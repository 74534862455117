import React from 'react';
import { NavLink } from 'react-router-dom';
//components
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
//translation
import { useTranslation } from 'react-i18next';

const SendEmailPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = (data: { email: string }) => {
    console.log(data);
  };

  return (
    <Box
      px={5}
      display={'flex'}
      alignItems={'center'}
      height={'100%'}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color={'primary'} variant="h3" fontWeight={'bold'} my={3}>
            {t('login.resetPassword.findAccount')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                label={t('global.email')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth variant="contained" type="submit" size="large">
            {t('global.send')}
          </Button>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <NavLink to={'/login'} replace className={'link'}>
            {t('login.backToLogin')}
          </NavLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendEmailPage;
