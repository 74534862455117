//react-query
import { RadioResponseType, RadioType } from '@interfaces/radio/radio';
import { ErrorsResponseType } from '@interfaces/response';
import { getAllRadio, getSingleRadio } from '@services/radioStation/radioStations';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllRadioStations = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: {
    order?: string;
    page?: number;
    take?: number;
    search?: string;
    genreId?: string;
    lang: string;
  },
  disable?: boolean
): UseQueryResult<RadioResponseType, ErrorsResponseType<{}>> =>
  useQuery(['radio-stations', params], () => getAllRadio(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: !disable
  });

  export const useSingleRadioStation = (
    id: string | undefined,
    onError: (err: ErrorsResponseType<{}>) => void,
    onSuccess: (data: RadioType) => void,
    lang?: string,
): UseQueryResult<RadioType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['radio-station', id, lang], () => (id ? getSingleRadio(id, lang) : null), {
    select: res => res?.data,
    onError: err => onError(err),
    onSuccess: data => data && onSuccess(data),
    enabled: !!id
  });
