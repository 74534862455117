import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// hooks
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from '@wrappers/AuthProvider/useAuth';
//mui
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import List from '@mui/material/List';
import Loading from '@components/Loading/Loading';
//theme
import theme from '@src/theme';
//icons
import LogoutIcon from '@mui/icons-material/Logout';
import LanguagePicker from '@components/LanguagePicker/LanguagePicker';
import { InfoOutlined } from '@mui/icons-material';
//style
import '@styles/components/sidebar.scss';
// services
import { useMyCompanies } from '@api/queries/company/company';
import { useNotificationCount } from '@api/queries/notifications/notifications';
// atoms
import { userAtom } from '@atoms/userAtom';
import { notificationCountAtom } from '@atoms/notfications';
//images
import LogoImage from '@src/assets/cms-logo.svg';
//helpers
import { dayjs, getLanguage, hasToken } from '@helpers/utility';
import i18next from 'i18next';
import { getSidemenu } from '@helpers/sidemenu';
import { getProfileSteps } from '@helpers/steps/steps';
//type
import FormModal from '@components/FormModal/FormModal';
import { ErrorsResponseType } from '@interfaces/response';
import { RoleEnum } from '@enum/roleEnum';
import { VITE_DISABLE_TOUR } from '@helpers/env.config';

type SidebarProps = {
  open: boolean;
  onClose: Function;
};

const Sidebar: React.FC<SidebarProps> = ({ open, onClose }: SidebarProps): JSX.Element => {
  let isLogOut = false;

  const user = useRecoilValue(userAtom);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [editLanguage, setEditLanguage] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const setNotificationCount = useSetRecoilState(notificationCountAtom);

  const { setCurrentStep, setSteps, setIsOpen, currentStep, isOpen } = useTour();

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { handleLogOut } = useAuth();

  const onError = (err: ErrorsResponseType<{}>) => {};

  const onCountSuccess = (data: number) => {
    setNotificationCount(data);
  };

  const { data: companies, isFetching: isLoadingCompanies } = useMyCompanies(
    onError,
    user?.type === RoleEnum.BUSINESS
  );

  const { data } = useNotificationCount(onError, onCountSuccess);

  const close = () => {
    if (!isMdUp) {
      onClose();
    }
  };

  const signOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setOpenPopup(true);
  };

  const handleSetTourSteps = () => {
    if (setSteps && companies) {
      setSteps(
        getProfileSteps(t, () => {
          navigate('/company/' + companies?.[0]?.id);
        })
      );

      const saved = localStorage.getItem('steps');

      let steps = saved ? JSON.parse(saved) : undefined;

      let currStep = 0;

      if (steps?.['currStep'] !== undefined) {
        currStep = steps['currStep'];
      }

      if (location.pathname === '/profile' && currStep > 1) {
        currStep = 0;
      }

      setCurrentStep(currStep);
    }
  };

  const handleHelp = () => {
    handleSetTourSteps();
    setIsOpen(true);
  };
 
  if (!hasToken()) return <></>;
  if (!user) return <></>;
  return (
    <Box className="Sidebar">
      <LanguagePicker open={editLanguage} onClose={() => setEditLanguage(false)} />
      <FormModal
        title={t('global.sign_out_title')}
        open={!!openPopup}
        fields={[]}
        onCancel={() => setOpenPopup(false)}
        onSubmit={() => {
          if (!isLogOut) {
            handleLogOut();
            isLogOut = true;
          }
        }}
        buttonText={t('global.accept')}
        variant="delete"
      />
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
            overflowX: 'hidden'
          }
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#F2F2F2',
            color: '#304674',
            borderRadius: '0px'
          }
        }}
        open={open}
        onClick={close}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <List sx={{ m: 2 }}>
            <ListItem sx={{ mb: 2 }}>
              <img src={LogoImage} height={40} />
            </ListItem>

            <Divider sx={{ borderColor: 'white', opacity: '0.4', mb: 1 }} />

            {isLoadingCompanies && <Loading />}

            {getSidemenu(user.type, t, companies).map(el => (
              <NavLink
                to={el.link}
                // end
                key={el.label}
                className={({ isActive }) => (isActive ? 'sidemenu_link active' : 'sidemenu_link')}
              >
                <ListItem sx={{ backgroundColor: 'inherit' }} data-tour={el.tourId}>
                  {el.icon}
                  <ListItemText sx={{ ml: '10px' }} primary={el.label} />
                </ListItem>
              </NavLink>
            ))}
          </List>

          <List sx={{ m: 2 }}>
            {!VITE_DISABLE_TOUR && user.type === RoleEnum.BUSINESS && (
              <ListItem
                className={'sidemenu_link active'}
                sx={{ mb: 1, cursor: 'pointer' }}
                onClick={handleHelp}
              >
                <ListItemAvatar>
                  <InfoOutlined />
                </ListItemAvatar>
                <ListItemText primary={t('global.help')} />
              </ListItem>
            )}

            <ListItem
              className={'language-card active'}
              sx={{ mb: 1, cursor: 'pointer' }}
              onClick={() => setEditLanguage(true)}
            >
              <ListItemAvatar>
                <Avatar src={getLanguage(i18next.language)?.icon}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={getLanguage(i18next.language)?.label} />
            </ListItem>

            {user && (
              <Box>
                <NavLink
                  to={'/profile'}
                  className={({ isActive }) =>
                    isActive ? 'sidemenu_link active' : 'sidemenu_link'
                  }
                >
                  {({ isActive }) => (
                    <ListItem
                      className={isActive ? 'profile-card active' : 'profile-card'}
                      sx={{ outline: '1px solid #C4C4C4' }}
                    >
                      <Avatar src={`${user.image}?${dayjs().toString()}`} />
                      <ListItemText
                        sx={{ ml: '10px' }}
                        primary={`${user.firstName} ${user.lastName}`}
                        secondary={`${
                          user.email.length < 22 ? user.email : user.email.slice(0, 22) + '...'
                        }`}
                        primaryTypographyProps={{
                          style: {
                            fontWeight: 'bold'
                          }
                        }}
                        secondaryTypographyProps={{
                          style: {
                            fontSize: '10px',
                            color: 'inherit'
                          }
                        }}
                      />
                      <Avatar sx={{ background: '#D32F2F' }} onClick={e => signOut(e)}>
                        <LogoutIcon fontSize="small" />
                      </Avatar>
                    </ListItem>
                  )}
                </NavLink>
              </Box>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
