import { useEffect, useState } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
//helpers
import { jobFields } from '@helpers/formFields/formFields';
import { dayjs } from '@helpers/utility';
//type
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { CreateJobType, JobType } from '@interfaces/job/job';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
//api
import { useAllIndustries } from '@api/queries/interests/interests';
import {
  useCreateJob,
  useDeleteJob,
  useDeleteJobImage,
  useUpdateJob,
  useUploadJobImage
} from '@api/mutations/jobs/jobs';
import { useMyJobs } from '@api/queries/jobs/jobs';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { formHelper } from '@helpers/formDataHelper';

export function useBusinessUserJobs() {
  const { t } = useTranslation();

  const { control, handleSubmit, reset, setError, getValues, setValue, clearErrors } =
    useForm<CreateJobType>({
      defaultValues: {
        hashTags: [''],
        tags: ['']
      }
    });

  const [popup, setPopup] = useState<FormModalPropsType<CreateJobType> | null>(null);
  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onCreateError = (err: ErrorType<CreateJobType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
    if (err.errors) {
      for (const key in err.errors) {
        setError(key as keyof CreateJobType, {
          type: 'custom',
          message: err.errors[key as keyof CreateJobType]?.join(', ')
        });
      }
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const { data: industries } = useAllIndustries(onError);
  const { data: jobs, refetch } = useMyJobs(onError);
  const {
    mutate: createJob,
    isSuccess: isSuccessCreate,
    data: createRes
  } = useCreateJob(onCreateError);
  const {
    mutate: updateJob,
    isSuccess: isSuccessUpdate,
    data: updateRes
  } = useUpdateJob(onCreateError);

  const { mutate: deleteJob, isSuccess: isSuccessDelete } = useDeleteJob(onError);

  const { mutate: uploadJobImage, isSuccess: isSuccessImage } = useUploadJobImage(onError);

  const { mutate: deleteJobImage, isSuccess: isSuccessDeleteImage } = useDeleteJobImage(onError);

  const onSubmit = (data: CreateJobType) => {
    delete data.heroImage;

    if (!dayjs().isBefore(data.expiredAt)) {
      setError('expiredAt', {
        type: 'custom',
        message: 'error'
      });
      return;
    }

    if (data.hashTags) data.hashTags = data.hashTags.filter(ht => !!ht);
    if (data.tags) data.tags = data.tags.filter(ht => !!ht);

    if (data.id) {
      const jobId = data.id;
      delete data.id;

      updateJob({
        id: jobId,
        data: { ...data, title: data.name }
      });
    } else {
      createJob({ ...data, title: data.name });
    }
  };

  const onCreateJobClick = () => {
    setPopup({
      title: t('job.create_job_title'),
      open: true,
      control: control,
      fields: jobFields(t, industries ? industries : []),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.accept'),
      variant: 'form',
      onCancel: () => {
        onReset();
      },
      setValue: setValue,
      getValues: getValues,
      clearErrors: clearErrors,
      setError: setError
    });
  };

  const onEditJobClick = (job: JobType) => {
    reset({
      name: job.name,
      position: job.position,
      description: job.description,
      type: job.type,
      location: job.location,
      expiredAt: dayjs(job.expiredAt),
      industryId: job.industry.id,
      id: job.id,
      heroImage: job.heroImage,
      shortDescription: job.shortDescription,
      hashTags: job.hashTags ? (job.hashTags.length > 0 ? job.hashTags : ['']) : [''],
      tags: job.tags ? (job.tags.length > 0 ? job.tags : ['']) : ['']
    });

    setPopup({
      title: t('job.edit_job_title'),
      open: true,
      control: control,
      fields: jobFields(t, industries ? industries : []),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        onReset();
      },
      setValue: setValue,
      getValues: getValues,
      setError: setError,
      clearErrors: clearErrors
    });
  };

  const onDeleteJobClick = (jobId: string) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.job`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.job`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        deleteJob(jobId);
      }
    });
  };

  const onReset = () => {
    setPopup(null);
    reset({
      name: '',
      position: '',
      description: '',
      type: undefined,
      location: '',
      expiredAt: undefined,
      industryId: '',
      hashTags: [''],
      tags: ['']
    });
  };

  useEffect(() => {
    if (isSuccessDelete || isSuccessImage || isSuccessDeleteImage) {
      onReset();
      refetch();
    }
  }, [isSuccessDelete, isSuccessImage, isSuccessDeleteImage]);

  useEffect(() => {
    if (isSuccessCreate && createRes) {
      setInfoPopup({
        title: t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
      const image = getValues('heroImage');

      if (image === null) {
        deleteJobImage(createRes.data.id);
      } else if (image && typeof image !== 'string') {
        uploadJobImage({
          jobId: createRes.data.id,
          type: 'heroImage',
          data: formHelper.getBody({ file: image })
        });
      } else {
        onReset();
        refetch();
      }
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (isSuccessUpdate && updateRes) {
      setInfoPopup({
        title: t('global.success_update'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });

      const image = getValues('heroImage');

      if (image === null) {
        deleteJobImage(updateRes.data.id);
      } else if (image && typeof image !== 'string') {
        uploadJobImage({
          jobId: updateRes.data.id,
          type: 'heroImage',
          data: formHelper.getBody({ file: image })
        });
      } else {
        onReset();
        refetch();
      }
    }
  }, [isSuccessUpdate]);

  return {
    popup,
    jobs: jobs ? jobs : [],
    onCreateJobClick,
    onDeleteJobClick,
    onEditJobClick
  };
}
