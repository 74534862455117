export enum SliderItemTypeEnum {
  VOUCHER = 'voucher',
  JOB = 'job',
  NEWS = 'news',
  COMPANY = 'company'
}
export enum SliderTypeEnum {
  HOME_TOP = 'home_top',
  HOME_BOTTOM_1 = 'home_bottom_1',
  HOME_BOTTOM_2 = 'home_bottom_2',
  HOME_BOTTOM_3 = 'home_bottom_3',
  HOME_BOTTOM_4 = 'home_bottom_4'
}

// export enum SliderTypeEnum {
//   HOME_TOP = 'home_top',
//   HOME_BOTTOM_1 = 'home_bottom_1',
//   HOME_BOTTOM_2 = 'home_bottom_2',
//   HOME_BOTTOM_3 = 'home_bottom_3',
//   HOME_BOTTOM_4 = 'home_bottom_4'
// }
