import { isDevMode } from '@helpers/utility';
import { useEffect } from 'react';

let _cm = false;

export const useObserve = (callback: () => void, dep: any[]) => {
  const isDev: boolean = isDevMode();
  if (isDev) {
    useEffect(() => {
      if (!_cm) {
        callback();
      }
      
      return () => {
        // _cm = !_cm;
        _cm=true
      };
    }, dep);
  } else {
    useEffect(() => {
      callback();
    }, dep);
  }

  return useObserve;
};
