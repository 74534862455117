import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
//components
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Loading from '@components/Loading/Loading';
//ract form
import { Controller, useForm } from 'react-hook-form';
//types
import { RegisterRequestType } from '@interfaces/login';
import { User } from '@interfaces/users/users';
//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//phone input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
//hooks
import { useTranslation } from 'react-i18next';
import { useAuth } from '@wrappers/AuthProvider/useAuth';
//api
import { useRegisterUser, useUserMutation } from '@api/mutations/user/user';
import { fetchToken } from '@services/keycloak';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorType } from '@interfaces/response';
import { userAtom } from '@atoms/userAtom';
//helpers
import {
  VITE_TERMS_OF_SERVICE_URL,
  CLIENT_ID,
  REDIRECT_URL,
  VITE_RECAPTCHA_KEY
} from '@helpers/env.config';
import { isProdMode } from '@helpers/utility';

const GetWrapper = ({ children }: { children: ReactNode }) => {
  if (!isProdMode()) {
    console.log('recaptcha');
    return (
      <GoogleReCaptchaProvider reCaptchaKey={VITE_RECAPTCHA_KEY}>
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  console.log('Recaptcha is disabled');
  return <>{children}</>;
};

const RegisterPage: React.FC = (): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showPassword, setShowPassword] = useState(false);
  const [tempPass, setTempPass] = useState<string | null>(null);

  const { isAuth, setIsAuth } = useAuth();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue
  } = useForm<RegisterRequestType>();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    if (isAuth) {
      navigate('/profile');
    }
  }, [isAuth]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('SignUp');

    if (!token) {
      setErrorPopup({
        title: t('login.recaptcha_error'),
        onClose: () => {
          resetErrorPopup();
        },
        type: 'error'
      });
    }

    return token;
  }, [executeRecaptcha]);

  const onSuccessUserResponse = (data: User) => {
    setUser(data);
    setIsAuth(true);
  };

  const onErrorUserResponse = (err: ErrorType<{}>) => {
    navigate('/error-page');
  };

  const { mutate: fetchUser } = useUserMutation(onSuccessUserResponse, onErrorUserResponse);

  const login = async (email: string) => {
    const token = await fetchToken({ username: email, password: tempPass });

    setTempPass(null);

    if (token) {
      fetchUser('');
    }
  };

  const onSuccess = (res: User): void => {
    if (res.shouldVerify) {
      navigate(`/login?verify=true&email=${res.email}`);
    } else {
      login(res.email);
    }
  };

  const onError = (err: ErrorType<RegisterRequestType>): void => {
    if (err.errors)
      for (const key in err.errors) {
        if (key !== 'username') {
          setError(key as keyof RegisterRequestType, {
            type: 'custom',
            message: err.errors[key as keyof RegisterRequestType]?.join(', ')
          });
        } else {
          setError('email', {
            type: 'custom',
            message: err.errors[key as keyof RegisterRequestType]?.join(', ')
          });
        }
      }
    else if (err.statusCode === 409) {
      setError('root', {
        type: 'custom',
        message: err.error
      });
    } else {
      setError('root', {
        type: 'custom',
        message: err.error
      });
    }

    setTempPass(null);
  };

  const { mutate: register, isLoading, isSuccess } = useRegisterUser(onSuccess, onError);

  const onSubmit = async (data: RegisterRequestType) => {
    try {
      const reCaptchaToken = isProdMode() ? await handleReCaptchaVerify() : null;

      if (data.password !== data.passwordConfirm) {
        setError('password', {
          type: 'custom',
          message: t('user.password_error')
        });
        setError('passwordConfirm', {
          type: 'custom',
          message: t('user.password_error')
        });
        return;
      }

      delete data.tos;
      delete data.privacy;
      delete data.passwordConfirm;
      data.username = data.email; // + '.username';

      setTempPass(data.password);

      register({
        ...data,
        redirectUri: `${REDIRECT_URL}/login`,
        clientId: CLIENT_ID,
        reCaptchaToken
      });
    } catch (e: any) {
      console.log('recaptcha err', e);
      setErrorPopup({
        title: t('login.recaptcha_error'),
        onClose: () => {
          resetErrorPopup();
        },
        type: 'error'
      });
    }
  };

  if (isLoading || isSuccess) return <Loading />;

  return (
    <Box
      px={5}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'100%'}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} mt={6}>
        <Grid item xs={12}>
          <Typography
            color={'primary'}
            variant="h3"
            fontWeight={'bold'}
            my={{ xs: 0, sm: 3 }}
            sx={{ fontSize: { xs: '2rem', md: '3rem' } }}
          >
            {t('login.register.title')}
          </Typography>
        </Grid>
        <Typography color="error">{errors.root?.message}</Typography>
        <Grid item xs={12}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                required
                label={t('login.register.user.name')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                required
                label={t('login.register.user.surname')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                required
                label={t('login.register.user.email')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error>
            <PhoneInput
              country={'rs'}
              specialLabel={t('login.register.user.phone')}
              regions={'europe'}
              inputClass="phone-input"
              value={watch('phone')}
              onChange={ev => {
                setValue('phone', ev);
                if (errors.phone) {
                  delete errors.phone;
                }
              }}
            />
            <FormHelperText color={'error'}>{errors.phone?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth error={invalid}>
                <InputLabel>{t('global.password')}</InputLabel>
                <OutlinedInput
                  required
                  label={t('global.password')}
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="passwordConfirm"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth error={invalid}>
                <InputLabel>{t('user.password_confirmation')}</InputLabel>
                <OutlinedInput
                  required
                  label={t('user.password_confirmation')}
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Controller
              name="tos"
              control={control}
              rules={{ required: true }}
              defaultValue={false}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl
                  fullWidth
                  error={invalid}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Checkbox
                    {...field}
                    sx={{ color: invalid ? 'red' : 'primary' }}
                    size="small"
                    checked={!!watch('tos')}
                  />
                  <FormLabel>
                    <Typography color={'text.secondary'} variant="subtitle2">
                      {t('login.tos.accept_message')}
                      <NavLink
                        to={`${VITE_TERMS_OF_SERVICE_URL}pages/cookies/${i18n.language}/index.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${t('login.tos.conditions')}`}
                      </NavLink>
                    </Typography>
                  </FormLabel>
                  <FormHelperText color="error">{error?.message}</FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name="privacy"
              control={control}
              rules={{ required: true }}
              defaultValue={false}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl
                  fullWidth
                  error={invalid}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Checkbox
                    {...field}
                    sx={{ color: invalid ? 'red' : 'primary' }}
                    size="small"
                    checked={!!watch('privacy')}
                  />
                  <FormLabel>
                    <Typography color={'text.secondary'} variant="subtitle2">
                      {t('login.tos.accept_message')}
                      <NavLink
                        to={`${VITE_TERMS_OF_SERVICE_URL}pages/privacy/${i18n.language}/index.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${t('login.tos.policy')}`}
                      </NavLink>
                    </Typography>
                  </FormLabel>
                  <FormHelperText color="error">{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" size="large" type="submit" sx={{ py: '.5rem' }}>
            {t('login.register.confirm_btn')}
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        {t('login.have_account')}{' '}
        <NavLink to={'/login'} replace className={'link'}>
          {t('login.log_in_here')}
        </NavLink>
      </Box>
    </Box>
  );
};

export default RegisterPage;
