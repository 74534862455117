//type

import { CreateJobType, JobType, UpdateJobStatusType, UpdateJobType } from '@interfaces/job/job';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//react query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//api
import {
  createJob,
  deleteJob,
  deleteJobImage,
  updateJob,
  updateJobStatus,
  uploadJobImage
} from '@services/job/jobs';

export const useCreateJob = (
  onError: (err: ErrorType<CreateJobType>) => void
): UseMutationResult<
  { data: JobType },
  ErrorsResponseType<CreateJobType>,
  CreateJobType,
  (data: JobType) => void
> =>
  useMutation({
    mutationFn: createJob,
    onError: err => onError(err.response.data)
  });

export const useUpdateJob = (
  onError: (err: ErrorType<CreateJobType>) => void
): UseMutationResult<
  { data: JobType },
  ErrorsResponseType<CreateJobType>,
  UpdateJobType,
  (data: JobType) => void
> =>
  useMutation({
    mutationFn: updateJob,
    onError: err => onError(err.response.data)
  });

export const useUpdateJobStatus = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<{}, ErrorsResponseType<{}>, UpdateJobStatusType, () => void> =>
  useMutation({
    mutationFn: updateJobStatus,
    onError: err => onError(err),
    onSuccess: () => onSuccess()
  });

export const useDeleteJob = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteJob,
    onSuccess: () => onSuccess && onSuccess(),
    onError: err => onError(err)
  });

export const useUploadJobImage = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  { data: { heroImage: string } }, // response
  ErrorsResponseType<{}>,
  { jobId: string; type: 'heroImage'; data: FormData } // request
> =>
  useMutation({
    mutationFn: req => uploadJobImage(req.jobId, req.type, req.data),
    onError: err => onError(err)
  });

export const useDeleteJobImage = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteJobImage,
    onSuccess: () => onSuccess && onSuccess(),
    onError: err => onError(err)
  });
