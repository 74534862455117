import {
  CreateJobType,
  JobParamsType,
  JobType,
  JobsResponseType,
  UpdateJobStatusType,
  UpdateJobType
} from '@interfaces/job/job';

import axiosInstance from '@services/config';

export const getAllJobs = (params: JobParamsType): Promise<{ data: JobsResponseType }> => {
  return axiosInstance.get('v1/jobs/admin', {
    params: { ...params, order: 'DESC' }
  });
};

export const createJob = (data: CreateJobType): Promise<{ data: JobType }> => {
  return axiosInstance.post('v1/jobs', data);
};

export const updateJob = (data: UpdateJobType): Promise<{ data: JobType }> => {
  return axiosInstance.patch('v1/jobs/' + data.id, data.data);
};

export const deleteJob = (id: string): Promise<{}> => {
  return axiosInstance.delete('v1/jobs/' + id);
};

export const getSingleJob = (id: string): Promise<{ data: JobType }> => {
  return axiosInstance.get('v1/jobs/' + id);
};

export const getMyJobs = (): Promise<{ data: JobType[] }> => {
  return axiosInstance.get('v1/jobs/my');
};

export const getCompanyJobs = (id: string): Promise<{ data: JobType[] }> => {
  return axiosInstance.get('v1/jobs/admin/company/' + id);
};

export const updateJobStatus = (data: UpdateJobStatusType): Promise<{ data: JobType }> => {
  return axiosInstance.patch(`v1/jobs/${data.id}/status`, {
    newStatus: data.newStatus
  });
};

export const getAllAllowedJobs = (): Promise<{ data: { data: JobType[]; meta: {} } }> => {
  return axiosInstance.get('v1/jobs');
};

export const uploadJobImage = (
  jobId: string,
  type: 'heroImage',
  data: FormData
): Promise<{ data: { heroImage: string } }> =>
  axiosInstance.patch(`v1/jobs/${jobId}/images`, data, {
    params: {
      type
    }
  });

export const deleteJobImage = (jobId: string): Promise<{}> =>
  axiosInstance.delete(`v1/jobs/${jobId}/images`);
