import React from 'react';
import { Outlet } from 'react-router-dom';
// hooks
import { useProfile } from '@hooks/useProfile/useProfile';
//compoents
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';

import { Box } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import dayjs from 'dayjs';

const ProfilePageLayout: React.FC = (): JSX.Element => {
  const { formData, setFormData, control, user, onEdit, t, onEditProfileImage } = useProfile();

  return (
    <Box width={'100%'}>
      {formData && (
        <FormModal
          title={formData.title}
          open={!!formData}
          control={control}
          fields={formData.fields}
          onCancel={() => setFormData(null)}
          onSubmit={formData.onSubmit}
          buttonText={formData.buttonText}
          variant={formData.variant}
          setValue={formData.setValue}
          setError={formData.setError}
          clearErrors={formData.clearErrors}
        />
      )}

      <ProfileInfoCard
        title={`${user?.firstName} ${user?.lastName}`}
        subtitle={`${user?.email}`}
        isVerified={user?.type}
        profileImage={user?.image ? `${user.image}?${dayjs().toISOString()}` : null}
        handleEdit={user?.type === 'business' ? undefined : onEdit}
        handleEditProfileImage={onEditProfileImage}
      />
      <Box className={'container'}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProfilePageLayout;
