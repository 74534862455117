//react
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
//components
import { Box } from '@mui/system';
import Loading from '@components/Loading/Loading';
import PageLayout from '@pages/Page/PageLayout';
//interfaces
import { type MRT_ColumnDef } from 'material-react-table';
import { RadioType } from '@interfaces/radio/radio';
//hook
import { useRadio } from '@hooks/useRadio/useRadio';
import { useTranslation } from 'react-i18next';
//helpers
import { radioFields } from '@helpers/formFields/formFields';
import { getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const RadioPage = () => {
  const {
    handleSubmit,
    onSubmit,
    deleteRadio,
    radios,
    control,
    setValue,
    reset,
    genres,
    setError,
    success,
    pagination,
    setPagination,
    errors,
    columnFilters,
    setColumnFilters,
    rowCount,
    isLoadingAll,
    isSubmitting
  } = useRadio();

  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<RadioType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        Cell: ({ row }) => <NavLink to={row.original.id}> {row.original.name} </NavLink>
      },
      {
        accessorKey: 'lcn',
        header: t('radio.lcn'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'isDefault',
        header: t('radio.isDefault'),
        Cell: ({ row }) => t(`global.${row.original.isDefault.toString()}`),
        enableColumnFilter: false
      },
      {
        accessorKey: 'genres',
        header: t('global.genres'),
        Cell: ({ row }) => row.original.genres?.map(g => g.name).join(', '),
        filterVariant: 'select',
        filterSelectOptions: genres.map(g => {
          return {
            text: getTranslationValue(
              g.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            ),
            value: g.id
          };
        })
      }
    ],
    [radios, t]
  );

  const handleDelete = (data: RadioType) => {
    deleteRadio(data.id);
  };

  return (
    <Box className="container">
      <PageLayout
        columns={columns}
        data={radios}
        fields={radioFields(t, genres)}
        handleCreate={onSubmit}
        handleDelete={handleDelete}
        title={t('global.radios')}
        subtitle={t('radio.page_subtitle')}
        addBtnText={t('radio.add_radio')}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={reset}
        translationKey="radio"
        setError={setError}
        isSubmitSuccessfull={success}
        errors={errors}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={rowCount}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        isLoading={isLoadingAll}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

export default RadioPage;
