//hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
//type
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { JobType } from '@interfaces/job/job';
import { ErrorsResponseType } from '@interfaces/response';
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
//enum
import { JobStatusEnum, JobTypeEnum } from '@enum/companyEnum';
//api
import { useAllJobs, useSingleJob } from '@api/queries/jobs/jobs';
import { useDeleteJob, useUpdateJobStatus } from '@api/mutations/jobs/jobs';
//helpers
import { getDefaultPagnation } from '@helpers/utility';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';

export function useJobs(single: boolean) {
  const { t } = useTranslation();
  const [popup, setPopup] = useState<FormModalPropsType<{}> | null>(null);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const { jobId } = useParams();
  const navigate = useNavigate();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onDeleteSuccess = () => {
    refetchAll();
    setPopup(null);
    if (jobId) {
      navigate(-1);
    }
  };

  const onUpdateStatusError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.response.data.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onUpdateStatusSuccess = () => {
    refetchSingle();
    setPopup(null);
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const {
    data: jobs,
    refetch: refetchAll,
    isFetching: isLoadingAll
  } = useAllJobs(
    {
      page: pagination.pageIndex + 1,
      take: pagination.pageSize,
      search: columnFilters.find(el => el.id === 'name')?.value as string,
      status: columnFilters.find(el => el.id === 'status')?.value as JobStatusEnum,
      type: columnFilters.find(el => el.id === 'type')?.value as JobTypeEnum,
      location: columnFilters.find(el => el.id === 'location')?.value as string
    },
    onError,
    !single
  );
  const {
    data: singleJob,
    refetch: refetchSingle,
    isFetching: isLoadingSingle
  } = useSingleJob(jobId, onError);

  const { mutate: deleteJob, isLoading: isLoadingDelete } = useDeleteJob(onError, onDeleteSuccess);
  const { mutate: updateStatus, isLoading: isLoadingUpdateStatus } = useUpdateJobStatus(
    onUpdateStatusSuccess,
    onUpdateStatusError
  );

  const onDeleteClick = (job?: JobType) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.job`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.job`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (jobId) deleteJob(jobId);
        if (job?.id) deleteJob(job.id);
      }
    });
  };

  const onConfirmClick = () => {
    setPopup({
      title: t('job.confirm_title'),
      subtitle: t('job.confirm_subtitle'),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (jobId) {
          updateStatus({
            id: jobId,
            newStatus: JobStatusEnum.ALLOWED
          });
        }
      }
    });
  };
  const onDeclineClick = () => {
    setPopup({
      title: t('job.decline_title'),
      subtitle: t('job.decline_subtitle'),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (jobId) {
          updateStatus({
            id: jobId,
            newStatus: JobStatusEnum.BLOCKED
          });
        }
      }
    });
  };

  useEffect(() => {
    if (location.pathname === '/jobs')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    popup,
    onConfirmClick,
    onDeclineClick,
    onDeleteClick,
    allJobs: jobs ? jobs.data : [],
    singleJob,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination,
    rowCount: jobs ? jobs.meta.itemCount : 0,
    isLoadingAll,
    isLoadingSingle,
    isLoadingUpdateStatus,
    isLoadingDelete
  };
}
