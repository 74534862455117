export enum NotificationOriginEnum {
  COUPON = 'COUPON',
  VOUCHER = 'VOUCHER',
  JOB = 'JOB',
  NEWS = 'NEWS',
  EVENT = 'EVENT'
}

export enum NotificationTypeEnum {
  COUPON_EXPIRED = 'COUPON_EXPIRED',
  COUPON_REDEEMED = 'COUPON_REDEEMED',
  VOUCHER_NEW = 'VOUCHER_NEW',
  VOUCHER_DECLINED = 'VOUCHER_DECLINED',
  VOUCHER_FINISHED = 'VOUCHER_FINISHED',
  VOUCHER_REQUEST_REVIEW = 'VOUCHER_REQUEST_REVIEW',
  NEWS_NEW = 'NEWS_NEW'
}
