//hooks
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//components
import { FormModalPropsType } from '@components/FormModal/FormModal';
//type
import { ChangePasswordRequestType } from '@interfaces/users/users';
//helpers
import { changePasswordFields } from '@helpers/formFields/formFields';
//api
import { useChangePassword } from '@api/mutations/user/user';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorsResponseType } from '@interfaces/response';

export function useEditPassword() {
  const { t } = useTranslation();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const [formData, setPasswordFormData] =
    useState<FormModalPropsType<ChangePasswordRequestType> | null>(null);

  const onChangeSuccess = () => {
    setPasswordFormData(null);
    reset({ oldPassword: '', newPassword: '', passwordConfirmation: '' });
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onChangeError = (err: ErrorsResponseType<{ password: string[] }>) => {
    if (err.response.data.errors?.password) {
      setError('newPassword', {
        type: 'custom',
        message: err.response.data.errors.password.join(', ')
      });
    } else {
      setError('root', {
        type: 'custom',
        message: err.response.data.message
      });
      setInfoPopup({
        title: err.response.data.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const { mutate: changePassword } = useChangePassword(onChangeSuccess, onChangeError);

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<ChangePasswordRequestType>({});

  const onSubmit = (data: ChangePasswordRequestType) => {
    if (data.newPassword !== data.passwordConfirmation) {
      setError('newPassword', {
        type: 'custom',
        message: t('user.password_error')
      });
      setError('passwordConfirmation', {
        type: 'custom',
        message: t('user.password_error')
      });
    } else {
      changePassword(data);
    }
  };

  const onEditPassword = () => {
    setPasswordFormData({
      title: t('user.change_password'),
      open: true,
      control: control,
      fields: changePasswordFields(t),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        reset({ oldPassword: '', newPassword: '', passwordConfirmation: '' });
        setPasswordFormData(null);
      },
      errors: errors
    });
  };

  return {
    onEditPassword,
    passwordFormData: formData,
    setPasswordFormData
  };
}
