//type
import { AllNewsType, NewsType } from '@interfaces/news/news';
import { AxiosError } from 'axios';
//api
import { getAllNews, getSingleNews } from '@services/news/news';
//react-query
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';
import { ErrorsResponseType } from '@interfaces/response';

export const useAllNews = (
  onError: (err: ErrorsResponseType<{}>) => void,
  pagination?: {
    page?: number;
    take?: number;
    search?: string;
    rubricId?: string;
    lang?: string;
    countries?: string[];
  },
  disable?: boolean
): UseQueryResult<AllNewsType, ErrorsResponseType<{}>> =>
  useQuery(['news', pagination], () => getAllNews(pagination), {
    onError: err => onError(err),
    select: res => res.data,
    refetchOnWindowFocus: true,
    enabled: !disable
  });

export const useSingleNews = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void,
  lang?: string
): UseQueryResult<NewsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['news', id, lang], () => (id ? getSingleNews(id, lang) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });

export const useAllNewsInfinity = (
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean,
  params: { search?: string }
): UseInfiniteQueryResult<{ data: AllNewsType }, ErrorsResponseType<{}>> =>
  useInfiniteQuery(
    ['infinity-news', params],
    a =>
      getAllNews({
        page: a.pageParam,
        take: 10,
        search: params.search
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.hasNextPage ? lastPage.data.meta.page + 1 : undefined,
      onError: err => onError(err),
      enabled: enabled
    }
  );
