//type
import {
  CompanyType,
  CreateCompanyType,
  UpdateCompanyRequestType
} from '@interfaces/company/company';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//api
import {
  createCompany,
  deleteCompany,
  deleteImage,
  updateCompaniesVisibility,
  updateCompany,
  uploadImage
} from '@services/company/company';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreateCompany = (
  onError: (err: ErrorType<CreateCompanyType>) => void
): UseMutationResult<
  { data: CompanyType }, // response
  ErrorsResponseType<CreateCompanyType>,
  CreateCompanyType, // request
  (data: CompanyType) => void // on success
> =>
  useMutation({
    mutationFn: createCompany,
    onError: err => onError(err.response.data)
  });

export const useUpdateCompany = (
  onError: (err: ErrorType<CreateCompanyType>) => void
): UseMutationResult<
  { data: CompanyType }, // response
  ErrorsResponseType<CreateCompanyType>,
  UpdateCompanyRequestType, // request
  (data: CompanyType) => void // on success
> =>
  useMutation({
    mutationFn: updateCompany,
    onError: err => onError(err.response.data)
  });

export const useDeleteCompany = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteCompany,
    onError: err => onError(err),
    onSuccess: () => onSuccess && onSuccess()
  });

export const useUploadCompanyImage = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  { data: { image?: string; coverImage?: string } }, // response
  ErrorsResponseType<{}>,
  { companyId: string; type: 'image' | 'coverImage' | 'heroImage'; data: FormData } // request
> =>
  useMutation({
    mutationFn: req => uploadImage(req.companyId, req.type, req.data),
    onError: err => onError(err)
  });

export const useDeleteCompanyImage = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { companyId: string; type: 'image' | 'coverImage' | 'heroImage' } // request
> =>
  useMutation({
    mutationFn: req => deleteImage(req.companyId, req.type),
    onError: err => onError(err),
    onSuccess: () => onSuccess && onSuccess()
  });

export const useUpdateCompanyVisibility = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  { data: CompanyType }, // response
  ErrorsResponseType<{}>,
  { id: string; visible: boolean }, // request
  (data: CompanyType) => void // on success
> =>
  useMutation({
    mutationFn: updateCompaniesVisibility,
    onError: err => onError(err)
  });
