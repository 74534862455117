//type
import { ErrorsResponseType } from '@interfaces/response';
import { SliderType } from '@interfaces/sliders/sliders';
//api
import { getAllSliders, getSingleSlider } from '@services/sliders/sliders';
//react query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllSliders = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<SliderType[], ErrorsResponseType<{}>> =>
  useQuery(['sliders'], () => getAllSliders(), {
    onError: err => onError(err),
    select: res => res.data
  });

export const useSingleSlider = (
  sliderId: undefined | string,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<SliderType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['slider', sliderId], () => (sliderId ? getSingleSlider(sliderId) : undefined), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!sliderId
  });
