import { useState } from 'react';
//mui
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import List from '@mui/material/List';
//style
import '@styles/components/sidebar.scss';
//translate
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { languages } from '@src/translation/languages.config';

const LanguagePicker = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();

  const onChangeLanguage = (lng: string) => {
    localStorage.setItem('i18nextLng', lng);
    i18next.changeLanguage(lng);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255,255,255, .5)' } }
      }}
    >
      <DialogContent>
        <List>
          {languages.map(lng => (
            <ListItem
              className={
                localStorage.getItem('i18nextLng') === lng.id
                  ? 'language-card active'
                  : 'language-card'
              }
              sx={{ mb: 1, cursor: 'pointer' }}
              onClick={() => onChangeLanguage(lng.id)}
              key={lng.id}
            >
              <ListItemAvatar>
                <Avatar src={lng.icon}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={lng.label} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default LanguagePicker;
