export enum InputTypeEnum {
  text = 'text',
  password = 'password',
  select = 'select',
  date = 'date',
  number = 'number',
  image = 'image',
  url = 'url',
  custom = 'custom',
  document = 'document',
  checkbox = 'checkbox',
  editor = 'editor',
  multipleFields = 'multipleFields',
  parser = 'parser',
  autocomplete = 'autocomplete'
}
