import axiosInstance from '@services/config';
import {
  NotificationResponseType,
  NotificationsType,
  RegisterNotificationTokenRequestType,
  RegisterNotificationTokenResponseType
} from '@interfaces/notifications/notifications';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

export const registerNotificationToken = async (
  data: RegisterNotificationTokenRequestType
): Promise<{ data: RegisterNotificationTokenResponseType }> => {
  return axiosInstance.post('v1/firebase/fcm-token', data);
};

export const getNotificationsHisory = async (params: {
  order?: 'ASC' | 'DESC';
  page?: number;
  take?: number;
  lang: SupportedLanguagesEnum;
}): Promise<{ data: NotificationResponseType }> => {
  return await axiosInstance.get('v1/notifications/history/localized', { params });
};

export const getNotificationCount = async (): Promise<{ data: number }> => {
  return await axiosInstance.get('v1/notifications/unseen/count');
};

export const seenNotfication = async (id: string): Promise<{ data: NotificationsType }> => {
  return await axiosInstance.get('v1/notifications/seen/' + id);
};
