import {
  AllPodcastChannelsType,
  AllPodcastsType,
  CreatePodcastChannelType,
  CreatePodcastType,
  PodcastChannelType,
  PodcastType,
  PodcastTypeEnum,
  UpdatePodcastChannelType,
  UpdatePodcastType
} from '@interfaces/podcast/podcast';
import axiosInstance from '@services/config';

export const getSinglePodcastChannel = (id: string): Promise<{ data: PodcastChannelType }> =>
  axiosInstance.get('v1/podcasts/channels/' + id);

export const createPodcastChannel = (
  data: CreatePodcastChannelType
): Promise<{ data: PodcastChannelType }> => axiosInstance.post('v1/podcasts/channels', data);

export const updatePodcastChannel = (
  data: UpdatePodcastChannelType
): Promise<{ data: PodcastChannelType }> =>
  axiosInstance.patch('v1/podcasts/channels/' + data.id, data.data);

export const deletePodcastChannel = (id: string): Promise<{}> =>
  axiosInstance.delete('v1/podcasts/channels/' + id);

export const getAllPodcastChannel = (params?: {
  page: number;
  take: number;
  type?: string;
  order?: 'ASC' | 'DESC';
}): Promise<{ data: AllPodcastChannelsType }> =>
  axiosInstance.get('v1/podcasts/channels', { params });

export const getAllPodcasts = (params?: {
  page: number;
  take: number;
  order?: 'ASC' | 'DESC';
  type?: PodcastTypeEnum;
  channelId?: string;
}): Promise<{ data: AllPodcastsType }> => axiosInstance.get('v1/podcasts', { params });

export const getSinglePodcast = (id: string): Promise<{ data: PodcastType }> =>
  axiosInstance.get('v1/podcasts/' + id);

export const createPodcast = (data: CreatePodcastType): Promise<{ data: PodcastType }> =>
  axiosInstance.post('v1/podcasts', data);

export const updatePodcast = (data: UpdatePodcastType): Promise<{ data: PodcastType }> =>
  axiosInstance.patch('v1/podcasts/' + data.id, data.data);

export const deletePodcast = (id: string): Promise<{}> => axiosInstance.delete('v1/podcasts/' + id);
