import { StatusEnum } from '@enum/progressEnum';
import {
  AllHitListsResponseType,
  ChangeStatusHitListRequestType,
  SingleHitListResponseType,
  UpdateHitListType
} from '@interfaces/hitList/hitList';
import axiosInstance from '@services/config';

export const getAllHitLists = (params?: {
  page?: number;
  take?: number;
  search?: string;
  status?: StatusEnum;
}): Promise<{ data: AllHitListsResponseType }> => {
  return axiosInstance.get('v1/hit-lists', {
    params: { ...params, order: 'DESC' }
  });
};

export const createHitList = (data: FormData): Promise<SingleHitListResponseType> => {
  return axiosInstance.post('v1/hit-lists', data);
};

export const updateHitList = (data: UpdateHitListType): Promise<SingleHitListResponseType> => {
  return axiosInstance.patch('v1/hit-lists/' + data.id, data.data);
};

export const deleteHitList = (id: string): Promise<{}> => {
  return axiosInstance.delete('v1/hit-lists/' + id);
};

export const getSingleHitList = (id: string): Promise<SingleHitListResponseType> => {
  return axiosInstance.get('v1/hit-lists/' + id);
};

export const changeStatusHitList = (data: ChangeStatusHitListRequestType): Promise<{}> =>
  axiosInstance.post(`v1/hit-lists/${data.id}/status`, { newStatus: data.newStatus });
