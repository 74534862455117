//type

import {
  CreatePodcastChannelType,
  CreatePodcastType,
  PodcastChannelType,
  PodcastType,
  UpdatePodcastChannelType,
  UpdatePodcastType
} from '@interfaces/podcast/podcast';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//api
import {
  createPodcast,
  createPodcastChannel,
  deletePodcast,
  deletePodcastChannel,
  updatePodcast,
  updatePodcastChannel
} from '@services/podcast/podcast';

//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreatePodcast = (
  onError: (err: ErrorType<CreatePodcastType>) => void,
  onSuccess: (data: PodcastType) => void
): UseMutationResult<
  { data: PodcastType }, // response
  ErrorsResponseType<CreatePodcastType>,
  CreatePodcastType, // request
  (data: PodcastType) => void // on success
> =>
  useMutation({
    mutationFn: createPodcast,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data)
  });

export const useUpdatePodcast = (
  onError: (err: ErrorType<CreatePodcastType>) => void
): UseMutationResult<
  { data: PodcastType }, // response
  ErrorsResponseType<CreatePodcastType>,
  UpdatePodcastType, // request
  (data: PodcastType) => void // on success
> =>
  useMutation({
    mutationFn: updatePodcast,
    onError: err => onError(err.response.data)
  });

export const useDeletePodcast = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deletePodcast,
    onError: err => onError(err)
  });

export const useCreatePodcastChannel = (
  onError: (err: ErrorType<CreatePodcastChannelType>) => void,
  onSuccess: (data: PodcastChannelType) => void
): UseMutationResult<
  { data: PodcastChannelType }, // response
  ErrorsResponseType<CreatePodcastChannelType>,
  CreatePodcastChannelType, // request
  (data: PodcastChannelType) => void // on success
> =>
  useMutation({
    mutationFn: createPodcastChannel,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data)
  });

export const useUpdatePodcastChannel = (
  onError: (err: ErrorType<CreatePodcastChannelType>) => void
): UseMutationResult<
  { data: PodcastChannelType }, // response
  ErrorsResponseType<CreatePodcastChannelType>,
  UpdatePodcastChannelType, // request
  (data: PodcastChannelType) => void // on success
> =>
  useMutation({
    mutationFn: updatePodcastChannel,
    onError: err => onError(err.response.data)
  });

export const useDeletePodcastChannel = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deletePodcastChannel,
    onError: err => onError(err)
  });
