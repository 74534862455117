import { useMemo } from 'react';
//components
import { Navigate } from 'react-router-dom';
import FormModal from '@components/FormModal/FormModal';
import { Button, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TableLayout from '@components/TableLayout/TableLayout';
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { RadioProgramType, RadioType } from '@interfaces/radio/radio';
//hooks
import { useRadio } from '@hooks/useRadio/useRadio';
import { useTranslation } from 'react-i18next';
//helpers
import dayjs from 'dayjs';
import { getRadioKeysName } from '@helpers/profileFields/profileFields';

type RadioTypeKeys = {
  label: string;
  key: keyof RadioType;
};

const SingleRadioPage = () => {
  const {
    isLoadingSingle,
    singleRadio,
    popup,
    setPopup,
    onEditRadioClick,
    onDeleteRadioClick,
    onDeleteRadioProgramClick,
    onEditRadioProgramClick,
    errors,
    isSubmitting,
    isSubmittingProgram
  } = useRadio();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<RadioTypeKeys>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Typography>
            {row.original.key !== 'genres'
              ? singleRadio?.[row.original.key]?.toString()
              : singleRadio?.[row.original.key].map(g => g.name).join(', ')}
          </Typography>
        )
      }
    ],
    [singleRadio, t]
  );

  const program_schema_columns = useMemo<MRT_ColumnDef<RadioProgramType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'startTime',
        header: t('global.date_start'),
        enableColumnFilter: false,
        Cell: ({ row }) => dayjs(row.original.startTime).format('DD.MM.YYYY')
      },
      {
        accessorKey: 'endTime',
        header: t('global.duration'),
        enableColumnFilter: false,
        Cell: ({ row }) =>
          dayjs(row.original.startTime).format('HH:mm') +
          ' - ' +
          dayjs(row.original.endTime).format('HH:mm')
      },
      {
        accessorKey: 'description',
        header: t('global.description'),
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue<string>()}>
            <Typography variant="caption">
              {cell.getValue<string>().slice(0, 60) + '...'}
            </Typography>
          </Tooltip>
        )
      }
    ],
    []
  );

  if (isLoadingSingle) {
    return <Loading />;
  }
  if (!singleRadio) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={() => {
            popup.onCancel();
            setPopup(null);
          }}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          errors={errors}
          isLoading={isSubmitting || isSubmittingProgram}
        />
      )}

      <ProfileInfoCard
        title={singleRadio ? singleRadio.name : ''}
        subtitle={t('global.radio_station')}
        bannerImage={singleRadio?.banner + `?${dayjs().toISOString()}`}
        profileImage={singleRadio?.image + `?${dayjs().toISOString()}`}
        logoImage={singleRadio?.logo + `?${dayjs().toISOString()}`}
        avatarVariant="rounded"
      />
      <Box className="container">
        <Box mt={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
              {t('global.basic_info')}
            </Typography>
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{ mx: 2 }}
                onClick={() => onEditRadioClick()}
              >
                {t('global.edit')}
              </Button>

              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => onDeleteRadioClick()}
              >
                {t('global.delete')}
              </Button>
            </Box>
          </Box>
          <TableLayout
            data={getRadioKeysName(t)}
            columns={columns}
            rowCount={singleRadio.radioProgram.length}
          />
        </Box>

        <Box mt={2}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
              {t('radio.current_program')}
            </Typography>
            <Box display={'flex'} gap={2}>
              <Button variant="contained" size="small" onClick={() => onEditRadioProgramClick()}>
                {t('radio.add_program')}
              </Button>
              {singleRadio.radioProgram.length !== 0 && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => onDeleteRadioProgramClick()}
                >
                  {t('radio.delete_program')}
                </Button>
              )}
            </Box>
          </Box>
          <TableLayout
            data={singleRadio.radioProgram}
            columns={program_schema_columns}
            rowCount={singleRadio?.radioProgram.length}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SingleRadioPage;
