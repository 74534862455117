//react
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//interfaces
import { MRT_PaginationState } from 'material-react-table';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { CreatePodcastChannelType, PodcastChannelType } from '@interfaces/podcast/podcast';
import { FormModalPropsType } from '@components/FormModal/FormModal';
//hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
//services
import { useAllPodcastChannels, useSinglePodcastChannel } from '@api/queries/podcast/podcast';
import {
  useCreatePodcastChannel,
  useDeletePodcastChannel,
  useUpdatePodcastChannel
} from '@api/mutations/podcast/podcast';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
//helpers
import { getDefaultPagnation } from '@helpers/utility';
import { podcastChannelFields } from '@helpers/formFields/formFields';

export function usePodcastChannels() {
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });

  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const [popup, setPopup] = useState<FormModalPropsType<CreatePodcastChannelType> | null>(null);

  const { channelId } = useParams();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors }
  } = useForm<CreatePodcastChannelType>();

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.response.data?.message ? err.response.data?.message : err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const { data: singleChannel, isLoading: isLoadingSingle } = useSinglePodcastChannel(
    channelId,
    onError
  );

  const {
    data: channels,
    isLoading: isLoadingAll,
    refetch
  } = useAllPodcastChannels(onError, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    order: 'DESC'
  });

  const onErrorCreate = (err: ErrorType<CreatePodcastChannelType>) => {
    for (const key in err.errors) {
      setError(key as keyof CreatePodcastChannelType, {
        type: 'custom',
        message: err.errors[key as keyof CreatePodcastChannelType]?.join(', ')
      });
    }
    if (!err.errors) {
      setError('root', {
        type: 'custom',
        message: err.message
      });
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onSuccessCreate = (data: PodcastChannelType) => {
    refetch();
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const {
    mutate: deleteChannel,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete
  } = useDeletePodcastChannel(onError);

  const {
    mutate: createChannel,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreatePodcastChannel(onErrorCreate, onSuccessCreate);
  const {
    mutate: updateChannel,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdatePodcastChannel(onErrorCreate);

  const onCancel = () => {
    setPopup(null);
  };

  useEffect(() => {
    if (location.pathname === '/podcasts/channels')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  useEffect(() => {
    if (isSuccessDelete) {
      if (channelId) {
        navigate(-1);
      } else {
        setPopup(null);
        refetch();
      }
    }
  }, [isSuccessDelete]);

  const onSubmit = (data: CreatePodcastChannelType) => {
    if (channelId) {
      // updatePodcast
    } else {
      createChannel(data);
    }
  };

  const onDeleteChannelClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.channel`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.channel`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (channelId) {
          deleteChannel(channelId);
        }
      }
    });
  };

  const onEditChannelClick = () => {
    setPopup({
      title: t('podcast.form.edit_title'),
      open: true,
      control: control,
      fields: podcastChannelFields(t),
      onCancel: onCancel,
      onSubmit: handleSubmit(onSubmit as () => void),
      setValue: setValue,
      buttonText: t('global.edit'),
      variant: 'form',
      setError: setError
    });
  };

  return {
    onCancel,
    deleteChannel,
    channels: channels ? channels.data : [],
    rowCount: channels ? channels.meta.itemCount : 0,
    pagination,
    setPagination,
    singleChannel,
    isLoadingAll,
    isLoadingSingle,
    channelId,
    popup,
    setPopup,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    errors,
    isSuccess: isCreateSuccess || isUpdateSuccess,
    reset,
    onSubmit,
    onDeleteChannelClick,
    onEditChannelClick,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
