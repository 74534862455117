import {
  JobParamsType,
  JobType,
  JobsInfinityResponseType,
  JobsResponseType
} from '@interfaces/job/job';
import { ErrorsResponseType } from '@interfaces/response';
import {
  getAllAllowedJobs,
  getAllJobs,
  getCompanyJobs,
  getMyJobs,
  getSingleJob
} from '@services/job/jobs';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';

export const useAllJobs = (
  params: JobParamsType,
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean
): UseQueryResult<JobsResponseType, ErrorsResponseType<{}>> =>
  useQuery(['jobs', params], () => getAllJobs(params), {
    select: res => res.data,
    onError: err => onError(err),
    enabled: enabled
  });

export const useSingleJob = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<JobType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['job', id], () => (id ? getSingleJob(id) : null), {
    select: res => res?.data,
    onError: err => onError(err),
    enabled: !!id
  });

export const useCompanyJobs = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<JobType[] | undefined, ErrorsResponseType<{}>> =>
  useQuery(['company-jobs', id], () => (id ? getCompanyJobs(id) : undefined), {
    select: res => res?.data,
    onError: err => onError(err),
    enabled: !!id
  });

export const useMyJobs = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<JobType[], ErrorsResponseType<{}>> =>
  useQuery(['my-jobs'], () => getMyJobs(), {
    select: res => res?.data,
    onError: err => onError(err)
  });

export const useAllAllowedJobs = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<{ data: JobType[] }, ErrorsResponseType<{}>> =>
  useQuery(['allowed-jobs'], () => getAllAllowedJobs(), {
    select: res => res?.data,
    onError: err => onError(err)
  });

export const useInfinityJobs = (
  params: JobParamsType,
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean
): UseInfiniteQueryResult<JobsInfinityResponseType, ErrorsResponseType<{}>> =>
  useInfiniteQuery(
    ['infinity-jobs', params],
    a =>
      getAllJobs({
        ...params,
        page: a.pageParam
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.hasNextPage ? lastPage.data.meta.page + 1 : undefined,
      onError: err => onError(err),
      enabled: enabled
    }
  );
