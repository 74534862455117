//router
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
//layout
import Layout from '@layout/Layout/Layout';
import LoginLayout from '@layout/LoginLayout/LoginLayout';
import ProfilePageLayout from '@layout/ProfilePageLayout/ProfilePageLayout';
import SingleUserLayout from '@layout/SingleUserLayout/SingleUserLayout';
import CompanyLayout from '@layout/CompanyLayout/CompanyLayout';
import CategoriesLayoutPage from '@layout/CategoriesLayout/CategoriesLayoutPage';
//pages
import LoginPage from '@pages/Login/Login';
import RegisterPage from '@pages/Register/Register';
import ResetPasswordForm from '@pages/ResetPassword/ResetPassword';
import SendEmailPage from '@pages/SendEmail/SendEmail';
import UsersPage from '@pages/Users/UsersPage';
import RegisterCompanyPage from '@pages/Register/RegisterCompany';
import ErrorPage from '@pages/ErrorPage/ErrorPage';
import VouchersPage from '@pages/Vouchers/VouchersPage';
import SingleVoucherPage from '@pages/Vouchers/Single/SingleVoucherPage';
import RadioPage from '@pages/Radio/RadioPage';
import SingleRadioPage from '@pages/Radio/Single/SingleRadioPage';
import AuthProvider from '@wrappers/AuthProvider/AuthProvider';
import FAQPage from '@pages/FAQ/FAQPage';
import HitListPage from '@pages/HitList/HitListPage';
import SingleHitListPage from '@pages/HitList/Single/SingleHitListPage';
import NewsPage from '@pages/News/NewsPage';
import SingleNewsPage from '@pages/News/Single/SingleNewsPage';
import SingleUserPage from '@pages/Users/Single/SingleUserPage';
import SingleBusinessJobsPage from '@pages/Users/SingleBusiness/SingleBusinessJobsPage';
import JobsPage from '@pages/Jobs/JobsPage';
import SingleJobPage from '@pages/Jobs/Single/SingleJobPage';
import UserProfileInfoPage from '@pages/BusinessUserProfile/UserProfileInfoPage';
import SoonPage from '@pages/Soon/SoonPage';
import CreateVoucherPage from '@pages/Vouchers/Create/CreateVoucherPage';
import CompanyInfoPage from '@pages/BusinessUserProfile/CompanyInfoTable';
import CompaniesPage from '@pages/CompaniesPage/CompaniesPage';
import BusinessUserJobsPage from '@pages/BusinessUserProfile/Jobs/BusinessUserJobsPage';
import SlidersPage from '@pages/Sliders/SlidersPage';
import SingleSliderPage from '@pages/Sliders/Single/SingleSliderPage';
import RubricsPage from '@pages/Rubrics/RubricsPage';
import GenresPage from '@pages/Genre/GenresPage';
import IndustriesPage from '@pages/Industries/IndustriesPage';
import SingleRubricsPage from '@pages/Rubrics/SingleRubricsPage';
import SingleIndustryPage from '@pages/Industries/SingleIndustryPage';
import SingleGenrePage from '@pages/Genre/SingleGenrePage';
import SingleInterestPage from '@pages/Interests/SingleInterestPage';
import InterestsPage from '@pages/Interests/InterestsPage';
import VoucherLayout from '@layout/VoucherLayout/VoucherLayout';
import VoucherCouponsPage from '@pages/Vouchers/Coupons/VoucherCouponsPage';
import CreateVoucherBody from '@pages/Vouchers/Create/CreateVoucherBody';
import CreateVoucherStepper from '@pages/Vouchers/Create/CreateVoucherStepper';
import SinglePodcastPage from '@pages/PodcastChannel/Podcast/SinglePodcastPage';
import PodcastChannelPage from '@pages/PodcastChannel/PodcastChannelPage';
import SingleChannelPage from '@pages/PodcastChannel/SingleChannelPage';
import NotificationsPage from '@pages/Notifications/NotificationsPage';

export const routerConfig = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Navigate to={'/profile'} />
          },
          {
            path: 'profile',
            element: <ProfilePageLayout />,
            children: [
              {
                path: '',
                element: <UserProfileInfoPage />
              }
            ]
          },

          {
            path: 'notifications',
            element: <NotificationsPage />
          },

          {
            path: 'my-vouchers',
            element: <Outlet />,
            children: [
              { path: '', element: <VouchersPage /> },
              {
                path: ':voucherId',
                element: <VoucherLayout />,
                children: [
                  { path: '', element: <SingleVoucherPage /> },
                  { path: 'coupons', element: <VoucherCouponsPage /> }
                ]
              }
            ]
          },

          {
            path: 'company/:companyId',
            element: <AuthProvider role="business" />,
            children: [
              {
                path: '',
                element: <CompanyLayout />,
                children: [
                  {
                    path: '',
                    element: <CompanyInfoPage />
                  },

                  {
                    path: 'projects',
                    element: <SoonPage />
                  },
                  {
                    path: 'jobs',
                    element: <BusinessUserJobsPage />
                  }
                ]
              },
              {
                path: 'jobs/:jobId',
                element: <SingleJobPage single />
              }
            ]
          },

          {
            path: '',
            element: <AuthProvider role="admin" />,
            children: [
              {
                path: 'users',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <UsersPage />
                  },
                  {
                    path: 'single/:userId',
                    element: <SingleUserLayout />,
                    children: [
                      {
                        path: '',
                        element: <SingleUserPage />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'companies',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CompaniesPage />
                  },
                  {
                    path: 'single/:companyId',
                    element: <CompanyLayout single />,
                    children: [
                      {
                        path: '',
                        element: <CompanyInfoPage single />
                      },

                      {
                        path: 'projects',
                        element: <SoonPage />
                      },
                      {
                        path: 'jobs',
                        element: <Outlet />,
                        children: [
                          {
                            path: '',
                            element: <SingleBusinessJobsPage />
                          },
                          {
                            path: ':jobId',
                            element: <SingleJobPage />
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'radio',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <RadioPage />
                  },
                  {
                    path: ':radioId',
                    element: <SingleRadioPage />
                  }
                ]
              },

              {
                path: 'hit-lists',
                element: <Outlet />,
                children: [
                  { path: '', element: <HitListPage /> },
                  { path: ':listId', element: <SingleHitListPage /> }
                ]
              },
              {
                path: 'jobs',
                element: <Outlet />,
                children: [
                  { path: '', element: <JobsPage /> },
                  { path: ':jobId', element: <SingleJobPage /> }
                ]
              },
              {
                path: 'vouchers',
                element: <Outlet />,
                children: [
                  { path: '', element: <VouchersPage /> },
                  {
                    path: ':voucherId',
                    element: <VoucherLayout />,
                    children: [
                      { path: '', element: <SingleVoucherPage /> },
                      { path: 'coupons', element: <VoucherCouponsPage /> }
                    ]
                  },
                  // {
                  //   path: 'create',
                  //   element: <CreateVoucherStepper activeStep={0} />,
                  //   children: [{ path: '', element: <CreateVoucherPage /> }]
                  // },
                  {
                    path: ':voucherId/edit/body',
                    element: <CreateVoucherStepper activeStep={1} />,
                    children: [{ path: '', element: <CreateVoucherBody /> }]
                  },
                  {
                    path: '',
                    element: <CreateVoucherStepper activeStep={0} />,
                    children: [
                      { path: ':voucherId/edit', element: <CreateVoucherPage /> },
                      {
                        path: 'create',
                        element: <CreateVoucherPage />
                      }
                    ]
                  }
                ]
              },

              {
                path: 'news',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <NewsPage />
                  },
                  {
                    path: ':newsId',
                    element: <SingleNewsPage />
                  }
                ]
              },
              {
                path: 'featured-posts',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <SlidersPage />
                  },
                  {
                    path: ':sliderId',
                    element: <SingleSliderPage />
                  }
                ]
              },
              {
                path: 'faq',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <FAQPage />
                  }
                ]
              },

              {
                path: 'categories',
                element: <Outlet />,
                children: [
                  {
                    path: 'rubrics/:rubricId',
                    element: <SingleRubricsPage />
                  },
                  {
                    path: 'industries/:industryId',
                    element: <SingleIndustryPage />
                  },
                  {
                    path: 'genres/:genreId',
                    element: <SingleGenrePage />
                  },
                  {
                    path: 'interests/:interestId',
                    element: <SingleInterestPage />
                  }
                ]
              },

              {
                path: 'categories',
                element: <CategoriesLayoutPage />,
                children: [
                  {
                    path: '',
                    element: <Navigate to={'rubrics'} />
                  },
                  {
                    path: 'genres',
                    element: <GenresPage />
                  },
                  {
                    path: 'rubrics',
                    element: <RubricsPage />
                  },

                  {
                    path: 'industries',
                    element: <IndustriesPage />
                  },
                  {
                    path: 'interests',
                    element: <InterestsPage />
                  }
                ]
              },

              {
                path: 'channels',
                element: <Outlet />,
                children: [
                  { path: '', element: <PodcastChannelPage /> },
                  { path: ':channelId', element: <SingleChannelPage /> },
                  { path: ':channelId/podcasts/:podcastId', element: <SinglePodcastPage /> }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: '',
        element: <AuthProvider role="no-user" />,
        children: [
          {
            path: 'login',
            element: <LoginPage />
          },
          {
            path: 'register',
            element: <RegisterPage />
          },
          {
            path: 'reset-password-form',
            element: <ResetPasswordForm />
          },
          {
            path: 'reset-password',
            element: <SendEmailPage />
          },
          {
            path: 'recover-password',
            element: <div>recover-password page</div>
          }
        ]
      },
      {
        path: '',
        element: <AuthProvider />,
        children: [
          {
            path: 'register/company',
            element: <RegisterCompanyPage />
          }
        ]
      }
    ]
  }
]);
