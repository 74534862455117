import { NavLink } from 'react-router-dom';
//components
import FormModal from '@components/FormModal/FormModal';
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Typography } from '@mui/material';
//hooks
import { useCompany } from '@hooks/useCompany/useCompany';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
//type
import { CompanyType } from '@interfaces/company/company';
import { MRT_ColumnDef } from 'material-react-table';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
//helpers
import { getTranslationValue } from '@helpers/utility';
import { TranslationType } from '@interfaces/translation';
import { CountryType } from '@interfaces/autocomplete/autocomplete';

const CompaniesPage = () => {
  const {
    companies,
    popup,
    onDeleteCompanyClick,
    isLoadingAll,
    pagination,
    setPagination,
    rowCount,
    columnFilters,
    setColumnFilters,
    industries,
    isLoadingDelete
  } = useCompany();

  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<CompanyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.name'),
        Cell: ({ row }) => <NavLink to={'single/' + row.original.id}>{row.original.name}</NavLink>
      },
      {
        accessorKey: 'countryNew.name',
        header: t('global.country'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'industries',
        header: t('global.industry'),

        Cell: ({ row }) => row.original.industries?.map(i => i.name).join(', '),
        filterVariant: 'select',
        filterSelectOptions: industries.map(i => {
          return {
            text: getTranslationValue(
              i.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            ),
            value: i.id
          };
        })
      },
      {
        accessorKey: 'visible',
        header: t('global.visible'),
        Cell: ({ row }) => t(`global.${row.original.visible}`),
        filterVariant: 'select',
        filterSelectOptions: [
          { text: t(`global.true`), value: 'true' },
          { text: t(`global.false`), value: 'false' }
        ]
      }
    ],
    [t, companies, industries]
  );

  const handleDelete = (data: CompanyType) => {
    onDeleteCompanyClick(data.id);
  };

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingDelete}
        />
      )}
      <Box my={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('user.company.title')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('user.company.subtitle')}
        </Typography>
      </Box>

      <TableLayout
        columns={columns}
        data={companies}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={rowCount}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        handleDelete={handleDelete}
        isLoading={isLoadingAll}
      />
    </Box>
  );
};

export default CompaniesPage;
