//type
import { StatusEnum } from '@enum/progressEnum';
import { AllHitListsResponseType, HitListType } from '@interfaces/hitList/hitList';
import { ErrorsResponseType } from '@interfaces/response';
//api
import { getAllHitLists, getSingleHitList } from '@services/hitList/hitList';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllHitLists = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: {
    page?: number;
    take?: number;
    search?: string;
    status?: StatusEnum;
  }
): UseQueryResult<AllHitListsResponseType, ErrorsResponseType<{}>> =>
  useQuery(['hit-lists', params], () => getAllHitLists(params), {
    select: res => res.data,
    onError: err => onError(err),
    retry: false
  });

export const useSingleHitList = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<HitListType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['hit-list', id], () => (id ? getSingleHitList(id) : null), {
    select: res => res?.data,
    onError: err => onError(err),
    enabled: !!id
  });
