//hooks
import { useMemo } from 'react';
import { useJobs } from '@hooks/useJobs/useJobs';
import { useTranslation } from 'react-i18next';
//components
import FormModal from '@components/FormModal/FormModal';
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Button, Chip, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
//helpers
import { getJobKeys } from '@helpers/profileFields/profileFields';
import { getColorByStatus, getTranslationValue } from '@helpers/utility';
import dayjs from 'dayjs';
//type
import { JobType } from '@interfaces/job/job';
import { MRT_ColumnDef } from 'material-react-table';
//enum
import { JobStatusEnum } from '@enum/companyEnum';
import { Navigate } from 'react-router-dom';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const SingleJobPage = ({ single }: { single?: boolean }) => {
  const { t, i18n } = useTranslation();
  const {
    popup,
    singleJob,
    onDeclineClick,
    onConfirmClick,
    onDeleteClick,
    isLoadingSingle,
    isLoadingDelete,
    isLoadingUpdateStatus
  } = useJobs(!!single);

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: keyof JobType }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ row }) => {
          if (row.original.key === 'company') {
            return <Typography>{singleJob?.company.name}</Typography>;
          }
          if (singleJob && (row.original.key === 'createdAt' || row.original.key === 'expiredAt')) {
            return (
              <Typography>{dayjs(singleJob?.[row.original.key]).format('DD.MM.YYYY.')}</Typography>
            );
          }

          if (row.original.key === 'industry')
            return (
              <Typography>
                {singleJob
                  ? getTranslationValue(
                      singleJob.industry.translations,
                      'name',
                      i18n.language as SupportedLanguagesEnum
                    )
                  : ''}
              </Typography>
            );

          if (row.original.key === 'tags' || row.original.key === 'hashTags')
            return <Typography>{singleJob?.[row.original.key]?.join(', ')}</Typography>;

          if (row.original.key === 'status')
            return <Typography>{t(`status.${singleJob?.[row.original.key]}`)}</Typography>;

          return <Typography>{singleJob?.[row.original.key]?.toString()}</Typography>;
        }
      }
    ],
    [singleJob]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleJob) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box className="container" display={'flex'} flexDirection={'column'} gap={2}>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          errors={popup.errors}
          isLoading={isLoadingUpdateStatus || isLoadingDelete}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {t('global.jobs')}
        </Typography>
        <Box display={'flex'} gap={5}>
          <Typography component="div" variant="h4" fontWeight={'bold'}>
            {singleJob?.name} / {singleJob.company.name}
          </Typography>
          <Chip
            label={t(`status.${singleJob.status}`)}
            variant="outlined"
            color={getColorByStatus(singleJob.status)}
          />
        </Box>
      </Box>

      <Box mt={2}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
            {t('global.basic_info')}
          </Typography>
          <Box>
            {!single && (
              <>
                {(singleJob.status === JobStatusEnum.PENDING ||
                  singleJob.status === JobStatusEnum.BLOCKED) && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={() => {
                      onConfirmClick();
                    }}
                  >
                    {t('global.approve')}
                  </Button>
                )}
                {singleJob.status === JobStatusEnum.PENDING && (
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                      onDeclineClick();
                    }}
                  >
                    {t('global.decline')}
                  </Button>
                )}
              </>
            )}
            <Button
              variant="outlined"
              color="error"
              size="small"
              sx={{ ml: 5 }}
              onClick={() => {
                onDeleteClick();
              }}
            >
              {t('global.delete')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box width={'70%'}>
        <Typography component="div" variant="body2">
          {singleJob?.description}
        </Typography>
      </Box>
      <Box>
        <TableLayout data={getJobKeys(t)} columns={columns} />
      </Box>
    </Box>
  );
};

export default SingleJobPage;
