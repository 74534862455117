//type
import {
  AllPodcastChannelsType,
  AllPodcastsType,
  PodcastChannelType,
  PodcastType,
  PodcastTypeEnum
} from '@interfaces/podcast/podcast';
//api
import {
  getAllPodcastChannel,
  getAllPodcasts,
  getSinglePodcast,
  getSinglePodcastChannel
} from '@services/podcast/podcast';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ErrorsResponseType } from '@interfaces/response';

export const useAllPodcasts = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: {
    page: number;
    take: number;
    order?: 'ASC' | 'DESC';
    type?: PodcastTypeEnum;
    channelId?: string;
  }
): UseQueryResult<AllPodcastsType, ErrorsResponseType<{}>> =>
  useQuery(['podcasts', params], () => getAllPodcasts(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: !!params?.channelId
  });

export const useSinglePodcast = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<PodcastType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['podcast', id], () => (id ? getSinglePodcast(id) : null), {
    onError: err => onError(err),
    select: res => (res ? res.data : undefined),
    enabled: !!id
  });

export const useAllPodcastChannels = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: { page: number; take: number; type?: string; order?: 'ASC' | 'DESC' },
  disabled?: boolean
): UseQueryResult<AllPodcastChannelsType, ErrorsResponseType<{}>> =>
  useQuery(['podcast-channels', params], () => getAllPodcastChannel(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: !disabled
  });

export const useSinglePodcastChannel = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<PodcastChannelType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['podcast-channel', id], () => (id ? getSinglePodcastChannel(id) : null), {
    onError: err => onError(err),
    select: res => (res ? res.data : undefined),
    enabled: !!id
  });
