import { Navigate, useNavigate } from 'react-router-dom';
//components
import { Box, Button, Grid, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
import LanguageTabs from '@components/LanguagesTabs/LanguageTabs';
//type
import { InputTypeEnum } from '@enum/formModal';
//hooks
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { useTranslation } from 'react-i18next';
//api
import axiosInstance from '@services/config';

const CreateVoucherBody = () => {
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    handleCreate,
    voucherId,
    singleVoucher,
    getValues,
    clearErrors,
    isLoadingSingle,
    isSubmitting
  } = useVoucher();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await axiosInstance.patch(`v1/vouchers/${voucherId}/body-images`, formData).then(res => {
      return {
        success: 1,
        file: {
          url: res.data.imageUrl
        }
      };
    });
  };

  if (isLoadingSingle) return <Loading />;
  if (!singleVoucher) return <Navigate to={'/error-page'} />;
  return (
    <div className="container">
      <Typography variant="h5" fontWeight={'bold'}>
        {singleVoucher?.title}
      </Typography>

      <Typography color={'grey'}>{t('voucher.text_title')}</Typography>

      <Box component={'form'} onSubmit={handleSubmit(handleCreate)}>
        {getValues(`translations.${i18n.language}.body`) !== undefined && (
          <Grid container spacing={1}>
            <LanguageTabs
              control={control}
              setValue={setValue}
              setError={setError}
              getValues={getValues}
              clearErrors={clearErrors}
              translationFields={[
                {
                  key: 'body',
                  type: InputTypeEnum.editor,
                  label: t('voucher.text'),
                  uploadImage: uploadImage
                },
                {
                  key: 'body',
                  type: InputTypeEnum.parser,
                  label: t('voucher.text')
                }
              ]}
            />
          </Grid>
        )}

        <Box mt={2} display={'flex'} gap={1} justifyContent={'flex-end'}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate(`/vouchers/${voucherId}/edit`)}
            disabled={isSubmitting}
          >
            {t('global.back')}
          </Button>

          <Button variant="contained" type="submit" color="primary">
            {t('global.accept')}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CreateVoucherBody;
