//react
import { useCallback, useRef } from 'react';
//react-query
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';

const useLastElementScroll = (
  isLoading: boolean,
  isFetching: boolean,
  hasNextPage: boolean | undefined,
  fetchNextPage:
    | ((
        options?: FetchNextPageOptions | undefined
      ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>)
    | undefined
) => {
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLElement) => {
      if (isLoading || isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (fetchNextPage && entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, isFetching]
  );

  return {
    lastElementRef
  };
};

export default useLastElementScroll;
