import axios from 'axios';
import routes from './routes';
import { CLIENT_ID, CLIENT_SECRET, REDIRECT_URL } from '@helpers/env.config';

type FetchTokenParams = {
  username?: string | null;
  password?: string | null;
  code?: string | null;
};

export const fetchToken = async ({
  username,
  password,
  code
}: FetchTokenParams): Promise<string | null> => {
  const payload = {
    grant_type: code ? 'authorization_code' : 'password',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: 'openid profile'
  };

  const data = code
    ? { ...payload, code, redirect_uri: REDIRECT_URL }
    : { ...payload, username, password };

  const tokenResponse = await axios.post(routes.token, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  if (tokenResponse) {
    localStorage.setItem('token', tokenResponse.data.access_token);
    localStorage.setItem('refresh_token', tokenResponse.data.refresh_token);
    return tokenResponse.data.access_token;
  }

  return null;
};

export const logOut = async () => {
  const data = {
    client_id: CLIENT_ID,
    refresh_token: localStorage.getItem('refresh_token'),
    redirect_uri: REDIRECT_URL,
    client_secret: CLIENT_SECRET
  };

  const logOutResponse = await axios.post(routes.logout, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  if (logOutResponse && logOutResponse.status === 204) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }
};

export const refreshToken = async () => {
  const data = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token: localStorage.getItem('refresh_token'),
    scope: 'openid profile'
  };

  try {
    const tokenResponse = await axios.post(routes.token, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (tokenResponse) {
      localStorage.setItem('token', tokenResponse.data.access_token);
      localStorage.setItem('refresh_token', tokenResponse.data.refresh_token);
    }
  } catch (e: any) {
    console.log('Error refresh token: ', e);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    logOut();
    window.dispatchEvent(new Event('logout'));
  }
};
