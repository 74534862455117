import global from './fr/global.json';
import sidebar from './fr/sidebar.json';
import category from './fr/category.json';
import deleteTranslation from './fr/delete.json';
import faq from './fr/faq.json';
import slider from './fr/slider.json';
import hit_list from './fr/hit_list.json';
import interests from './fr/interests.json';
import job from './fr/job.json';
import news from './fr/news.json';
import radio from './fr/radio.json';
import status from './fr/status.json';
import user from './fr/user.json';
import voucher from './fr/voucher.json';
import login from './fr/login.json';
import verify_email from './fr/verify_email.json';
import podcast from './fr/podcast.json';
import steps from './fr/steps.json';

export const translationFrench = {
  global,
  sidebar,
  category,
  delete: deleteTranslation,
  faq,
  slider,
  hit_list,
  interests,
  job,
  news,
  radio,
  status,
  user,
  voucher,
  login,
  verify_email,
  podcast,
  steps
};
