import {
  useAllGenres,
  useAllInterests,
  useSingleGenre,
  useSingleInterest
} from '@api/queries/interests/interests';
//helpers
import { formHelper } from '@helpers/formDataHelper';
//type
import { CreateInterestsType, InterestsType } from '@interfaces/interests/interests';

import { useForm } from 'react-hook-form';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { checkForEmptyTranslation } from '@helpers/utility';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { MRT_PaginationState } from 'material-react-table';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import {
  useCreateInterest,
  useDeleteInterest,
  useDeleteInterestImage,
  useUpdateInterest
} from '@api/mutations/interests/interests';

export function useInterests() {
  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t } = useTranslation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    getValues
  } = useForm<CreateInterestsType>();

  const { interestId } = useParams();

  const [voucherPagination, setVoucherPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const onSuccessDelete = () => {
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
    refetchInterests();
    reset({
      translations: {
        sr: { name: '' },
        en: { name: '' },
        de: { name: '' },
        fr: { name: '' }
      },
      file: '',
      id: undefined
    });
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onSuccessCreate = (data: InterestsType) => {
    const val = getValues();
    if (val.file === null) {
      deleteInterestImage(data.id);
    } else {
      setInfoPopup({
        title: val.id ? t('global.success_update') : t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
      refetchInterests();
      reset({
        translations: {
          sr: { name: '' },
          en: { name: '' },
          de: { name: '' },
          fr: { name: '' }
        },
        file: '',
        id: undefined
      });
    }
  };

  const onErrorCreate = (err: ErrorType<InterestsType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });

    if (err.errors?.translations) {
      setError('root', {
        type: 'custom',
        message: err.errors.translations.join(', ')
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const {
    data: interests,
    refetch: refetchInterests,
    isLoading: isLoadingInterests
  } = useAllInterests(onError);

  const {
    mutate: createInterest,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreateInterest(onSuccessCreate, onErrorCreate);
  const {
    mutate: updateInterest,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdateInterest(onSuccessCreate, onErrorCreate);
  const { mutate: deleteInterest, isLoading: isLoadingDelete } = useDeleteInterest(
    onSuccessDelete,
    onError
  );

  const { data: singleInterest, isLoading: isLoadingSingle } = useSingleInterest(
    interestId,
    onError
  );

  const { mutate: deleteInterestImage } = useDeleteInterestImage(onSuccessDelete, onError);

  const onSubmitInterest = (data: CreateInterestsType) => {
    if (checkForEmptyTranslation(data.translations)) {
      setError('root', {
        type: 'custom',
        message: t('global.translation_error_message')
      });
      return;
    }
    if (data.id) {
      if (data.file === null || typeof data.file === 'string') {
        updateInterest({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations })
        });
      } else {
        updateInterest({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations, file: data.file })
        });
      }
    } else {
      delete data.id;
      createInterest(formHelper.getBody({ translations: data.translations, file: data.file }));
    }
  };

  return {
    interests: interests ? interests : [],
    refetchInterests,
    isLoadingInterests: false,
    onSubmitInterest,
    deleteInterest,
    isSuccessInterest: isCreateSuccess || isUpdateSuccess,
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    errors,
    singleInterest,
    isLoadingSingle,
    voucherPagination,
    setVoucherPagination,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
