import { useEffect, useState } from 'react';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
import { RegisterNotificationTokenResponseType } from '@interfaces/notifications/notifications';
import { useRegisterNotificationToken } from '@api/mutations/notification/notification';
import { VITE_VAPID_KEY } from '@helpers/env.config';

const useFcmToken = () => {
  const [token, setToken] = useState('');
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');

  const onSuccess = (res: RegisterNotificationTokenResponseType) => {
    // console.log('Success register firebase token: ', res);
  };

  const onError = (err: Error) => {
    console.log('Error register firebase token: ', err.message);
  };

  const { mutate: registerNotificationToken } = useRegisterNotificationToken(onSuccess, onError);

  const retrieveToken = async () => {
    console.log('get token');
    if (localStorage.getItem('firebase_token')) {
      return;
    }
    try {
      if (typeof window !== 'undefined') {
        // Retrieve the notification permission status
        const permission = await Notification.requestPermission();
        setNotificationPermissionStatus(permission);

        // Check if permission is granted before retrieving the token
        const mess = await messaging();

        if (permission === 'granted' && mess) {
          const currentToken = await getToken(mess, {
            vapidKey: VITE_VAPID_KEY
          });

          if (currentToken) {
            localStorage.setItem('firebase_token', currentToken);
            setToken(currentToken);
            registerNotificationToken({
              app: 'web',
              fcmToken: currentToken
            });
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }
        if (!mess) {
          console.log('not supportder notification permission');
        }
      }
    } catch (error) {
      console.log('An error occurred while retrieving token:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('login', retrieveToken);

    return () => {
      window.removeEventListener('login', retrieveToken);
    };
  }, []);

  return { fcmToken: token, notificationPermissionStatus };
};

export default useFcmToken;
