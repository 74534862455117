//react
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//interfaces
import { MRT_PaginationState, MRT_ColumnFiltersState } from 'material-react-table';
import { CreateRadioType, RadioProgramErrorType, RadioType } from '@interfaces/radio/radio';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { AxiosError } from 'axios';
//hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
//services
import { useAllRadioStations, useSingleRadioStation } from '@api/queries/radioStatios';
import {
  useCreateRadioProgram,
  useCreateRadioStation,
  useDeleteRadioImage,
  useDeleteRadioProgram,
  useDeleteRadioStation,
  useUpdateRadioImages,
  useUpdateRadioStation
} from '@api/mutations/radioStations';
//helpers
import { formHelper } from '@helpers/formDataHelper';
import { radioFields } from '@helpers/formFields/formFields';
import { getDefaultPagnation } from '@helpers/utility';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { useAllGenres } from '@api/queries/interests/interests';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

export function useRadio() {
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [values, setValues] = useState<CreateRadioType | undefined>();
  const [success, setSuccess] = useState(false);
  const [popup, setPopup] = useState<FormModalPropsType<CreateRadioType> | null>(null);

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { radioId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const onSuccessSingle = (data: RadioType) => {
    setValues({
      ...data,
      genres: data.genres.map(c => c.id)
    });
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const {
    data: singleRadio,
    refetch: refetchSingle,
    isLoading: isLoadingSingle
  } = useSingleRadioStation(radioId, onError, onSuccessSingle, i18n.language);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
    getValues
  } = useForm<CreateRadioType>({
    values
  });

  const onSuccessUpdate = (data: RadioType) => {
    const pom = getValues();

    const deleteTypes = [];
    let updateImages: undefined | Object;

    if (typeof pom.image !== 'string') {
      if (pom.image === null) {
        deleteTypes.push('image');
      } else {
        updateImages = { image: pom.image };
      }
    }
    if (typeof pom.logo !== 'string') {
      if (pom.logo === null) {
        deleteTypes.push('logo');
      } else {
        updateImages = updateImages ? { ...updateImages, logo: pom.logo } : { logo: pom.logo };
      }
    }
    if (typeof pom.banner !== 'string') {
      if (pom.banner === null) {
        deleteTypes.push('banner');
      } else {
        updateImages = updateImages
          ? { ...updateImages, banner: pom.banner }
          : { banner: pom.banner };
      }
    }
    if (updateImages === undefined && deleteTypes.length === 0) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 300);
    } else {
      if (updateImages !== undefined)
        updateRadioImages({
          id: data.id,
          data: formHelper.getBody({ image: pom.image, logo: pom.logo, banner: pom.banner })
        });

      if (deleteTypes.length !== 0) {
        deleteRadioImages({ id: data.id, type: deleteTypes });
      }
    }
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onSuccessCreate = (data: RadioType) => {
    const program = getValues().file;
    const pom = getValues();

    if (program) {
      const formData = new FormData();
      formData.append('file', program);
      createRadioProgram({ radioId: data.id, data: formData });
    } else {
      onSuccessCreateRadioProgram();
    }
    updateRadioImages({
      id: data.id,
      data: formHelper.getBody({ image: pom.image, logo: pom.logo, banner: pom.banner })
    });
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onErrorCreate = (err: ErrorType<CreateRadioType>) => {
    setSuccess(false);
    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof CreateRadioType, {
          type: 'custom',
          message: err.errors[key as keyof CreateRadioType]?.join(', ')
        });
      }
    else if (err.statusCode === 409) {
      setError('name', {
        type: 'custom',
        message: err.message
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
      setError('root', {
        type: 'custom',
        message: err.message
      });
    }
  };

  const onSuccessDelete = () => {
    if (radioId) {
      navigate(-1);
    } else {
      refetch();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 300);
    }
  };

  const onSuccessDeleteProgram = () => {
    refetchSingle();
    setPopup(null);
  };

  const onCreateProgramError = (err: RadioProgramErrorType) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
  };

  const onSuccessCreateRadioProgram = () => {
    if (radioId) {
      setPopup(null);
      refetchSingle();
      setInfoPopup({
        title: t('global.success_update'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
    } else {
      refetch();
      setSuccess(true);
      setInfoPopup({
        title: t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
      setTimeout(() => {
        setSuccess(false);
      }, 300);
    }

    reset();
  };

  const onSuccessUpdateRadioImages = () => {
    setPopup(null);
    setSuccess(true);
    refetchSingle();
    setTimeout(() => {
      setSuccess(false);
    }, 300);
  };
  const { data: genres } = useAllGenres(onError);

  const {
    data: radios,
    refetch,
    isLoading: isLoadingAll
  } = useAllRadioStations(onError, {
    order: 'ASC',
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    search: columnFilters.find(el => el.id === 'name')?.value as string,
    genreId: columnFilters.find(el => el.id === 'genres')?.value as string,
    lang: i18n.language
  });

  const { mutate: createRadio, isLoading: isLoadingCreate } = useCreateRadioStation(
    onSuccessCreate,
    onErrorCreate
  );
  const { mutate: updateRadio, isLoading: isLoadingUpdate } = useUpdateRadioStation(
    onSuccessUpdate,
    onErrorCreate
  );
  const { mutate: deleteRadio, isLoading: isLoadingDeleteRadio } = useDeleteRadioStation(
    onSuccessDelete,
    onError
  );
  const { mutate: createRadioProgram, isLoading: isLoadingCreateRadioProgram } =
    useCreateRadioProgram(onSuccessCreateRadioProgram, onCreateProgramError);
  const { mutate: deleteRadioProgram, isLoading: isLoadingDeleteRadioProgram } =
    useDeleteRadioProgram(onSuccessDeleteProgram, onError);
  const { mutate: updateRadioImages } = useUpdateRadioImages(onSuccessUpdateRadioImages, onError);
  const { mutate: deleteRadioImages } = useDeleteRadioImage(onError, onSuccessUpdateRadioImages);

  const onSubmit = (data: CreateRadioType) => {
    if (singleRadio && data.file && typeof data.file !== 'string') {
      const formData = new FormData();
      formData.append('file', data.file);
      createRadioProgram({ radioId: singleRadio.id, data: formData });
      return;
    }

    const newRadio: CreateRadioType = {
      name: data.name,
      genres: data.genres,
      streamSource: data.streamSource,
      isDefault: data.isDefault,
      lcn: Number(data.lcn)
    };

    if (newRadio.lcn !== undefined && newRadio.lcn < 1) {
      delete newRadio.lcn;
    }

    if (radioId) {
      updateRadio({
        id: radioId,
        data: newRadio
      });
    } else {
      createRadio(newRadio);
    }
  };

  const handleEditRadioProgram = (data: CreateRadioType) => {
    if (singleRadio && data.file && typeof data.file !== 'string') {
      const formData = new FormData();
      formData.append('file', data.file);
      createRadioProgram({ radioId: singleRadio.id, data: formData });
      return;
    } else {
      setPopup(null);
    }
  };

  const onCancel = () => {
    setPopup(null);
    reset();
  };

  const onEditRadioClick = () => {
    setPopup({
      title: t('radio.form.edit_title'),
      open: true,
      control: control,
      fields: radioFields(t, genres ? genres : [], true, false),
      onCancel: onCancel,
      onSubmit: handleSubmit(onSubmit as () => void),
      setValue: setValue,
      buttonText: t('global.edit'),
      variant: 'form',
      setError: setError
    });
  };
  const onDeleteRadioClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.radio`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.radio`) }),
      open: true,
      fields: [],
      onCancel: () => {},
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (radioId) {
          deleteRadio(radioId);
        }
      }
    });
  };
  const onEditRadioProgramClick = () => {
    setPopup({
      title: t('radio.program.add_title'),
      open: true,
      control: control,
      fields: radioFields(t, genres ? genres : [], true, true),
      onCancel: onCancel,
      onSubmit: handleSubmit(handleEditRadioProgram as () => void),
      setValue: setValue,
      buttonText: t('global.edit'),
      variant: 'form',
      setError: setError
    });
  };
  const onDeleteRadioProgramClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.radio_program`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.radio_program`) }),
      open: true,
      fields: [],
      onCancel: () => {},
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (radioId) {
          deleteRadioProgram(radioId);
        }
      }
    });
  };

  useEffect(() => {
    if (location.pathname === '/radio')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    handleSubmit,
    onSubmit,
    onCancel,
    deleteRadio,
    radios: radios?.data ? radios.data : [],
    pagination,
    setPagination,
    control,
    setValue,
    columnFilters,
    setColumnFilters,
    singleRadio,
    isLoadingAll,
    isLoadingSingle,
    reset,
    genres: genres ? genres : [],
    radioId,
    setError,
    errors,
    success,
    deleteRadioProgram,
    popup,
    setPopup,
    onEditRadioClick,
    onDeleteRadioClick,
    onDeleteRadioProgramClick,
    onEditRadioProgramClick,
    rowCount: radios ? radios.meta.itemCount : 0,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDeleteRadio,
    isSubmittingProgram: isLoadingCreateRadioProgram || isLoadingDeleteRadioProgram
  };
}
