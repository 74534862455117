import { useState } from 'react';
//components
import { Box, FormControl, FormHelperText, TextField, Autocomplete, ListItem } from '@mui/material';
//react form
import {
  ControllerRenderProps,
  FieldError,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form';
//types
import { MappedFieldsType } from '@interfaces/modalForm';
//hooks
import { useTranslation } from 'react-i18next';
//helpers
import dayjs, { Dayjs } from 'dayjs';
import { checkDate } from '@helpers/utility';

const days = [...Array(31).keys()].map(el => `${el + 1}`);
const months = [...Array(12).keys()].map(el => `${el + 1}`);

const DatePickerCustom = <T extends {}>({
  invalid,
  option,
  error,
  field,
  setValue,
  setError,
  clearErrors,
  defaultValue
}: {
  error: FieldError | undefined;
  option: MappedFieldsType;
  setError: UseFormSetError<T>;
  invalid: boolean;
  field: ControllerRenderProps<T>;
  setValue: UseFormSetValue<T>;
  clearErrors: UseFormClearErrors<T> | undefined;
  defaultValue?: Dayjs;
}) => {
  const years = [...Array(100).keys()].map(el => {
    if (option.minDate) return `${new Date().getFullYear() + el}`;
    return `${new Date().getFullYear() - el}`;
  });

  const { t } = useTranslation();

  const [fullDate, setDate] = useState<{ day: string; month: string; year: string }>({
    day: defaultValue ? defaultValue.date().toString() : '',
    month: defaultValue ? (defaultValue.month() + 1).toString() : '',
    year: defaultValue ? defaultValue.year().toString() : ''
  });

  const handleChange = (key: 'day' | 'month' | 'year', value: string | null, reason: string) => {
    setDate(prev => {
      return {
        ...prev,
        [key]: value
      };
    });

    if (reason === 'clear') {
      setValue(option.key as Path<T>, null as PathValue<T, Path<T>>);
      if (clearErrors) {
        clearErrors(option.key as Path<T>);
      }
      return;
    }

    const tmpDate = { ...fullDate };
    tmpDate[key] = value ?? '';

    if (tmpDate.day && tmpDate.month && tmpDate.year) {
      if (clearErrors) {
        clearErrors(option.key as Path<T>);
      }

      const date = new Date(Number(tmpDate.year), Number(tmpDate.month) - 1, Number(tmpDate.day));

      if (
        date.getFullYear() != Number(tmpDate.year) ||
        date.getMonth() != Number(tmpDate.month) - 1 ||
        date.getDate() != Number(tmpDate.day)
      ) {
        setError(option.key as Path<T>, {
          type: 'custom',
          message: t('global.invalid_date')
        });
      } else {
        if (option.minDate?.isAfter(date)) {
          setError(option.key as Path<T>, {
            type: 'custom',
            message: t('global.invalid_date')
          });
          return;
        }
        if (option.maxDate?.isBefore(date)) {
          setError(option.key as Path<T>, {
            type: 'custom',
            message: t('global.invalid_date')
          });
          return;
        }

        if (
          option.minDate &&
          (date.getFullYear() < Number(years[0]) ||
            date.getFullYear() > Number(years[years.length - 1]))
        ) {
          setError(option.key as Path<T>, {
            type: 'custom',
            message: t('global.invalid_date')
          });
          return;
        }
        if (
          !option.minDate &&
          (date.getFullYear() < Number(years[years.length - 1]) ||
            date.getFullYear() > Number(years[-0]))
        ) {
          setError(option.key as Path<T>, {
            type: 'custom',
            message: t('global.invalid_date')
          });
          return;
        }

        setValue(option.key as Path<T>, dayjs(date) as PathValue<T, Path<T>>);

        const err = checkDate(option, dayjs(date), t);

        if (err) {
          setError(option.key as Path<T>, {
            type: 'custom',
            message: err
          });
        } else {
          if (clearErrors) clearErrors(option.key as Path<T>);
        }
      }
    }
  };

  return (
    <FormControl fullWidth error={invalid} required={option.required}>
      <FormHelperText>{option.label}</FormHelperText>
      <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          options={days}
          ListboxProps={{ style: { maxHeight: 150 } }}
          value={fullDate?.day ? fullDate.day.toString() : ''}
          onChange={(e, newOptions, reason) => handleChange('day', newOptions, reason)}
          onInputChange={(e, newOptions, reason) => handleChange('day', newOptions, reason)}
          renderOption={(props, option) => (
            <ListItem {...props} value={option}>
              {option}
            </ListItem>
          )}
          renderInput={params => (
            <TextField
              InputProps={params.InputProps}
              inputProps={params.inputProps}
              label={t('global.day')}
              error={invalid}
              helperText={error?.message}
              required={option.required}
            />
          )}
        />

        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          ListboxProps={{ style: { maxHeight: 150 } }}
          options={months}
          value={fullDate?.month ? Number(fullDate.month).toString() : ''}
          onChange={(e, newOptions, reason) => handleChange('month', newOptions, reason)}
          onInputChange={(e, newOptions, reason) => handleChange('month', newOptions, reason)}
          renderOption={(props, option, state) => (
            <ListItem {...props} value={option}>
              {option}
            </ListItem>
          )}
          renderInput={params => (
            <TextField
              InputProps={params.InputProps}
              inputProps={params.inputProps}
              label={t('global.month')}
              error={invalid}
              helperText={error?.message}
              required={option.required}
            />
          )}
        />

        <Autocomplete
          fullWidth
          disablePortal
          freeSolo
          ListboxProps={{ style: { maxHeight: 150 } }}
          options={years}
          value={fullDate?.year ? fullDate.year.toString() : ''}
          onChange={(e, newOptions, reason) => handleChange('year', newOptions, reason)}
          onInputChange={(e, newOptions, reason) => handleChange('year', newOptions, reason)}
          renderOption={(props, option, state) => (
            <ListItem {...props} value={option}>
              {option}
            </ListItem>
          )}
          renderInput={params => (
            <TextField
              InputProps={params.InputProps}
              inputProps={params.inputProps}
              label={t('global.year')}
              error={invalid}
              helperText={error?.message}
              required={option.required}
            />
          )}
        />
      </Box>

      <FormHelperText color="error">
        {/* {dateErrorHandler(error, option, field.value, setError, t)} */}
      </FormHelperText>
    </FormControl>
  );
};

export default DatePickerCustom;
