import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
//components
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPasswordForm: React.FC = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      password_confirmation: ''
    }
  });

  const onSubmit = (data: { password: string; password_confirmation: string }) => {
    console.log(data);
  };

  return (
    <Box
      px={5}
      display={'flex'}
      alignItems={'center'}
      height={'100%'}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color={'primary'} variant="h3" fontWeight={'bold'} my={3}>
            Zaboravljena lozinka
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth>
                <InputLabel error={invalid}>{'Nova lozinka'}</InputLabel>
                <OutlinedInput
                  label={'Nova lozinka'}
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="password_confirmation"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth>
                <InputLabel error={invalid}>{'Nova lozinka ponovo'}</InputLabel>
                <OutlinedInput
                  label={'Nova lozinka ponovo'}
                  type={showPassword ? 'text' : 'password'}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth variant="contained" type="submit" size="large">
            Promeni lozinku
          </Button>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <NavLink to={'/login'} replace className={'link'}>
            Nazad na prijavljivanje
          </NavLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPasswordForm;
