import { AllNewsType, NewsType } from '@interfaces/news/news';
import axiosInstance from '@services/config';

export const getAllNews = (pagination?: {
  page?: number;
  take?: number;
  search?: string;
  rubricId?: string;
  lang?: string;
  countries?: string[];
}): Promise<{ data: AllNewsType }> =>
  axiosInstance.get('v2/news/pageable', {
    params: { ...pagination, order: 'DESC' }
  });

export const getSingleNews = (id: string, lang?: string): Promise<{ data: NewsType }> =>
  axiosInstance.get('v2/news/' + id, { params: { lang } });

export const editNews = (data: { id: string; data: FormData }): Promise<{ data: NewsType }> =>
  axiosInstance.patch('v1/news/' + data.id, data.data);

export const deleteNewsImages = (id: string, type: string[]): Promise<{}> =>
  axiosInstance.delete(`v1/news/${id}/images?type=${type}`);
