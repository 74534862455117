import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  debounce
} from '@mui/material';
import useLastElementScroll from '@hooks/useLastElementScroll/useLastElementScroll';
import { Control, Controller, Path, PathValue, UseFormSetValue } from 'react-hook-form';
//react-query
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

type AutocompleteInfiniteScrollPropsType<T extends {}> = {
  name: Path<T>;
  control: Control<T>;
  required: boolean;
  options: { id: string; name: string }[] | undefined;
  label: string;
  infinityOptions: {
    isLoading: boolean;
    isFetching: boolean;
    hasNextPage?: boolean;
    fetchNextPage?: (
      options?: FetchNextPageOptions | undefined
    ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
  };
  setValue: UseFormSetValue<T>;
  setSearch: Dispatch<SetStateAction<string>>;
};

const AutocompleteInfiniteScroll = <T extends {}>({
  infinityOptions,
  name,
  control,
  required,
  label,
  setValue,
  setSearch,
  options = []
}: AutocompleteInfiniteScrollPropsType<T>) => {
  const { lastElementRef } = useLastElementScroll(
    infinityOptions.isLoading,
    infinityOptions.isFetching,
    infinityOptions.hasNextPage,
    infinityOptions.fetchNextPage
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={'' as PathValue<T, Path<T>>}
      rules={{ required: required }}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl fullWidth sx={{ position: 'relative' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            loadingText={<CircularProgress />}
            ListboxProps={{ sx: { maxHeight: '15rem' } }}
            getOptionLabel={option => option.name}
            loading={infinityOptions.isFetching}
            renderOption={(props, op, index) => {
              return (
                <MenuItem {...props} value={op.id} key={op.id}>
                  <Box ref={options.length === index.index + 1 ? lastElementRef : undefined}>
                    {op.name}
                  </Box>
                </MenuItem>
              );
            }}
            isOptionEqualToValue={(
              option: { id: string; name: string },
              value: { id: string; name: string }
            ) => option.id === value.id}
            renderInput={params => (
              <TextField
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                label={label}
                error={invalid}
                helperText={error?.message}
                value={field.value}
              />
            )}
            onChange={(e, val) => {
              setValue(name, val?.id as PathValue<T, Path<T>>);
            }}
            onInputChange={debounce((ev, val) => {
              setSearch(val);
            }, 300)}
          />
        </FormControl>
      )}
    />
  );
};

export default AutocompleteInfiniteScroll;
