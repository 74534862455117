//hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//api
import {
  useAllUsers,
  useSingleUser,
  useUsersCelebrations,
  useUsersGenres,
  useUsersIndustries,
  useUsersInterests,
  useUsersRubrics
} from '@api/queries/users/users';
import { useDeleteUser, useExportUsersData } from '@api/mutations/user/user';
//type
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
import { ExportUsersParamsType, User } from '@interfaces/users/users';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { ErrorsResponseType } from '@interfaces/response';
import { RoleEnum } from '@enum/roleEnum';
//helpers
import { dayjs, getDefaultPagnation, selectedTab } from '@helpers/utility';
import { exportUsersOptionsFields } from '@helpers/formFields/formFields';
//recoil
import { useRecoilValue } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { useCountries } from '@api/queries/autocomplete/autocomplete';
import { CountryLocalizedType } from '@interfaces/autocomplete/autocomplete';

const getDateFilter = (columnFilters: MRT_ColumnFiltersState, key: string, position: number) => {
  const value = columnFilters.find(el => el.id === key);
  if (value?.value && Array.isArray(value.value)) return dayjs(value.value[position]).toISOString();
  return undefined;
};

let deleteMe = false;

export function useUsers() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );
  const [activeTab, setActiveTab] = useState(selectedTab(location.pathname));
  const [popup, setPopup] = useState<FormModalPropsType<any> | null>(null);

  const loggedUser = useRecoilValue(userAtom);
  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const onDelete = (data: User) => {
    if (loggedUser) deleteMe = loggedUser.id === data.id;

    deleteUser(data.id);
  };

  const handleReset = () => {
    setPopup(null);
    refetchAll();

    if (userId) navigate(`/users`);
    if (deleteMe) navigate('/login');
  };

  const onDeleteClick = (user: User) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.user`) }),
      subtitle:
        user.type === RoleEnum.BUSINESS
          ? t(`user.admin_delete_subtitle`)
          : t(`global.delete_subtitle`, { object: t(`delete.user`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        onDelete(user);
      }
    });
  };

  const [countrySearch, setCountrySearch] = useState<string>('');

  const { data: suggestedCountries, isLoading: isLoadingSuggestion } = useCountries(true, onError, {
    search: countrySearch ?? '',
    lang: i18n.language
  });

  const handleSearchChange = (event: React.SyntheticEvent, value: string) => {
    setCountrySearch(value);
  };

  const handleInputChange = (
    e: React.SyntheticEvent,
    data: CountryLocalizedType | null | string
  ) => {
    if (typeof data !== 'string' && !Array.isArray(data) && data?.id) {
      setColumnFilters(prev => {
        return [...prev, { id: 'countries', value: data.id }];
      });
    }
    if (Array.isArray(data)) {
      const val = data.map(el => (typeof el !== 'string' ? el.id : el));
      setColumnFilters(prev => {
        return [...prev, { id: 'countries', value: val }];
      });
    }
    if (data === null) {
      setColumnFilters([]);
    }
  };

  const { control, handleSubmit } = useForm<ExportUsersParamsType>({
    defaultValues: {
      username: false,
      countryNew: true,
      birthCountry: true,
      city: true,
      address: true,
      religion: false,
      phone: true,
      gender: true,
      dateOfBirth: true,
      employmentStatus: true,
      relationshipStatus: true,
      communityLife: false,
      jobSeeker: true,
      disability: true,
      birthPlace: true,
      createdAt: true,
      updatedAt: false,
      userCompanies: true,
      relation: 'celebrations'
    }
  });

  const onSuccessExport = (data: { data: BlobPart }) => {
    const downloadUrl = window.URL.createObjectURL(
      //@ts-ignore
      new File([data.data], 'w-media', { type: data.data.type })
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'w-media-users');
    link.click();
    link.remove();
    setPopup(null);
  };

  const { mutate: exportUsers } = useExportUsersData(onError, onSuccessExport);

  const onSubmitExport = (data: ExportUsersParamsType) => {
    exportUsers(data);
  };

  const handleDownloadData = () => {
    setPopup({
      title: t(`user.export.export_title`),
      subtitle: t(`user.export.export_subtitle`),
      open: true,
      control: control,
      fields: exportUsersOptionsFields(t, control),

      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.export'),
      variant: 'form',

      onSubmit: handleSubmit(onSubmitExport)
    });
  };

  const {
    data: users,
    isLoading,
    refetch: refetchAll
  } = useAllUsers(
    {
      page: pagination.pageIndex + 1,
      take: pagination.pageSize,
      search: columnFilters.find(el => el.id === 'firstName')?.value as string,
      lang: i18n.language,
      countries: columnFilters.find(el => el.id === 'countries')?.value as string[],
      roles: columnFilters.find(el => el.id === 'type')?.value as string[],
      genders: columnFilters.find(el => el.id === 'gender')?.value as string[],
      // @ts-ignore
      birthStartDate: columnFilters.find(el => el.id === 'dateOfBirth')?.value?.[0]
        ? getDateFilter(columnFilters, 'dateOfBirth', 0)
        : undefined,
      // @ts-ignore
      birthEndDate: columnFilters.find(el => el.id === 'dateOfBirth')?.value?.[1]
        ? getDateFilter(columnFilters, 'dateOfBirth', 1)
        : undefined,
      // @ts-ignore
      createdAtStartDate: columnFilters.find(el => el.id === 'createdAt')?.value?.[0]
        ? getDateFilter(columnFilters, 'createdAt', 0)
        : undefined,
      // @ts-ignore
      createdAtEndDate: columnFilters.find(el => el.id === 'createdAt')?.value?.[1]
        ? getDateFilter(columnFilters, 'createdAt', 1)
        : undefined
    },
    onError
  );

  const { data: user, isLoading: isLoadingSingle } = useSingleUser(userId, onError);
  const { data: genres } = useUsersGenres({ id: userId, lang: i18n.language }, onError);
  const { data: rubrics } = useUsersRubrics({ id: userId, lang: i18n.language }, onError);
  const { data: interests } = useUsersInterests({ id: userId, lang: i18n.language }, onError);
  const { data: industries } = useUsersIndustries({ id: userId, lang: i18n.language }, onError);
  const { data: celebrations } = useUsersCelebrations({ id: userId, lang: i18n.language }, onError);
  const { mutate: deleteUser, isLoading: isLoadingDelete } = useDeleteUser(handleReset, onError);

  useEffect(() => {
    if (location.pathname === '/users')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    users,
    t,
    onDelete,
    rowCount: users?.meta.itemCount,
    pagination,
    setPagination,
    isLoading,
    user,
    handleReset,
    isLoadingSingle,
    activeTab,
    handleChange,
    onDeleteClick,
    popup,
    setPopup,
    columnFilters,
    setColumnFilters,
    isLoadingDelete,
    genres,
    rubrics,
    interests,
    industries,
    celebrations,
    handleDownloadData,
    onError,
    suggestedCountries,
    isLoadingSuggestion,
    handleSearchChange,
    handleInputChange,
    countrySearch
  };
}
