import { useMemo } from 'react';
//components
import { NavLink, Navigate } from 'react-router-dom';
import FormModal from '@components/FormModal/FormModal';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TableLayout from '@components/TableLayout/TableLayout';
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import { Delete } from '@mui/icons-material';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { PodcastType } from '@interfaces/podcast/podcast';
//hooks
import { useTranslation } from 'react-i18next';
import { usePodcast } from '@hooks/usePodcast/usePodcast';
//helpers
import { youtubeDurationParse } from '@helpers/utility';
import { getPodcastKeysName } from '@helpers/profileFields/profileFields';

const SinglePodcastPage = () => {
  const { t } = useTranslation();
  const { singlePodcast, popup, errors, isLoadingSingle, onDeletePodcastClick } = usePodcast();

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: keyof PodcastType }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          if (cell.getValue<string>() === 'url') {
            return (
              <NavLink to={singlePodcast?.url ?? ''} target="_blank" rel="noopener noreferrer">
                {singlePodcast?.url}
              </NavLink>
            );
          }
          if (cell.getValue<string>() === 'duration') {
            return <Typography>{youtubeDurationParse(singlePodcast?.duration ?? '')}</Typography>;
          }
          return (
            <Typography>
              {singlePodcast?.[cell.getValue<string>() as keyof PodcastType]?.toString()}
            </Typography>
          );
        }
      }
    ],
    [singlePodcast]
  );

  if (isLoadingSingle) {
    return <Loading />;
  }
  if (!singlePodcast) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          errors={errors}
        />
      )}

      <ProfileInfoCard
        title={singlePodcast?.title ?? 'title'}
        subtitle={t('global.podcast')}
        bannerImage={singlePodcast?.thumbnailUrl}
        profileImage={undefined}
        buttons={
          <>
            <Button variant="contained" color="error" size="small" onClick={onDeletePodcastClick}>
              <Delete fontSize="small" />
              {t('global.delete')}
            </Button>
          </>
        }
      />

      <Box className="container">
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          gap={2}
          mb={2}
        >
          <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
            {t('global.basic_info')}
          </Typography>
          <Typography variant="body2">{singlePodcast?.description}</Typography>
        </Box>
        <TableLayout data={getPodcastKeysName(t)} columns={columns} rowCount={0} />
      </Box>
    </Box>
  );
};

export default SinglePodcastPage;
