import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
//components
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import AutocompleteInputField from '@components/Autocomplete/AutocomleteInputField';
import Loading from '@components/Loading/Loading';
//types
import { CreateCompanyType } from '@interfaces/company/company';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { User } from '@interfaces/users/users';
//hooks
import { useCreateCompany } from '@api/mutations/company/company';
import { useAllIndustries } from '@api/queries/interests/interests';
import { useTranslation } from 'react-i18next';
import { useUser } from '@api/queries/users/users';
//recoil
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { userAtom } from '@atoms/userAtom';
//helpers
import { getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import CountriesAutocompleteField from '@components/CountriesAutocompleteField/CountriesAutocompleteField';
import { useUserMutation } from '@api/mutations/user/user';

const RegisterCompanyPage: React.FC = (): JSX.Element => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue
  } = useForm<CreateCompanyType>();

  const [user, setUser] = useRecoilState(userAtom);

  const { t, i18n } = useTranslation();

  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const navigate = useNavigate();

  const onCreateCompanyError = (err: ErrorType<CreateCompanyType>): void => {
    setError('root', {
      type: 'custom',
      message: err.message
    });

    if (err.errors) {
      for (const key in err.errors) {
        setError(key as keyof CreateCompanyType, {
          type: 'custom',
          message: err.errors[key as keyof CreateCompanyType]?.join(', ')
        });
      }
    } else if (err.statusCode === 409) {
      setError('root', {
        type: 'custom',
        message: err.error
      });
    }
  };

  const onError = (err: ErrorsResponseType<{}>): void => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { mutate: createCompany, isLoading, isSuccess } = useCreateCompany(onCreateCompanyError);
  const { data: industries } = useAllIndustries(onError);

  const onSubmit = (data: CreateCompanyType) => {
    createCompany({
      ...data,
      title: data.name
    });
  };

  const onSuccessUserResponse = (data: User) => {
    setUser(data);
    navigate('/profile');
  };
  const onErrorUserResponse = (err: ErrorType<{}>) => {};

  const { mutate: refetchUser, isSuccess: isSuccessUser } = useUserMutation(
    onSuccessUserResponse,
    onErrorUserResponse
  );

  useEffect(() => {
    if (isSuccess) {
      refetchUser('');
    }
  }, [isSuccess]);

  if (isLoading) {
    return <Loading />;
  }
  if (isSuccessUser && user && user.userCompanies && user.userCompanies.length > 0) {
    return <Navigate to={'/profile'} replace />;
  }
  return (
    <Box
      px={{ xs: 2, md: 5 }}
      mt={5}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      minHeight={'100%'}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            color={'primary'}
            variant="h3"
            fontWeight={'bold'}
            my={{ xs: 0, sm: 3 }}
            sx={{ fontSize: { xs: '2rem', md: '3rem' } }}
          >
            {t('login.register.company.title')}
          </Typography>
        </Grid>
        {errors.root && (
          <Typography color={'error'} mx={2}>
            {errors.root.message}
          </Typography>
        )}
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                label={t('login.register.company.name')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                label={t('login.register.company.email')}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error>
            <PhoneInput
              country={'rs'}
              specialLabel={t('login.register.company.phone')}
              regions={'europe'}
              inputClass="phone-input"
              value={watch('phone')}
              onChange={ev => {
                setValue('phone', ev);
                if (errors.phone) {
                  delete errors.phone;
                }
              }}
            />
            <FormHelperText color={'error'}>{errors.phone?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="newCountryAlpha2"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <CountriesAutocompleteField
                setValue={setValue}
                invalid={invalid}
                error={error?.message}
                label={t('global.country')}
                type={AutpocompleteTypeEnum.COUNTRY}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="industries"
            control={control}
            defaultValue={[]}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth error={invalid}>
                <InputLabel>{t('global.industry')}</InputLabel>
                <Select label={t('global.industry')} {...field} multiple>
                  {industries?.map(ind => (
                    <MenuItem key={ind.id} value={ind.id}>
                      {getTranslationValue(
                        ind.translations,
                        'name',
                        i18n.language as SupportedLanguagesEnum
                      )}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color={'error'}>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth variant="contained" type="submit" size="large" sx={{ py: '.5rem' }}>
            {t('login.register.finish')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography color={'error'}>{t('login.register.message')}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterCompanyPage;
