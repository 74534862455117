import {
  NotificationsType,
  RegisterNotificationTokenRequestType,
  RegisterNotificationTokenResponseType
} from '@interfaces/notifications/notifications';
import { ErrorsResponseType } from '@interfaces/response';
import { registerNotificationToken, seenNotfication } from '@services/notifications/notification';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useRegisterNotificationToken = (
  onSuccess: (res: RegisterNotificationTokenResponseType) => void,
  onError: (err: Error) => void
): UseMutationResult<
  { data: RegisterNotificationTokenResponseType },
  Error,
  RegisterNotificationTokenRequestType,
  (data: RegisterNotificationTokenResponseType) => void
> =>
  useMutation({
    mutationFn: registerNotificationToken,
    onError: err => onError(err),
    onSuccess: res => onSuccess(res.data)
  });

export const useSeenNotification = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: (res: NotificationsType) => void
): UseMutationResult<
  { data: NotificationsType },
  ErrorsResponseType<{}>,
  string,
  (data: NotificationsType) => void
> =>
  useMutation({
    mutationFn: seenNotfication,
    onError: err => onError(err),
    onSuccess: res => onSuccess && onSuccess(res.data)
  });
