import {
  AllCountriesResponseType,
  AllPredictionsResponseType,
  GeoLocationType
} from '@interfaces/autocomplete/autocomplete';
import axiosInstance from '@services/config';

export const getSuggestedCountries = (params?: {
  input: string;
  language: string;
}): Promise<{ data: AllPredictionsResponseType }> =>
  axiosInstance.get('/v1/autocomplete/country', { params: { ...params } });

export const getSuggestedCities = (params?: {
  input: string;
  language: string;
  countryCode?: string;
}): Promise<{ data: AllPredictionsResponseType }> =>
  axiosInstance.get('/v1/autocomplete/city', { params: { ...params } });

export const getSuggestedAddresses = (params?: {
  input: string;
  language: string;
  lat?: number;
  lng?: number;
}): Promise<{ data: AllPredictionsResponseType }> =>
  axiosInstance.get('/v1/autocomplete/address', { params: { ...params } });

export const getGeoInfo = (placeId: string, language: string): Promise<{ data: GeoLocationType }> =>
  axiosInstance.get('/v1/autocomplete/geoinfo/' + placeId, { params: { language } });

export const getAllCountries = (
  search?: string,
  lang?: string
): Promise<{ data: AllCountriesResponseType }> =>
  axiosInstance.get('/v1/countries/localized', { params: { lang, search } });
