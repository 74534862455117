//type
import { RegisterRequestType, ResendVerifyType } from '@interfaces/login';
import {
  ChangePasswordRequestType,
  CreateUserRequestType,
  UpdateUserRequestType,
  UserInfo,
  User,
  ExportUsersParamsType
} from '@interfaces/users/users';
//api
import {
  changePassword,
  createRegularUser,
  register,
  resendVerify,
  updateUser,
  me,
  deleteUsersImage,
  uploadUserImage,
  exportUsersData
} from '@services/users/users';
import { deleteUser } from '@services/users/users';
//react query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';

export const useRegisterUser = (
  onSuccess: (res: User) => void,
  onError: (err: ErrorType<RegisterRequestType>) => void
): UseMutationResult<
  { data: User },
  ErrorsResponseType<RegisterRequestType>,
  RegisterRequestType,
  (data: User) => void
> =>
  useMutation({
    mutationFn: register,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data)
  });

export const useUserMutation = (
  onSuccess: (res: User) => void,
  onError: (err: ErrorType<{}>) => void
): UseMutationResult<
  { data: UserInfo },
  ErrorsResponseType<{}>,
  string,
  (data: UserInfo) => void
> =>
  useMutation({
    mutationFn: me,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data.user),
    retry: false
  });

export const useResendVerify = (
  onSuccess: (res: any) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  { data: any },
  ErrorsResponseType<{}>,
  ResendVerifyType,
  (data: any) => void
> =>
  useMutation({
    mutationFn: resendVerify,
    onError: err => onError(err),
    onSuccess: res => onSuccess(res)
  });

export const useUpdateUser = (
  onSuccess: (res: User) => void,
  onError: (err: ErrorType<UpdateUserRequestType>) => void
): UseMutationResult<
  { data: User },
  ErrorsResponseType<UpdateUserRequestType>,
  UpdateUserRequestType,
  (data: User) => void
> =>
  useMutation({
    mutationFn: updateUser,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data)
  });

export const useChangePassword = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<{}, ErrorsResponseType<{}>, ChangePasswordRequestType, () => void> =>
  useMutation({
    mutationFn: changePassword,
    onError: err => onError(err),
    onSuccess: () => onSuccess()
  });

export const useDeleteUser = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteUser,
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });

export const useCreateUser = (
  onSuccess: (res: User) => void,
  onError: (err: ErrorType<CreateUserRequestType>) => void
): UseMutationResult<
  { data: User },
  ErrorsResponseType<CreateUserRequestType>,
  CreateUserRequestType,
  (data: User) => void
> =>
  useMutation({
    mutationFn: createRegularUser,
    onError: err => onError(err.response.data),
    onSuccess: res => onSuccess(res.data)
  });

export const useDeleteUsersImage = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { id: string; type: 'image' | 'coverImage' }, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: req => deleteUsersImage(req.id, req.type),
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });

export const useUploadUsersImage = (
  onSuccess: (data: { image: string }) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { id: string; data: FormData; type: 'image' | 'coverImage' }, // request
  (data: { image: string }) => void // on success
> =>
  useMutation({
    mutationFn: req => uploadUserImage(req.id, req.data, req.type),
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useExportUsersData = (
  // params: ExportUsersParamsType,
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess: (data: { data: BlobPart }) => void
): UseMutationResult<
  { data: BlobPart },
  ErrorsResponseType<{}>,
  ExportUsersParamsType,
  (data: { data: BlobPart }) => void
> =>
  useMutation({
    mutationFn: (params: ExportUsersParamsType) => exportUsersData(params),
    onError: err => onError(err),
    onSuccess: res => onSuccess(res)
  });
