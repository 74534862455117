import {
  AllFAQsResponseType,
  CreateFAQRequestType,
  SingleFAQResponseType,
  UpdateFAQRequestType
} from '@interfaces/faq/faq';
import axiosInstance from '@services/config';

export const getAllFAQ = (): Promise<AllFAQsResponseType> =>
  axiosInstance.get('v2/support-ticket', {
    params: { order: 'DESC', lang: localStorage.getItem('i18nextLng') }
  });

export const createFAQ = (data: CreateFAQRequestType): Promise<SingleFAQResponseType> =>
  axiosInstance.post('v2/support-ticket', data);

export const updateFAQ = (data: UpdateFAQRequestType): Promise<SingleFAQResponseType> =>
  axiosInstance.patch('v2/support-ticket/' + data.id, data.data);

export const deleteFAQ = (id: string): Promise<{}> =>
  axiosInstance.delete('v2/support-ticket/' + id);
