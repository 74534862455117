//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePodcastChannels } from '@hooks/usePodcast/usePodcastChannels';
//components
import Loading from '@components/Loading/Loading';
import { Box } from '@mui/material';
import PageLayout from '@pages/Page/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import { NavLink } from 'react-router-dom';
//helper
import { podcastChannelFields } from '@helpers/formFields/formFields';
//type
import { PodcastChannelType } from '@interfaces/podcast/podcast';

const PodcastChannelPage = () => {
  const {
    deleteChannel,
    channels,
    rowCount,
    pagination,
    setPagination,
    control,
    handleSubmit,
    setValue,
    setError,
    errors,
    isSuccess,
    reset,
    onSubmit,
    isLoadingAll,
    isSubmitting
  } = usePodcastChannels();

  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<PodcastChannelType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        Cell: ({ row }) => <NavLink to={row.original.id}> {row.original.title} </NavLink>
      },
      {
        accessorKey: 'url',
        header: t('global.link'),
        Cell: ({ row }) => (
          <NavLink to={row.original.url} target="_blank" rel="noopener noreferrer">
            {row.original.url}
          </NavLink>
        )
      },
      {
        accessorKey: 'type',
        header: t('global.type')
      }
    ],
    [channels, t]
  );

  const handleDelete = (data: PodcastChannelType) => {
    deleteChannel(data.id);
  };

  return (
    <Box className="container">
      <PageLayout
        columns={columns}
        data={channels}
        fields={podcastChannelFields(t)}
        handleCreate={onSubmit}
        handleDelete={handleDelete}
        title={t('podcast.channel.page_title')}
        subtitle={t('podcast.channel.page_subtitle')}
        addBtnText={t('podcast.channel.add_podcast')}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={reset}
        translationKey="podcast.channel"
        setError={setError}
        isSubmitSuccessfull={isSuccess}
        errors={errors}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={rowCount}
        isLoading={isLoadingAll}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

export default PodcastChannelPage;
