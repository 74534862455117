import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
//api
import {
  useCreateRubrics,
  useDeleteRubricImage,
  useDeleteRubrics,
  useUpdateRubrics
} from '@api/mutations/interests/interests';
import { useAllRubrics, useSingleRubric } from '@api/queries/interests/interests';
import { useAllNews } from '@api/queries/news/news';
//helpers
import { checkForEmptyTranslation } from '@helpers/utility';
import { formHelper } from '@helpers/formDataHelper';
//type
import { CreateInterestsType, InterestsType } from '@interfaces/interests/interests';
import { useForm } from 'react-hook-form';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { MRT_PaginationState } from 'material-react-table';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';

export function useRubrics() {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    getValues
  } = useForm<CreateInterestsType>();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const [newsPagination, setNewsPagination] = useState<MRT_PaginationState>({
    pageSize: 10,
    pageIndex: 0
  });

  const { t } = useTranslation();

  const { rubricId } = useParams();

  const onSuccessDelete = () => {
    refetchRubrics();
    reset({
      translations: {
        sr: { name: '' },
        en: { name: '' },
        de: { name: '' },
        fr: { name: '' }
      },
      file: '',
      id: undefined
    });
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onSuccessCreate = (data: InterestsType) => {
    const val = getValues();
    if (val.file === null) {
      deleteRubricsImage(data.id);
    } else {
      refetchRubrics();
      reset({
        translations: {
          sr: { name: '' },
          en: { name: '' },
          de: { name: '' },
          fr: { name: '' }
        },
        file: '',
        id: undefined
      });
    }
    setInfoPopup({
      title: val.id ? t('global.success_update') : t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onErrorCreate = (err: ErrorType<InterestsType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });

    if (err.errors?.translations) {
      setError('root', {
        type: 'custom',
        message: err.errors.translations.join(', ')
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const {
    data: rubrics,
    refetch: refetchRubrics,
    isLoading: isLoadingRubrics
  } = useAllRubrics(onError);

  const {
    mutate: createRubrics,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreateRubrics(onSuccessCreate, onErrorCreate);
  const {
    mutate: updateRubrics,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdateRubrics(onSuccessCreate, onErrorCreate);
  const { mutate: deleteRubrics, isLoading: isLoadingDelete } = useDeleteRubrics(
    onSuccessDelete,
    onError
  );

  const { data: singleRubric, isLoading: isLoadingSingle } = useSingleRubric(rubricId, onError);

  const { data: news } = useAllNews(
    onError,
    { take: newsPagination.pageSize, page: newsPagination.pageIndex + 1, rubricId: rubricId },
    !rubricId
  );
  const { mutate: deleteRubricsImage } = useDeleteRubricImage(onSuccessDelete, onError);

  const onSubmitRubrics = (data: CreateInterestsType) => {
    if (checkForEmptyTranslation(data.translations)) {
      setError('root', {
        type: 'custom',
        message: t('global.translation_error_message')
      });
      return;
    }

    if (data.id) {
      if (data.file === null || typeof data.file === 'string') {
        // deleteRubricsImage(data.id);
        updateRubrics({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations })
        });
      } else {
        updateRubrics({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations, file: data.file })
        });
      }
    } else {
      delete data.id;
      createRubrics(formHelper.getBody({ translations: data.translations, file: data.file }));
    }
  };

  return {
    rubrics: rubrics ? rubrics : [],
    refetchRubrics,
    isLoadingRubrics,
    onSubmitRubrics,
    deleteRubrics,
    isSuccessRubrics: isCreateSuccess || isUpdateSuccess,
    singleRubric,
    isLoadingSingle,
    control,
    setValue,
    handleSubmit,
    setError,
    reset,
    errors,
    news: news ? news.data : [],
    newsPagination,
    setNewsPagination,
    rowCountNews: news ? news.meta.itemCount : 0,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
