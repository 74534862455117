import React, { useEffect } from 'react';
//types
import { AxiosError } from 'axios';
//hooks
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import {
  useSuggestedAddresses,
  useSuggestedCities,
  useSuggestedCountries
} from '@api/queries/autocomplete/autocomplete';
import { useGeoInfo } from '@api/mutations/autocomplete';
import { Path, PathValue, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PredictionType } from '@interfaces/autocomplete/autocomplete';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';

export function useAutocomplete<T extends {}>(
  setValue: UseFormSetValue<T>,
  type: AutpocompleteTypeEnum
) {
  const [countrySearch, setCountrySearch] = React.useState<string>('');

  const { i18n } = useTranslation();

  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: AxiosError): void => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { data: suggestedCountries, isLoading: isLoadingSuggestedCountries } =
    useSuggestedCountries(type === 'country', onError, {
      input: countrySearch ?? '',
      language: i18n.language === 'sr' ? 'sr-Latn' : i18n.language
    });

  const { data: suggestedCities, isLoading: isLoadingSuggestedCities } = useSuggestedCities(
    type === 'city' || type === 'location',
    onError,
    {
      input: countrySearch ?? '',
      language: i18n.language === 'sr' ? 'sr-Latn' : i18n.language
    }
  );

  const { data: suggestedAddresses, isLoading: isLoadingSuggestedAddresses } =
    useSuggestedAddresses(type === 'address' || type === 'office', onError, {
      input: countrySearch ?? '',
      language: i18n.language === 'sr' ? 'sr-Latn' : i18n.language
    });

  const {
    data: geoLocation,
    isLoading: isLoadingGeoLocation,
    isSuccess: isSuccessGeoLocation,
    mutate: getGeoInfo
  } = useGeoInfo(onError);

  const handleSearchChange = (event: React.SyntheticEvent, value: string) => {
    setCountrySearch(value);

    if (!value) {
      if (type === 'location') {
        setValue('location' as Path<T>, '' as PathValue<T, Path<T>>);
      } else if (type === 'office') {
        setValue('office' as Path<T>, '' as PathValue<T, Path<T>>);
      } else {
        setValue('city' as Path<T>, '' as PathValue<T, Path<T>>);
        setValue('country' as Path<T>, '' as PathValue<T, Path<T>>);
        setValue('newCountryAlpha2' as Path<T>, null as PathValue<T, Path<T>>);
      }

      setValue('address' as Path<T>, '' as PathValue<T, Path<T>>);
    }
  };

  const handleInputChange = (e: React.SyntheticEvent, data: PredictionType | null | string) => {
    if (typeof data !== 'string' && data?.place_id) {
      getGeoInfo({
        placeId: data.place_id,
        language: i18n.language === 'sr' ? 'sr-Latn' : i18n.language
      });
    }
  };

  const getSuggestedArray = () => {
    switch (type) {
      case 'country':
        return suggestedCountries;
      case 'city':
        return suggestedCities;
      case 'location':
        return suggestedCities;
      case 'address':
        return suggestedAddresses;
      case 'office':
        return suggestedAddresses;
    }
  };

  const getLoader = (): boolean => {
    switch (type) {
      case 'country':
        return isLoadingSuggestedCountries;
      case 'city':
        return isLoadingSuggestedCities;
      case 'location':
        return isLoadingSuggestedCities;
      case 'address':
        return isLoadingSuggestedAddresses;
      case 'office':
        return isLoadingSuggestedAddresses;
      default:
        return isLoadingGeoLocation;
    }
  };

  useEffect(() => {
    if (isSuccessGeoLocation) {
      geoLocation.data.results.forEach(res => {
        if (type === 'location') {
          setValue('location' as Path<T>, res.formatted_address as PathValue<T, Path<T>>);
        } else if (type === 'office') {
          setValue('office' as Path<T>, res.formatted_address as PathValue<T, Path<T>>);
        } else {
          let street = '';
          let street_number = '';
          res.address_components.forEach(el => {
            if (el.types.includes('street_number')) {
              street_number = ' ' + el.long_name;
            }
            if (el.types.includes('route')) {
              street = el.long_name;
            } else if (el.types.includes('locality') || el.types.includes('postal_town')) {
              setValue('city' as Path<T>, el.long_name as PathValue<T, Path<T>>);
            } else if (el.types.includes('country')) {
              setValue('country' as Path<T>, el.long_name as PathValue<T, Path<T>>);
              setValue('newCountryAlpha2' as Path<T>, el.short_name as PathValue<T, Path<T>>);
            }
          });
          const address = street + street_number;
          setValue('address' as Path<T>, address as PathValue<T, Path<T>>);
        }
      });
    }
  }, [isSuccessGeoLocation]);

  return {
    handleSearchChange,
    countrySearch,
    handleInputChange,
    suggested: getSuggestedArray(),
    isLoadingSuggestion: getLoader(),
    setCountrySearch
  };
}
