//hooks
import { useEffect, useMemo, useState } from 'react';
import { useJobs } from '@hooks/useJobs/useJobs';
import { useTranslation } from 'react-i18next';
//components
import { NavLink } from 'react-router-dom';
import FormModal from '@components/FormModal/FormModal';
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
//helpers
import dayjs from 'dayjs';
import { getTypographyColor } from '@helpers/utility';
//type
import { JobType } from '@interfaces/job/job';
import { MRT_ColumnDef } from 'material-react-table';
import { JobStatusEnum, JobTypeEnum } from '@enum/companyEnum';

const JobsPage = () => {
  const { t } = useTranslation();
  const {
    popup,
    allJobs,
    onDeleteClick,
    columnFilters,
    setColumnFilters,
    pagination,
    setPagination,
    rowCount,
    isLoadingAll,
    isLoadingDelete
  } = useJobs(false);

  const columns = useMemo<MRT_ColumnDef<JobType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        Cell: ({ cell, row }) => <NavLink to={row.original.id}>{cell.getValue<string>()}</NavLink>
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        filterVariant: 'select',
        filterSelectOptions: Object.values(JobStatusEnum).map(s => {
          return {
            text: t(`status.${s}`),
            value: s
          };
        }),
        Cell: ({ cell }) => (
          <Typography color={getTypographyColor(cell.getValue<string>())}>
            {t(`status.${cell.getValue<string>()}`)}
          </Typography>
        )
      },

      {
        accessorKey: 'createdAt',
        header: t('global.created_at'),
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY'),
        enableColumnFilter: false
      },

      {
        accessorKey: 'expiredAt',
        header: t('job.expired_at'),
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'company.name',
        header: t('global.author'),
        enableColumnFilter: false
      }
    ],
    [t]
  );

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingDelete}
        />
      )}
      <Box my={2}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('job.page_title')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('job.page_subtitle')}
        </Typography>
      </Box>

      <TableLayout
        columns={columns}
        data={allJobs}
        handleDelete={row => {
          onDeleteClick(row);
        }}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        rowCount={rowCount}
        isLoading={isLoadingAll}
      />
    </Box>
  );
};

export default JobsPage;
