//hooks
import { useCompanyProfile } from '@hooks/useCompanyProfile/useCompanyProfile';
import { useEffect, useMemo } from 'react';
//components
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Button, Stack, Switch, Typography } from '@mui/material';
//type
import { CompanyType } from '@interfaces/company/company';
import { MRT_ColumnDef } from 'material-react-table';
//helpers
import dayjs from 'dayjs';
import { getCompanyProfileKeys } from '@helpers/profileFields/profileFields';
//icons
import { DeleteOutline } from '@mui/icons-material';
import { getTranslationValue } from '@helpers/utility';
import i18next from 'i18next';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { useRecoilValue } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { RoleEnum } from '@enum/roleEnum';
import Loading from '@components/Loading/Loading';

const CompanyInfoPage = ({ single }: { single?: boolean }) => {
  const {
    t,
    company,
    onDeleteClick,
    updateCompanyVisibility,
    isLoadingUpdateVisibility,
    isLoading
  } = useCompanyProfile();
  const user = useRecoilValue(userAtom);

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: string }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const key = cell.getValue<string>() as keyof CompanyType;

          if (key === 'createdAt')
            return <Typography>{dayjs(company?.[key]).format('DD.MM.YYYY.')}</Typography>;
          if (key === 'founded')
            return <Typography>{dayjs(company?.[key]).format('DD.MM.YYYY.')}</Typography>;

          if (key === 'website' && company?.website)
            return (
              <a href={company?.website} target="_blank" rel="noopener noreferrer">
                {company?.website}
              </a>
            );
          if (
            cell.getValue<string>() === 'socialNetworks.instagram' &&
            company?.socialNetworks?.instagram
          )
            return (
              <a
                href={company?.socialNetworks?.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company?.socialNetworks?.instagram}
              </a>
            );
          if (
            cell.getValue<string>() === 'socialNetworks.linkedin' &&
            company?.socialNetworks?.linkedin
          )
            return (
              <a href={company?.socialNetworks?.linkedin} target="_blank" rel="noopener noreferrer">
                {company?.socialNetworks?.linkedin}
              </a>
            );
          if (
            cell.getValue<string>() === 'socialNetworks.youtube' &&
            company?.socialNetworks?.youtube
          )
            return (
              <a href={company?.socialNetworks?.youtube} target="_blank" rel="noopener noreferrer">
                {company?.socialNetworks?.youtube}
              </a>
            );

          if (key === 'industries')
            return <Typography>{company?.industries.map(ind => ind.name).join(', ')}</Typography>;

          if (key === 'countryNew')
            return (
              <Typography>
                {company?.countryNew?.translations
                  ? getTranslationValue(
                      company.countryNew.translations,
                      'name',
                      i18next.language as SupportedLanguagesEnum
                    )
                  : ''}
              </Typography>
            );

          if (key === 'visible') {
            if (isLoadingUpdateVisibility) return <Loading />;
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t('global.false')}</Typography>
                <Switch
                  checked={company?.visible}
                  onChange={(e, value) => {
                    if (company) {
                      updateCompanyVisibility({ id: company.id, visible: value });
                    }
                  }}
                  disabled={user?.type !== RoleEnum.ADMIN}
                />
                <Typography>{t('global.true')}</Typography>
              </Stack>
            );
          }

          return <Typography>{company?.[key] ? company?.[key]?.toString() : ''}</Typography>;
        }
      }
    ],
    [company, t]
  );

  return (
    <Box>
      <TableLayout data={getCompanyProfileKeys(t)} columns={columns} rowCount={0} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        {single ? (
          <Button variant="contained" size="small" disabled>
            {t('user.verify_account')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => onDeleteClick()}
            disabled
          >
            <DeleteOutline /> {t('user.delete_account')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanyInfoPage;
