import { NavLink, Navigate } from 'react-router-dom';
//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIndustries } from '@hooks/useIndustries/useIndustries';
//components
import { Box, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
import TableLayout from '@components/TableLayout/TableLayout';
//helpers
import { dayjs, getTranslationValue, getTypographyColor } from '@helpers/utility';
import { primaryColor } from '@src/theme';
//type
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { MRT_ColumnDef } from 'material-react-table';
import { CompanyType } from '@interfaces/company/company';
import { JobType } from '@interfaces/job/job';
import { JobStatusEnum } from '@enum/companyEnum';

const SingleIndustryPage = () => {
  const {
    singleIndustry,
    isLoadingSingle,
    companies,
    companyPagination,
    setCompanyPagination,
    jobPagination,
    setJobPagination,
    jobs,
    rowCountCompanies,
    rowCountJobs
  } = useIndustries();

  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<CompanyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.name'),
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue<string>()}>
            <NavLink to={`/companies/single/${row.original.id}`}>
              <Typography variant="body2">{cell.getValue<string>()}</Typography>
            </NavLink>
          </Tooltip>
        ),
        enableColumnFilter: false
      },
      {
        accessorKey: 'country',
        header: t('global.country')
      }
    ],
    [t, companies]
  );

  const jobColumns = useMemo<MRT_ColumnDef<JobType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.name'),
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue<string>()}>
            <NavLink to={`/jobs/${row.original.id}`}>
              <Typography variant="body2">{cell.getValue<string>()}</Typography>
            </NavLink>
          </Tooltip>
        ),
        enableColumnFilter: false
      },
      {
        accessorKey: 'company.name',
        header: t('global.author'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        filterVariant: 'select',
        filterSelectOptions: Object.values(JobStatusEnum),
        Cell: ({ cell }) => (
          <Typography color={getTypographyColor(cell.getValue<string>())}>
            {cell.getValue<string>()}
          </Typography>
        )
      },
      {
        accessorKey: 'location',
        header: t('global.location')
      }
    ],
    [t, jobs]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleIndustry) return <Navigate to={'/error-page'} replace />;
  return (
    <Box className="container">
      <CardContent sx={{ display: 'flex', columnGap: 2 }}>
        <CardMedia
          component="img"
          sx={{
            width: 120,
            height: 120,
            border: '2px solid ' + primaryColor,
            bgcolor: '#bdbdbd'
          }}
          image={
            singleIndustry?.imageUrl ? singleIndustry?.imageUrl + '?' + dayjs().toISOString() : ''
          }
          alt=""
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Typography component="div" variant="h5">
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {t('global.industry')}
            </Typography>
            {singleIndustry &&
              getTranslationValue(
                singleIndustry.translations,
                'name',
                i18n.language as SupportedLanguagesEnum
              )}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h5" color="primary" fontWeight={'bold'}>
          {t('interests.industries.companies_with_industry')}
        </Typography>
        <TableLayout
          data={companies}
          columns={columns}
          pagination={companyPagination}
          setPagination={setCompanyPagination}
          rowCount={rowCountCompanies}
        />
      </CardContent>

      <CardContent>
        <Typography variant="h5" color="primary" fontWeight={'bold'}>
          {t('interests.industries.jobs_with_industry')}
        </Typography>
        <TableLayout
          data={jobs}
          columns={jobColumns}
          pagination={jobPagination}
          setPagination={setJobPagination}
          rowCount={rowCountJobs}
        />
      </CardContent>
    </Box>
  );
};

export default SingleIndustryPage;
