// store
import { atom } from 'recoil';
import { hasToken } from '@helpers/utility';

const authAtom = atom<boolean>({
  key: 'authAtom',
  default: !!hasToken()
});

export { authAtom };
