//hooks
import { useTranslation } from 'react-i18next';
//components
import { Box, Card, CardContent, CardMedia, Typography, Chip } from '@mui/material';
import { primaryColor, secondaryColor } from '@src/theme';

type SliderItemCardPropsType = {
  heroImage: string | undefined | null;
  title: string | undefined | null;
  type: string | undefined | null;
  shortDescription: string | undefined | null;
  tags: string[] | undefined;
};

const SliderItemCard = ({
  heroImage,
  title,
  type,
  shortDescription,
  tags
}: SliderItemCardPropsType) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: '100%',
        position: 'relative',
        bgcolor: '#29294F',
        minWidth: '15rem',
        borderRadius: '0px'
      }}
    >
      <CardMedia
        sx={{ height: 270, opacity: 0.4, width: '100%' }}
        component={'img'}
        image={heroImage ? heroImage : ''}
        title={title?.toString()}
      />
      <CardContent sx={{ position: 'absolute', top: 0, left: 0, color: 'white', width: '95%' }}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Chip
            size="small"
            label={type ? type : t('slider.form.type')}
            sx={{ color: primaryColor, bgcolor: secondaryColor }}
          />
          <Chip color="error" label={t('slider.form.overview')} size="small" />
        </Box>
        <Typography gutterBottom variant="h5" component="div" fontWeight={'bold'}>
          {title ? title : t('slider.form.title')}
        </Typography>
        <Typography variant="body2">
          {shortDescription ? shortDescription : t('slider.form.description_placeholder')}
        </Typography>
        <Typography variant="body2">{tags?.map(t => `#${t} `)}</Typography>
      </CardContent>
    </Card>
  );
};

export default SliderItemCard;
