//components
import { Box, Typography } from '@mui/material';
import PageLayout from '@pages/Page/PageLayout';
import Loading from '@components/Loading/Loading';
import { NavLink } from 'react-router-dom';
//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRubrics } from '@hooks/useRubrics/useRubrics';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { InterestsType } from '@interfaces/interests/interests';
//helpers
import { interestsFields } from '@helpers/formFields/formFields';
import { dayjs, getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const RubricsPage = () => {
  const { t, i18n } = useTranslation();

  const {
    rubrics,
    onSubmitRubrics,
    deleteRubrics,
    isLoadingRubrics,
    isSuccessRubrics,
    control,
    setValue,
    handleSubmit,
    setError,
    reset,
    errors,
    isSubmitting
  } = useRubrics();

  const columns = useMemo<MRT_ColumnDef<InterestsType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <NavLink to={row.original.id}>
            {getTranslationValue(
              row.original.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            )}
          </NavLink>
        )
      },
      {
        accessorKey: 'imageURL',
        header: '',
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <img
            src={row.original.imageUrl ? row.original.imageUrl + '?' + dayjs().toISOString() : ''}
            height={50}
          />
        )
      }
    ],
    [rubrics, t]
  );

  return (
    <Box display={'flex'} flexDirection={'column'} className="container">
      <Typography variant="h5" fontWeight={'bold'}>
        {t('interests.rubrics.title')}
      </Typography>

      <PageLayout
        title=""
        subtitle={t('interests.rubrics.subtitle')}
        addBtnText={t('interests.rubrics.add_btn')}
        translationKey="interests.rubrics"
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={() => {
          reset({
            translations: {
              sr: { name: '' },
              en: { name: '' },
              de: { name: '' },
              fr: { name: '' }
            },
            file: '',
            id: undefined
          });
        }}
        columns={columns}
        fields={interestsFields(t)}
        data={rubrics}
        handleCreate={onSubmitRubrics}
        handleDelete={data => {
          deleteRubrics(data.id);
        }}
        handleEdit={data => {
          reset({ ...data, file: data.imageUrl });
        }}
        setError={setError}
        isSubmitSuccessfull={isSuccessRubrics}
        errors={errors}
        isLoading={isLoadingRubrics}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};
export default RubricsPage;
