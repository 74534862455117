import { Navigate } from 'react-router-dom';
//hook
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSliderItem } from '@hooks/useSliders/useSliderItem';
//mui
import { Box, Button, ButtonGroup, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormModal from '@components/FormModal/FormModal';
//components
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
//type
import { SliderItemPreviewType } from '@interfaces/sliders/sliders';
import { MRT_ColumnDef } from 'material-react-table';
//icon
import DeleteIcon from '@mui/icons-material/Delete';
import SliderItemCard from '@components/SliderItemCard/SliderItemCard';

const SingleSliderPage = () => {
  const {
    items,
    popup,
    onAddClick,
    onDeleteClick,
    fields,
    selected,
    setIndex,
    index,
    singleSlider,
    isLoadingSlider,
    isSubmitting
  } = useSliderItem();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<SliderItemPreviewType>[]>(
    () => [
      {
        accessorKey: 'type',
        header: t('global.type'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'title',
        header: t('global.title'),
        enableColumnFilter: false
      }
    ],
    [t, singleSlider?.sliderItems]
  );

  if (isLoadingSlider) return <Loading />;
  if (!singleSlider) return <Navigate to={'/error-page'} />;
  return (
    <Box className="container" display={'flex'} flexDirection={'column'} gap={1}>
      {popup && (
        <FormModal
          {...popup}
          fields={popup.variant === 'form' ? fields : []}
          isLoading={isSubmitting}
        />
      )}

      <Typography variant="h4" fontWeight={'bold'}>
        {singleSlider?.type}
      </Typography>

      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant="contained" onClick={onAddClick}>
          {t('slider.add_slider_item')}
        </Button>
      </Box>

      <Box>
        {selected && (
          <Card sx={{ borderRadius: '4px' }}>
            <SliderItemCard
              heroImage={selected.heroImage}
              title={selected.title}
              shortDescription={selected.shortDescription}
              type={selected.type}
              tags={selected.tags}
            />

            <CardActions sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  onDeleteClick(selected.id);
                }}
              >
                <DeleteIcon fontSize="small" />
                {t('global.delete')}
              </Button>
              <ButtonGroup variant="outlined" color="primary">
                <Button onClick={() => setIndex(index + 1)} sx={{ borderRadius: '4px' }}>
                  {' '}
                  {'<'}
                </Button>
                <Button>{''}</Button>
                <Button onClick={() => setIndex(index + 1)} sx={{ borderRadius: '4px' }}>
                  {'>'}
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        )}
      </Box>

      <Box mt={2}>
        <TableLayout
          columns={columns}
          data={items}
          handleDelete={row => {
            onDeleteClick(row.id);
          }}
        />
      </Box>
    </Box>
  );
};

export default SingleSliderPage;
