//components
import { Box, CardContent, CardMedia, CircularProgress, Typography } from '@mui/material';
import PageLayout from '@pages/Page/PageLayout';
//hooks
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIndustries } from '@hooks/useIndustries/useIndustries';
//type
import { MRT_ColumnDef } from 'material-react-table';
//helpers
import { dayjs, getTranslationValue, getTypographyColor } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { NavLink, Navigate } from 'react-router-dom';
import { useInterests } from '@hooks/useInterests/useInterests';
import { primaryColor } from '@src/theme';
import TableLayout from '@components/TableLayout/TableLayout';
import { VoucherType } from '@interfaces/vouchers/vouchers';
import { StatusEnum } from '@enum/progressEnum';
import Loading from '@components/Loading/Loading';

const SingleInterestPage = () => {
  const { t, i18n } = useTranslation();

  const { singleInterest, voucherPagination, setVoucherPagination, isLoadingSingle } =
    useInterests();

  const voucherColumns = useMemo<MRT_ColumnDef<VoucherType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        Cell: ({ cell, row }) => (
          <NavLink to={`/vouchers/${row.original.id}`}>
            <Typography variant="body2">{cell.getValue<string>()}</Typography>
          </NavLink>
        ),
        enableColumnFilter: false
      },
      {
        accessorKey: 'companyName',
        header: t('global.author'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        filterVariant: 'select',
        filterSelectOptions: Object.values(StatusEnum),
        Cell: ({ cell }) => (
          <Typography color={getTypographyColor(cell.getValue<string>())}>
            {cell.getValue<string>()}
          </Typography>
        )
      }
    ],
    [t]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleInterest) return <Navigate to={'/error-page'} replace />;
  return (
    <Box display={'flex'} flexDirection={'column'} className="container">
      <CardContent sx={{ display: 'flex', columnGap: 2 }}>
        <CardMedia
          component="img"
          sx={{
            width: 120,
            height: 120,
            border: '2px solid ' + primaryColor,
            bgcolor: '#bdbdbd'
          }}
          image={
            singleInterest?.imageUrl ? singleInterest.imageUrl + '?' + dayjs().toISOString() : ''
          }
          alt=""
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Typography component="div" variant="h5">
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {t('global.interest')}
            </Typography>
            {singleInterest &&
              getTranslationValue(
                singleInterest.translations,
                'name',
                i18n.language as SupportedLanguagesEnum
              )}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h5" color="primary" fontWeight={'bold'}>
          {t('interests.interests.vouchers_with_interest')}
        </Typography>
        <TableLayout
          data={[]}
          columns={voucherColumns}
          rowCount={0}
          pagination={voucherPagination}
          setPagination={setVoucherPagination}
        />
      </CardContent>
    </Box>
  );
};

export default SingleInterestPage;
