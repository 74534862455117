import { useRecoilValue } from 'recoil';
import '@styles/components/pushNotification.scss';
import { isShowNotificationAtom, notificationAtom } from '@atoms/notfications';
import { NotificationTranslations } from '@interfaces/notifications/notifications';

export default function PushNotificationModal({
  handleMouseOver,
  handleMouseLeave
}: {
  handleMouseOver: () => void;
  handleMouseLeave: () => void;
}) {
  // @ts-ignore
  const isShowNotification = useRecoilValue(isShowNotificationAtom);
  const notification = useRecoilValue(notificationAtom);

  const locale = localStorage.getItem('i18nextLng') || 'sr';

  const getTitle = (notificationTranslations: NotificationTranslations): string => {
    switch (locale) {
      case 'en':
        return notificationTranslations.en.title;
      case 'de':
        return notificationTranslations.de.title;
      case 'fr':
        return notificationTranslations.fr.title;
      default:
        return notificationTranslations.sr.title;
    }
  };

  return (
    <div
      className={`push-notification-modal ${isShowNotification ? 'show' : ''}`}
      onMouseOver={() => handleMouseOver()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className="push-notification-modal__info">
        <div className="push-notification-modal__title">{notification && notification.origin}</div>
        <div className="push-notification-modal__body">{notification && notification.title}</div>
      </div>
    </div>
  );
}
