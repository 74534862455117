import React from 'react';
//components
import { Box, Button, Card, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Loading from '@components/Loading/Loading';
//hooks
import { useTranslation } from 'react-i18next';
import { useNotifications } from '@hooks/useNotifications/useNotifications';
//theme
import { primaryColor } from '@src/theme';

const NotificationsPage = () => {
  const { t, i18n } = useTranslation();

  const {
    onNotificationClick,
    seenNotfication,
    lastElementRef,
    notifications,
    isLoadingNotifications,
    onSeenNotificationClick
  } = useNotifications();

  if (isLoadingNotifications) return <Loading />;
  if (!notifications) return <div />;
  return (
    <div className="container">
      <Typography variant="h4" fontWeight={'bold'} color={'primary'}>
        {t('sidebar.notifications')}
      </Typography>

      <List sx={{ width: '100%' }}>
        {notifications?.map((n, index) => (
          <React.Fragment key={n.id}>
            <Card sx={{ my: 2 }}>
              <Box ref={notifications.length === index + 1 ? lastElementRef : null}>
                <ListItemButton onClick={() => onNotificationClick(n)}>
                  <ListItem
                    alignItems="flex-start"
                    key={n.id}
                    sx={{ opacity: n.seen ? 0.6 : 1, my: 1 }}
                  >
                    <ListItemText
                      sx={{ p: 2 }}
                      primary={n.titleTemplated ?? n.title}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {n.descriptionTemplated ?? n.description}
                        </Typography>
                      }
                    />
                    {n.seen ? null : (
                      <div
                        className="dot"
                        style={{
                          width: '.75rem',
                          aspectRatio: '1',
                          background: primaryColor,
                          borderRadius: '100rem',
                          position: 'absolute',
                          left: '0',
                          top: '2rem'
                        }}
                      ></div>
                    )}
                  </ListItem>
                </ListItemButton>
                {!n.seen && (
                  <Box mb={1} ml={2}>
                    <Button
                      onClick={() => onSeenNotificationClick(n.id)}
                      variant="contained"
                      
                    >
                      {t('global.mark_as_seen')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Card>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default NotificationsPage;
