import { MetaType } from '@interfaces/response';

export enum PodcastTypeEnum {
  YOUTUBE_LIVE = 'YOUTUBE_LIVE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO'
}

export type PodcastChannelType = {
  id: string;
  externalId: string;
  title: string;
  description: string;
  url: string;
  iconUrl: string;
  bannerUrl: string;
  publishedAt: string;
  type: 'YOUTUBE';
  createdAt: string;
  updatedAt: string;
};

export type CreatePodcastChannelType = {
  externalId: string;
  type: 'YOUTUBE';
};


export type UpdatePodcastChannelType = {
  id: string;
  data: PodcastChannelType;
};


export type PodcastType = {
  id: string;
  externalId: string;
  title: string;
  description: string;
  url: string;
  thumbnailUrl: string;
  defaultLanguage: string;
  defaultAudioLanguage: string;
  duration: string;
  embededHtml: string;
  publishedAt: string;
  liveStartTime: string;
  liveEndTime: string;
  liveScheduledStartTime: string;
  liveScheduledEndTime: string;
  type: PodcastTypeEnum;
  createdAt: string;
  updatedAt: string;
};

export type CreatePodcastType = {
  externalId: string;
  type: PodcastTypeEnum;
  channelId: string;
};

export type UpdatePodcastType = {
  id: string;
  data: PodcastType;
};

export type AllPodcastsType = {
  data: PodcastType[];
  meta: MetaType;
};

export type AllPodcastChannelsType = {
  data: PodcastChannelType[];
  meta: MetaType;
};
