//interface
import { AllFAQsResponseType, FAQType } from '@interfaces/faq/faq';
import { ErrorsResponseType } from '@interfaces/response';
//api
import { getAllFAQ } from '@services/faq/faq';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllFAQs = (
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<FAQType[], ErrorsResponseType<{}>> =>
  useQuery(['faqs'], () => getAllFAQ(), {
    onError: err => onError(err),
    select: res => res.data
    // enabled: false
  });
