//react-query
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import {
  CreateVoucherType,
  SingleVoucherResponseType,
  UpdateVoucherType,
  VoucherType
} from '@interfaces/vouchers/vouchers';
import {
  approveVoucher,
  createVoucher,
  declineVoucher,
  deleteVoucher,
  deleteVoucherImages,
  updateVoucher
} from '@services/vouchers/vouchers';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreateVoucher = (
  onSuccess: (data: VoucherType) => void,
  onError: (err: ErrorType<CreateVoucherType>) => void
): UseMutationResult<
  SingleVoucherResponseType, // response
  ErrorsResponseType<CreateVoucherType>,
  { data: FormData; companyId: string }, // request
  (data: VoucherType) => void // on success
> =>
  useMutation({
    mutationFn: createVoucher,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateVoucher = (
  onSuccess: (data: VoucherType) => void,
  onError: (err: ErrorType<CreateVoucherType>) => void
): UseMutationResult<
  SingleVoucherResponseType, // response
  ErrorsResponseType<CreateVoucherType>,
  UpdateVoucherType, // request
  (data: VoucherType) => void // on success
> =>
  useMutation({
    mutationFn: updateVoucher,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteVoucher = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteVoucher,
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });

export const useApproveVoucher = (
  onSuccess: (data: VoucherType) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: approveVoucher,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useDeclineVoucher = (
  onSuccess: (data: VoucherType) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: declineVoucher,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useDeleteVoucherImage = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { id: string; type: string[] }, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: req => deleteVoucherImages(req.id, req.type),
    onSuccess: response => {
      onSuccess && onSuccess();
    },
    onError: err => onError(err)
  });
