//react-query
import {
  NotificationResponseType,
  NotificationsType
} from '@interfaces/notifications/notifications';
import { ErrorsResponseType } from '@interfaces/response';
import {
  getNotificationCount,
  getNotificationsHisory,
  seenNotfication
} from '@services/notifications/notification';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';

export const useAllNotifications = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params: {
    order?: 'ASC' | 'DESC';
    page?: number;
    take?: number;
    lang: SupportedLanguagesEnum;
  },
  disable?: boolean
): UseQueryResult<NotificationResponseType, ErrorsResponseType<{}>> =>
  useQuery(['notifications', params], () => getNotificationsHisory(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: !disable
  });

export const useNotificationCount = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSucces: (data: number) => void
): UseQueryResult<number, ErrorsResponseType<{}>> =>
  useQuery(['notification-count'], () => getNotificationCount(), {
    onError: err => onError(err),
    onSuccess: data => onSucces(data),
    select: res => res.data
  });

export const useSingleNotification = (
  id: undefined | string,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<NotificationsType | undefined, ErrorsResponseType<{}>> =>
  useQuery(['notification', id], () => (id ? seenNotfication(id) : null), {
    onError: err => onError(err),
    select: res => res?.data,
    enabled: !!id
  });

export const useAllNotificationsInfinity = (
  lang: SupportedLanguagesEnum,
  onError: (err: ErrorsResponseType<{}>) => void
): UseInfiniteQueryResult<{ data: NotificationResponseType }, ErrorsResponseType<{}>> =>
  useInfiniteQuery(
    ['infinite-notifications', lang],
    a =>
      getNotificationsHisory({
        page: a.pageParam,
        take: 8,
        order: 'DESC',
        lang: lang
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.hasNextPage ? lastPage.data.meta.page + 1 : undefined,
      onError: err => onError(err)
    }
  );
