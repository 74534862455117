import { useState } from 'react';
// hooks
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//api
import {
  useDeleteUser,
  useDeleteUsersImage,
  useUpdateUser,
  useUploadUsersImage
} from '@api/mutations/user/user';
import { useSingleUser } from '@api/queries/users/users';
// atoms
import { userAtom } from '@atoms/userAtom';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
//recoil
import { useResetRecoilState, useSetRecoilState, useRecoilState } from 'recoil';
//type
import { User } from '@interfaces/users/users';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { InputTypeEnum } from '@enum/formModal';
//helpers
import { userFields } from '@helpers/formFields/formFields';

export function useProfile() {
  const [user, setUser] = useRecoilState(userAtom);

  const { t } = useTranslation();

  const { userId } = useParams();

  const [formData, setFormData] = useState<FormModalPropsType<User> | null>(null);

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    getValues,
    setValue
  } = useForm<User>({});

  const onUpdateSuccess = (data: User) => {
    setFormData(null);
    setUser(data);
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onUpdateError = (err: ErrorType<User>) => {
    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof User, {
          type: 'custom',
          message: err.errors[key as keyof User]?.join(', ')
        });
      }
    else {
      setError('root', {
        type: 'custom',
        message: err.message
      });
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };
  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onDeleteSuccess = () => {
    setFormData(null);
    localStorage.clear();
    window.dispatchEvent(new Event('logout'));
  };

  const onUploadImageSuccess = (data: { image: string }) => {
    setFormData(null);
    reset({ image: undefined });
    setUser(prev => {
      return { ...prev, image: data.image } as User;
    });
  };

  const onSuccessDeleteImage = () => {
    setFormData(null);
    setUser(prev => {
      return { ...prev, image: undefined } as User;
    });
  };


  const { data: singleUser } = useSingleUser(userId, onError);
  const { mutate: updateUser } = useUpdateUser(onUpdateSuccess, onUpdateError);
  const { mutate: deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUser(
    onDeleteSuccess,
    onError
  );

  const { mutate: uploadUsersImage } = useUploadUsersImage(onUploadImageSuccess, onError);
  const { mutate: deleteUsersImage } = useDeleteUsersImage(onSuccessDeleteImage, onError);

  const onSubmitImage = () => {
    const image = getValues('image');
    if (user?.image && image === null) {
      deleteUsersImage({
        id: user.id,
        type: 'image'
      });
    } else if (user && image && image !== user?.image) {
      const data = new FormData();
      data.append('file', image);

      uploadUsersImage({
        id: user.id,
        data: data,
        type: 'image'
      });
    } else {
      setFormData(null);
    }
  };

  const onSubmit = (data: User) => {
    updateUser({
      id: data.id,
      data: Object.fromEntries(
        Object.entries({
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender,
          dateOfBirth: data.dateOfBirth,
          address: data.address,
          city: data.city,
          newCountryAlpha2: data.newCountryAlpha2
        })
      )
    });
  };

  const onEdit = () => {
    if (user) {
      let tmp: User = { ...user };
      Object.keys(tmp).forEach(function (key) {
        if (tmp[key as keyof User] === null) {
          delete tmp[key as keyof User];
        }
      });
      // tmp.dateOfBirth = dayjs(user.dateOfBirth);
      reset(tmp);
    }

    setFormData({
      title: t('user.edit_profile'),
      open: true,
      control: control,
      fields: userFields(t),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {},
      setValue: setValue,
      setError: setError,
      clearErrors: clearErrors
    });
  };

  const onDeleteClick = () => {
    setFormData({
      title: t(`global.delete_title`, { object: t(`delete.user_profile`) }),
      subtitle: t(`user.delete_subtitle`, { company: user?.userCompanies?.[0]?.company.name }),
      open: true,
      fields: [],
      onSubmit: () => {
        if (user) 
          deleteUser(user.id);
        
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onCancel: () => {
        setFormData(null);
      }
    });
  };

  const onEditProfileImage = () => {
    reset({ image: user?.image });

    setFormData({
      title: t('user.edit_profile'),
      open: true,
      control: control,
      fields: [
        {
          key: 'image',
          type: InputTypeEnum.image,
          label: t('global.image')
        }
      ],
      onSubmit: handleSubmit(onSubmitImage),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        setFormData(null);
      },
      setValue: setValue
    });
  };

  return {
    formData,
    setFormData,
    control,
    user: singleUser ? singleUser : user,
    onEdit,
    t,
    onDeleteClick,
    errors,
    getValues,
    setValue,
    onEditProfileImage,
    isLoadingDelete: isLoadingDeleteUser
  };
}
