import {
  CreateSliderItemType,
  CreateSliderType,
  SliderItemType,
  SliderType,
  UpdateSliderType
} from '@interfaces/sliders/sliders';
import axiosInstance from '@services/config';

export const getAllSliders = (): Promise<{ data: SliderType[] }> => axiosInstance.get('v1/sliders');

export const getSingleSlider = (id: string): Promise<{ data: SliderType }> =>
  axiosInstance.get('v1/sliders/' + id);

export const createSlider = (data: CreateSliderType): Promise<{ data: SliderType }> =>
  axiosInstance.post('v1/sliders', data);

export const updateSlider = (data: UpdateSliderType): Promise<{ data: SliderType }> =>
  axiosInstance.patch('v1/sliders/' + data.id, data.data);

export const deleteSlider = (id: string): Promise<any> => axiosInstance.delete('v1/sliders/' + id);

export const createSliderItem = (data: CreateSliderItemType): Promise<{ data: SliderItemType }> =>
  axiosInstance.post('v1/sliders/items', data);

export const deleteSliderItem = (id: string): Promise<any> =>
  axiosInstance.delete('v1/sliders/items/' + id);
