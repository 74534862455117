//api
import {
  useCreateGenres,
  useDeleteGenreImage,
  useDeleteGenres,
  useUpdateGenres
} from '@api/mutations/interests/interests';
import { useAllGenres, useSingleGenre } from '@api/queries/interests/interests';
//helpers
import { formHelper } from '@helpers/formDataHelper';
//type
import { CreateInterestsType, InterestsType } from '@interfaces/interests/interests';
import { useForm } from 'react-hook-form';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { checkForEmptyTranslation } from '@helpers/utility';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAllRadioStations } from '@api/queries/radioStatios';
import { useState } from 'react';
import { MRT_PaginationState } from 'material-react-table';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

export function useGenres() {
  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t, i18n } = useTranslation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    getValues
  } = useForm<CreateInterestsType>();

  const { genreId } = useParams();
  const [radioPagination, setRadioPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const onSuccessDelete = () => {
    refetchGenres();
    reset({
      translations: {
        sr: { name: '' },
        en: { name: '' },
        de: { name: '' },
        fr: { name: '' }
      },
      file: '',
      id: undefined
    });
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onSuccessCreate = (data: InterestsType) => {
    const val = getValues();
    if (val.file === null) {
      deleteGenreImage(data.id);
    } else {
      refetchGenres();
      reset({
        translations: {
          sr: { name: '' },
          en: { name: '' },
          de: { name: '' },
          fr: { name: '' }
        },
        file: '',
        id: undefined
      });

      setInfoPopup({
        title: val.id ? t('global.success_update') : t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
    }
  };

  const onErrorCreate = (err: ErrorType<InterestsType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });

    if (err.errors?.translations) {
      setError('root', {
        type: 'custom',
        message: err.errors.translations.join(', ')
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const {
    data: genres,
    refetch: refetchGenres,
    isLoading: isLoadingGenres
  } = useAllGenres(onError);

  const {
    mutate: createGenre,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreateGenres(onSuccessCreate, onErrorCreate);
  const {
    mutate: updateGenre,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdateGenres(onSuccessCreate, onErrorCreate);
  const { mutate: deleteGenre, isLoading: isLoadingDelete } = useDeleteGenres(
    onSuccessDelete,
    onError
  );

  const { data: singleGenre, isLoading: isLoadingSingle } = useSingleGenre(genreId, onError);

  const { data: radios } = useAllRadioStations(
    onError,
    {
      page: radioPagination.pageIndex + 1,
      take: radioPagination.pageSize,
      genreId: genreId,
      lang: i18n.language
    },
    !genreId
  );

  const { mutate: deleteGenreImage } = useDeleteGenreImage(onSuccessDelete, onError);

  const onSubmitGenre = (data: CreateInterestsType) => {
    if (checkForEmptyTranslation(data.translations)) {
      setError('root', {
        type: 'custom',
        message: t('global.translation_error_message')
      });
      return;
    }
    if (data.id) {
      if (data.file === null || typeof data.file === 'string') {
        updateGenre({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations })
        });
      } else {
        updateGenre({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations, file: data.file })
        });
      }
    } else {
      delete data.id;
      createGenre(formHelper.getBody({ translations: data.translations, file: data.file }));
    }
  };

  return {
    genres: genres ? genres : [],
    refetchGenres,
    isLoadingGenres,
    onSubmitGenre,
    deleteGenre,
    isSuccessGenres: isCreateSuccess || isUpdateSuccess,
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    errors,
    singleGenre,
    isLoadingSingle,
    radioPagination,
    setRadioPagination,
    radios: radios ? radios.data : [],
    rowCountRadio: radios ? radios.meta.itemCount : 0,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
