import {
  CreateRadioProgramType,
  CreateRadioType,
  RadioImagesType,
  RadioProgramType,
  RadioResponseType,
  RadioType,
  UpdateRadioType
} from '@interfaces/radio/radio';
import axiosInstance from '@services/config';

export const getAllRadio = (params?: {
  order?: string;
  page?: number;
  take?: number;
  search?: string;
  genreId?: string;
  lang: string;
}): Promise<{ data: RadioResponseType }> => {
  return axiosInstance.get('v1/radio-stations/pageable', { params: params });
};

export const createRadio = (data: CreateRadioType): Promise<{ data: RadioType }> => {
  return axiosInstance.post('v1/radio-stations', data);
};

export const updateRadio = (data: UpdateRadioType): Promise<{ data: RadioType }> => {
  return axiosInstance.patch('v1/radio-stations/' + data.id, data.data);
};

export const deleteRadio = (id: string): Promise<{}> => {
  return axiosInstance.delete('v1/radio-stations/' + id);
};

export const getSingleRadio = (id: string, lang?: string): Promise<{ data: RadioType }> => {
  return axiosInstance.get('v1/radio-stations/' + id, {
    params: { lang }
  });
};

export const createRadioProgram = (
  data: CreateRadioProgramType
): Promise<{ data: RadioProgramType[] }> => {
  return axiosInstance.post(`v1/programs/${data.radioId}`, data.data);
};

export const deleteRadioProgram = (id: string): Promise<{}> => {
  return axiosInstance.delete('v1/programs/' + id);
};

export const updateRadioImages = (data: RadioImagesType): Promise<{ data: RadioImagesType }> => {
  return axiosInstance.patch(`v1/radio-stations/${data.id}/images`, data.data);
};

export const deleteRadioImages = (id: string, type: string[]): Promise<{}> =>
  axiosInstance.delete(`v1/radio-stations/${id}/images?type=${type}`);
