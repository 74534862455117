//type
import {
  InterestsLocalizedType,
  InterestsType,
  UpdateInterestsType
} from '@interfaces/interests/interests';
//axios
import axiosInstance from '@services/config';

export const getRubrics = (): Promise<{ data: InterestsType[] }> =>
  axiosInstance.get('v2/rubrics', {
    params: { order: 'DESC', lang: localStorage.getItem('i18nextLng') }
  });

export const getSingleRubric = (id: string): Promise<{ data: InterestsType }> =>
  axiosInstance.get('v2/rubrics/' + id);

export const createRubrics = (data: FormData): Promise<{ data: InterestsType }> =>
  axiosInstance.post('v2/rubrics', data);

export const updateRubrics = (data: UpdateInterestsType): Promise<{ data: InterestsType }> =>
  axiosInstance.patch('v2/rubrics/' + data.id, data.data);

export const deleteRubrics = (id: string): Promise<{}> => {
  return axiosInstance.delete('v2/rubrics/' + id);
};

export const deleteRubricImage = (id: string): Promise<{}> =>
  axiosInstance.delete(`v2/rubrics/${id}/images`);

export const getGenres = (): Promise<{ data: InterestsType[] }> =>
  axiosInstance.get('v2/genres', {
    params: { order: 'DESC', lang: localStorage.getItem('i18nextLng') }
  });

export const getSingleGenre = (id: string): Promise<{ data: InterestsType }> =>
  axiosInstance.get('v2/genres/' + id);

export const createGenres = (data: FormData): Promise<{ data: InterestsType }> =>
  axiosInstance.post('v2/genres', data);

export const updateGenres = (data: UpdateInterestsType): Promise<{ data: InterestsType }> =>
  axiosInstance.patch('v2/genres/' + data.id, data.data);

export const deleteGenres = (id: string): Promise<{}> => {
  return axiosInstance.delete('v2/genres/' + id);
};

export const deleteGenreImage = (id: string): Promise<{}> =>
  axiosInstance.delete(`v2/genres/${id}/images`);

export const getIndustries = (): Promise<{ data: InterestsType[] }> =>
  axiosInstance.get('v2/industries', {
    params: { order: 'DESC', lang: localStorage.getItem('i18nextLng') }
  });

export const getSingleIndustry = (id: string): Promise<{ data: InterestsType }> =>
  axiosInstance.get('v2/industries/' + id);

export const createIndustries = (data: FormData): Promise<{ data: InterestsType }> =>
  axiosInstance.post('v2/industries', data);

export const updateIndustries = (data: UpdateInterestsType): Promise<{ data: InterestsType }> =>
  axiosInstance.patch('v2/industries/' + data.id, data.data);

export const deleteIndustries = (id: string): Promise<{}> => {
  return axiosInstance.delete('v2/industries/' + id);
};

export const deleteIndustryImage = (id: string): Promise<{}> =>
  axiosInstance.delete(`v2/industries/${id}/images`);

export const getInterests = (): Promise<{ data: InterestsType[] }> =>
  axiosInstance.get('v2/interests', {
    params: { order: 'DESC', lang: localStorage.getItem('i18nextLng') }
  });

export const getSingleInterest = (id: string): Promise<{ data: InterestsType }> =>
  axiosInstance.get('v2/interests/' + id);

export const createInterests = (data: FormData): Promise<{ data: InterestsType }> =>
  axiosInstance.post('v2/interests', data);

export const updateInterests = (data: UpdateInterestsType): Promise<{ data: InterestsType }> =>
  axiosInstance.patch('v2/interests/' + data.id, data.data);

export const deleteInterests = (id: string): Promise<{}> => {
  return axiosInstance.delete('v2/interests/' + id);
};

export const deleteInterestImage = (id: string): Promise<{}> =>
  axiosInstance.delete(`v2/interests/${id}/images`);

export const getInterestsLocalized = (lng: string): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get('v2/interests/localized', {
    params: { lang: lng }
  });
