//react
import { useState } from 'react';
//interfaces
import { MRT_PaginationState, MRT_ColumnFiltersState } from 'material-react-table';
import { CreateFAQRequestType, FAQType } from '@interfaces/faq/faq';
//useForm
import { useForm } from 'react-hook-form';
//api
import { useAllFAQs } from '@api/queries/faq/faq';
import { useCreateFAQ, useDeleteFAQ, useUpdateFAQ } from '@api/mutations/faq/faq';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';

import { checkForEmptyTranslation } from '@helpers/utility';
import { useTranslation } from 'react-i18next';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

export function useFAQ() {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [values, setValues] = useState<FAQType | undefined>(undefined);

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors }
  } = useForm<CreateFAQRequestType | FAQType>({
    values
  });

  const handleReset = () => {
    setValues(undefined);

    reset({
      translations: {
        sr: { question: '', answer: '' },
        en: { question: '', answer: '' },
        de: { question: '', answer: '' },
        fr: { question: '', answer: '' }
      }
    });

    setTimeout(() => {
      resetCreate();
      resetUpdate();
    }, 500);
  };

  const onSuccessCreate = (data: FAQType) => {
    setInfoPopup({
      title: values?.id ? t('global.success_update') : t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
    refetch();
    handleReset();
  };

  const onErrorCreate = (err: ErrorType<CreateFAQRequestType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
    if (err.errors?.translations) {
      setError('root', {
        type: 'custom',
        message: err.errors.translations?.join(', ')
      });
    } else if (err.statusCode === 409) {
      setError('root', {
        type: 'custom',
        message: err.message
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onSuccessDelete = () => {
    refetch();
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const { data: faqs, refetch, isLoading } = useAllFAQs(onError);
  const {
    mutate: createFAQ,
    isSuccess: isCreateSuccess,
    reset: resetCreate,
    isLoading: isLoadingCreate
  } = useCreateFAQ(onSuccessCreate, onErrorCreate);

  const {
    mutate: updateFAQ,
    isSuccess: isUpdateSuccess,
    reset: resetUpdate,
    isLoading: isLoadingUpdate
  } = useUpdateFAQ(onSuccessCreate, onErrorCreate);

  const { mutate: deleteFAQ, isLoading: isLoadingDelete } = useDeleteFAQ(onSuccessDelete, onError);

  const onSubmit = (data: CreateFAQRequestType) => {
    if (checkForEmptyTranslation(data.translations)) {
      setError('root', {
        type: 'custom',
        message: t('global.translation_error_message')
      });
      return;
    }

    if (values?.id) {
      updateFAQ({ id: values.id, data: { translations: data.translations } });
    } else {
      createFAQ({ translations: data.translations });
    }
  };

  const handleEdit = (data: FAQType) => {
    setValues(data);
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    setValue,
    reset: handleReset,
    setError,
    all_faqs: faqs ? faqs : [],
    handleEdit,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    errors,
    success: isUpdateSuccess || isCreateSuccess,
    deleteFAQ,
    isLoading,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
