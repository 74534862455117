import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { SupportedLanguagesEnum, namespaces, translationResources } from './languages.config';

// const apiKey = 'kVR0sfzZbuFr67S-K8cMOg';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'sr',
    lng: localStorage.getItem('i18nextLng') || 'sr',

    ns: namespaces,

    defaultNS: 'global',
    nsSeparator: '.',
    supportedLngs: Object.values(SupportedLanguagesEnum),

    resources: translationResources
  });
