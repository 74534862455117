import { Box, CircularProgress } from '@mui/material';
import { primaryColor } from '@src/theme';

const Loading = () => {
  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} my={2}>
      <CircularProgress sx={{ color: primaryColor }} />
    </Box>
  );
};

export default Loading;
