//components
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import FormInputField from '@components/FormInputField/FormInputField';
import LanguageTabs from '@components/LanguagesTabs/LanguageTabs';
//react form
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form';
//types
import { MappedFieldsType } from '@interfaces/modalForm';
import { InputTypeEnum } from '@enum/formModal';
//hooks
import { useTranslation } from 'react-i18next';

export type FormModalPropsType<T extends {}> = {
  title: string;
  subtitle?: string;
  open: boolean;
  fields: MappedFieldsType[];
  control?: Control<T>;
  onCancel: () => void;
  onSubmit: () => void;
  setValue?: UseFormSetValue<T>;
  setError?: UseFormSetError<T>;
  buttonText: string;
  variant: 'form' | 'delete' | 'info';
  errors?: FieldErrors<T>;
  clearErrors?: UseFormClearErrors<T>;
  getValues?: UseFormGetValues<T>;
  isLoading?: boolean;
};

const FormModal = <T extends {}>({
  title,
  subtitle,
  open,
  fields,
  control,
  onCancel,
  onSubmit,
  setValue,
  buttonText,
  variant,
  setError,
  errors,
  clearErrors,
  getValues,
  isLoading
}: FormModalPropsType<T>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255,255,255, .5)' } }
      }}
      onClose={onCancel}
      maxWidth={variant === 'form' ? 'md' : 'sm'}
      fullWidth
    >
      <DialogTitle variant="h5">
        {title}
        <Typography variant="body1">{subtitle}</Typography>
      </DialogTitle>
      <Box component={control && variant === 'form' ? 'form' : 'div'} onSubmit={onSubmit}>
        <DialogContent>
          <Grid container spacing={'1rem'}>
            {fields.filter(field => field.translation).length > 0 && (
              <LanguageTabs
                translationFields={fields.filter(field => field.translation)}
                control={control}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                getValues={getValues}
              />
            )}
            {fields
              .filter(field => !field.translation)
              .map((option, index) => (
                <Grid item xs={12} key={index}>
                  {option.type === InputTypeEnum.custom ? (
                    option.element
                  ) : (
                    <FormInputField
                      option={option}
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      required={option.required}
                      clearErrors={clearErrors}
                      getValues={getValues}
                    />
                  )}
                </Grid>
              ))}
            {errors?.root && (
              <Typography color={'error'} variant="caption">
                {errors.root.message}
              </Typography>
            )}
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant={variant === 'delete' ? 'outlined' : 'contained'}
                onClick={onSubmit}
                type="submit"
                sx={{ mx: 2 }}
                disabled={isLoading}
              >
                {buttonText}
              </Button>

              <Button
                color="error"
                onClick={onCancel}
                variant={variant === 'delete' ? 'contained' : 'outlined'}
              >
                {variant === 'info' ? t('global.close') : t('global.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default FormModal;
