import { translationSerbian } from './translation/srTranslation';
import { translationEnglish } from './translation/enTranslation';
import { translationGerman } from './translation/deTranslation';
import { translationFrench } from './translation/frTranslation';
import YugoFlag from '@src/assets/flags/YugoFlag.png';
import FlagUK from '@src/assets/flags/FlagUK.png';
import FlagGermany from '@src/assets/flags/FlagGermany.png';
import FlagFrance from '@src/assets/flags/FlagFrance.png';

export enum SupportedLanguagesEnum {
  ENGLISH = 'en',
  SERBIAN = 'sr',
  GERMAN = 'de',
  FRENCH = 'fr'
}

export const translationResources = {
  sr: translationSerbian,
  en: translationEnglish,
  de: translationGerman,
  fr: translationFrench
};

export const namespaces = [
  'login',
  'global',
  'sidebar',
  'voucher',
  'radio',
  'category',
  'faq',
  'slider',
  'user',
  'delete',
  'hit_list',
  'news',
  'job',
  'status',
  'interests',
  'verify_email',
  'podcast',
  'steps'
];

export const languages: { id: SupportedLanguagesEnum; label: string; icon: string }[] = [
  {
    id: SupportedLanguagesEnum.SERBIAN,
    label: 'SRB/BIH/HRV',
    icon: YugoFlag
  },
  {
    id: SupportedLanguagesEnum.ENGLISH,
    label: 'English',
    icon: FlagUK
  },
  {
    id: SupportedLanguagesEnum.GERMAN,
    label: 'Deutsch',
    icon: FlagGermany
  },
  {
    id: SupportedLanguagesEnum.FRENCH,
    label: 'French',
    icon: FlagFrance
  }
];
