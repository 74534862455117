import { useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
//components
import Sidebar from '@components/Sidebar/Sidebar';
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Snackbar,
  Toolbar,
  useMediaQuery,
  Button
} from '@mui/material';
import PushNotificationsProvider from '@wrappers/PushNotificationsProvider/PushNotificationsProvider';
//icons
import MenuIcon from '@mui/icons-material/Menu';
//theme
import theme, { primaryColor } from '@src/theme';
//atom
import { useRecoilValue } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import ErrorPopup from '@components/ErrorPopup/ErrorPopup';
import { authAtom } from '@atoms/authAtom';
//helpers
import { getProfileSteps } from '@helpers/steps/steps';
//reactour
import { TourProvider } from '@reactour/tour';
import { userAtom } from '@atoms/userAtom';

const Layout = () => {
  const [openSidemenu, setOpenSidemenu] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const infoPopup = useRecoilValue(infoPopupAtom);
  const isAuth = useRecoilValue(authAtom);
  const user = useRecoilValue(userAtom);

  const { setSteps } = useTour();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (setSteps) {
      setSteps(
        getProfileSteps(t, () => {
          navigate(`/company/${user?.userCompanies?.[0]?.companyId}`, { replace: true });
        })
      );
    }
  }, [setSteps]);

  const saveStepProgress = (currStep: number) => {
    const saved = localStorage.getItem('steps');
    let steps = saved ? JSON.parse(saved) : null;

    if (!steps) {
      steps = {};
    }

    steps['currStep'] = currStep;

    localStorage.setItem('steps', JSON.stringify(steps));
  };

  return (
    <Box ml={{ xs: 0, md: '280px' }}>
      <TourProvider
        steps={[]}
        position={'bottom'}
        afterOpen={() => {
          document.body.style.overflowY = 'hidden';
        }}
        beforeClose={() => {
          document.body.style.overflowY = 'visible';
        }}
        nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
          const last = currentStep === stepsLength - 1;
          saveStepProgress(last ? 0 : currentStep);
          return (
            <Button
              onClick={() => {
                if (last) {
                  setCurrentStep(0);
                  setIsOpen(false);
                } else {
                  setCurrentStep(s => s + 1);
                }
              }}
            >
              {last ? t('global.close') : t('global.next')}
            </Button>
          );
        }}
        prevButton={({ currentStep, setCurrentStep }) => {
          const first = currentStep === 0;
          saveStepProgress(currentStep);

          if (first) return null;
          return (
            <Button
              onClick={() => {
                setCurrentStep(s => s - 1);
              }}
            >
              {t('global.back')}
            </Button>
          );
        }}
        onClickMask={({ currentStep, setIsOpen }) => {
          saveStepProgress(currentStep);
          setIsOpen(false);
        }}
        styles={{
          popover: base => ({
            ...base,
            '--reactour-accent': primaryColor,
            borderRadius: 16,
            margin: 16
          }),
          maskArea: base => ({ ...base, rx: 16 }),
          controls: base => ({ ...base, marginTop: 50 })
        }}
        badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + '/' + totalSteps}
        disableDotsNavigation
      >
        {infoPopup && infoPopup.type === 'error' && (
          <ErrorPopup title={infoPopup.title} onClose={infoPopup.onClose} />
        )}

        <Snackbar
          open={infoPopup?.type === 'success'}
          autoHideDuration={600}
          onClose={infoPopup?.onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={infoPopup?.onClose}
            severity="success"
            sx={{ width: '100%', outline: '1px solid #2e7d32' }}
          >
            {infoPopup?.title}
          </Alert>
        </Snackbar>

        {!isMdUp && (
          <AppBar
            position="static"
            color="inherit"
            sx={{ boxShadow: 'none', borderBottom: 1, borderColor: 'divider', borderRadius: '0px' }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => {
                  setOpenSidemenu(!openSidemenu);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}

        <Sidebar
          open={openSidemenu}
          onClose={() => {
            setOpenSidemenu(!openSidemenu);
          }}
        />

        {isAuth ? <PushNotificationsProvider /> : null}
        <Outlet />
      </TourProvider>
    </Box>
  );
};

export default Layout;
