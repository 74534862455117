//react
import { useMemo } from 'react';
//components
import { Box } from '@mui/system';
import PageLayout from '@pages/Page/PageLayout';
import { Tooltip, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
//interfaces
import { type MRT_ColumnDef } from 'material-react-table';
import { FAQType } from '@interfaces/faq/faq';
//hook
import { useFAQ } from '@hooks/useFAQ/useFAQ';
import { useTranslation } from 'react-i18next';
//helpers
import { faqFields } from '@helpers/formFields/formFields';
import { getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const FAQPage = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    setValue,
    reset,
    setError,
    all_faqs,
    handleEdit,
    success,
    deleteFAQ,
    isLoading,
    isSubmitting
  } = useFAQ();

  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<FAQType>[]>(
    () => [
      {
        accessorKey: 'question',
        header: t('global.question'),
        enableColumnFilter: false,
        Cell: ({ row }) =>
          getTranslationValue(
            row.original.translations,
            'question',
            i18n.language as SupportedLanguagesEnum
          )
      },
      {
        accessorKey: 'answer',
        header: t('global.response'),
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Tooltip
            title={getTranslationValue(
              row.original.translations,
              'answer',
              i18n.language as SupportedLanguagesEnum
            )}
          >
            <Typography variant="body2" sx={{ maxWidth: 300, height: 'fit-content' }}>
              {getTranslationValue(
                row.original.translations,
                'answer',
                i18n.language as SupportedLanguagesEnum
              )}
            </Typography>
          </Tooltip>
        )
      }
    ],
    [all_faqs, t]
  );

  const handleDelete = (data: FAQType) => {
    deleteFAQ(data.id);
  };

  return (
    <Box className="container">
      <PageLayout
        columns={columns}
        data={all_faqs}
        fields={faqFields(t)}
        handleCreate={onSubmit}
        handleDelete={handleDelete}
        title={t('global.faq')}
        subtitle={''}
        addBtnText={t('faq.add_faq')}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={reset}
        translationKey="faq"
        setError={setError}
        handleEdit={handleEdit}
        isSubmitSuccessfull={success}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

export default FAQPage;
