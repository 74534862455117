//type
import {
  ChangeStatusHitListRequestType,
  CreateHitListType,
  HitListType,
  SingleHitListResponseType,
  UpdateHitListType
} from '@interfaces/hitList/hitList';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//api
import {
  changeStatusHitList,
  createHitList,
  deleteHitList,
  updateHitList
} from '@services/hitList/hitList';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreateHitList = (
  onSuccess: (data: HitListType) => void,
  onError: (err: ErrorType<CreateHitListType>) => void
): UseMutationResult<
  SingleHitListResponseType, // response
  ErrorsResponseType<CreateHitListType>,
  FormData, // request
  (data: HitListType) => void // on success
> =>
  useMutation({
    mutationFn: createHitList,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateHitList = (
  onSuccess: (data: HitListType) => void,
  onError: (err: ErrorType<CreateHitListType>) => void
): UseMutationResult<
  SingleHitListResponseType, // response
  ErrorsResponseType<CreateHitListType>,
  UpdateHitListType, // request
  (data: HitListType) => void // on success
> =>
  useMutation({
    mutationFn: updateHitList,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteHitList = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteHitList,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useChangeStatusHitList = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  ChangeStatusHitListRequestType, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: changeStatusHitList,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });
