import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
//components
import { Box, Typography } from '@mui/material';
import PageLayout from '@pages/Page/PageLayout';
import Loading from '@components/Loading/Loading';
//types
import { MRT_ColumnDef } from 'material-react-table';
import { HitListType } from '@interfaces/hitList/hitList';
import { StatusEnum } from '@enum/progressEnum';
//hooks
import { useTranslation } from 'react-i18next';
import { useHitList } from '@hooks/useHitList/useHitList';
//helpers
import { hitListFields } from '@helpers/formFields/formFields';
import { getTypographyColor } from '@helpers/utility';
import dayjs from 'dayjs';

const HitListPage = () => {
  const {
    allLists,
    setValue,
    handleCreate,
    control,
    handleSubmit,
    reset,
    setError,
    success,
    errors,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    rowCount,
    isLoadingAll,
    isSubmitting,
    clearErrors
  } = useHitList();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<HitListType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.name'),
        Cell: ({ row }) => <NavLink to={row.original.id}> {row.original.name} </NavLink>
      },
      {
        accessorKey: 'createdAt',
        header: t('hit_list.created_at'),
        enableColumnFilter: false,
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY.')
      },
      {
        accessorKey: 'songs.length',
        header: t('hit_list.song_number'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'expiredAt',
        header: t('hit_list.form.lasts_to'),
        enableColumnFilter: false,
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY.')
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        filterVariant: 'select',
        filterSelectOptions: [
          { text: t('status.' + StatusEnum.pending), value: StatusEnum.pending },
          { text: t('status.' + StatusEnum.active), value: StatusEnum.active },
          { text: t('status.' + StatusEnum.finished), value: StatusEnum.finished }
        ],

        Cell: ({ cell }) => (
          <Typography variant="body2" sx={{ color: getTypographyColor(cell.getValue<string>()) }}>
            {t('status.' + cell.getValue<string>())}
          </Typography>
        )
      }
    ],
    [allLists, t]
  );
  // if (isLoadingAll) {
  //   return <Loading />;
  // }
  return (
    <Box className="container">
      <PageLayout
        columns={columns}
        data={allLists}
        fields={hitListFields(t, true)}
        handleCreate={handleCreate}
        handleDelete={undefined}
        title={t('hit_list.page_title')}
        subtitle={t('hit_list.page_subtitle')}
        addBtnText={t('hit_list.add_list')}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={reset}
        translationKey="hit_list"
        setError={setError}
        isSubmitSuccessfull={success}
        errors={errors}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        rowCount={rowCount}
        isLoading={isLoadingAll}
        isSubmitting={isSubmitting}
        clearErrors={clearErrors}
      />
    </Box>
  );
};

export default HitListPage;
