import Badge from '@mui/material/Badge';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { useRecoilValue } from 'recoil';
import { notificationCountAtom } from '@atoms/notfications';
import { Box, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NotificationIcon = () => {
  const notificationCount = useRecoilValue(notificationCountAtom);
  const { t } = useTranslation();

  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
      <NotificationsNoneRoundedIcon />
      <ListItemText sx={{ ml: '10px' }} primary={t('sidebar.notifications')} />
      <Badge badgeContent={notificationCount} color="error"></Badge>
    </Box>
  );
};

export default NotificationIcon;
