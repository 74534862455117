import { useMemo } from 'react';
//components
import { NavLink, Navigate } from 'react-router-dom';
import FormModal from '@components/FormModal/FormModal';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TableLayout from '@components/TableLayout/TableLayout';
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import PageLayout from '@pages/Page/PageLayout';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { PodcastChannelType, PodcastType, PodcastTypeEnum } from '@interfaces/podcast/podcast';
//hooks
import { useTranslation } from 'react-i18next';
import { usePodcast } from '@hooks/usePodcast/usePodcast';
import { usePodcastChannels } from '@hooks/usePodcast/usePodcastChannels';
//helpers
import { getChannelKeysName } from '@helpers/profileFields/profileFields';
import { podcastFields } from '@helpers/formFields/formFields';
import dayjs from 'dayjs';

const SingleChannelPage = () => {
  const {
    singleChannel,
    isLoadingSingle,
    popup,
    onDeleteChannelClick,
    isSubmitting: isSubmittingChannel
  } = usePodcastChannels();

  const {
    podcasts,
    rowCount,
    pagination,
    setPagination,
    isLoadingAll: isLoadingPodcasts,
    control,
    handleSubmit,
    setValue,
    setError,
    errors,
    isSuccess,
    reset,
    onSubmit,
    deletePodcast,
    columnFilters,
    setColumnFilters,
    isSubmitting: isSubmittingPodcast
  } = usePodcast();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: keyof PodcastChannelType }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          if (cell.getValue<string>() === 'url') {
            return (
              <NavLink to={singleChannel?.url ?? ''} target="_blank" rel="noopener noreferrer">
                {singleChannel?.url}
              </NavLink>
            );
          }
          if (cell.getValue<string>() === 'publishedAt') {
            return (
              <Typography>
                {singleChannel && dayjs(singleChannel.publishedAt).format('DD.MM.YYYY.')}
              </Typography>
            );
          }
          return (
            <Typography>
              {singleChannel?.[cell.getValue<string>() as keyof PodcastChannelType]?.toString()}
            </Typography>
          );
        }
      }
    ],
    [singleChannel]
  );

  const podcastColums = useMemo<MRT_ColumnDef<PodcastType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        Cell: ({ row }) => (
          <NavLink to={`podcasts/${row.original.id}`}> {row.original.title} </NavLink>
        ),
        enableColumnFilter: false
      },
      {
        accessorKey: 'type',
        header: t('global.type'),
        filterVariant: 'select',
        filterSelectOptions: Object.values(PodcastTypeEnum)
      }
    ],
    [podcasts, t]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleChannel) return <Navigate to={'/error-page'} />;
  return (
    <Box>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          errors={popup.errors}
          isLoading={isSubmittingChannel}
        />
      )}

      <ProfileInfoCard
        title={singleChannel.title}
        profileImage={singleChannel.iconUrl}
        bannerImage={singleChannel.bannerUrl}
        subtitle={t('podcast.channel.page_title')}
        buttons={
          <Box>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                onDeleteChannelClick();
              }}
            >
              {t('global.delete')}
            </Button>
          </Box>
        }
      />
      <Box className="container" pt={0}>
        <Box mb={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
              {t('global.basic_info')}
            </Typography>
          </Box>
          <Typography my={2} variant="body2">
            {singleChannel.description}
          </Typography>
          <TableLayout data={getChannelKeysName(t)} columns={columns} rowCount={0} />
        </Box>

        <Box mt={5}>
          <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
            {t('global.podcasts')}
          </Typography>
          <Box>
            <PageLayout
              columns={podcastColums}
              data={podcasts}
              fields={podcastFields(t)}
              handleCreate={onSubmit}
              handleDelete={p => deletePodcast(p.id)}
              title={''}
              subtitle={t('podcast.page_subtitle')}
              addBtnText={t('podcast.add_podcast')}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              resetForm={reset}
              translationKey="podcast"
              setError={setError}
              isSubmitSuccessfull={isSuccess}
              errors={errors}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={rowCount}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              isLoading={isLoadingPodcasts}
              isSubmitting={isSubmittingPodcast}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleChannelPage;
