import { useEffect, useState } from 'react';
import { hasToken } from '@helpers/utility';
import { useNavigate } from 'react-router-dom';
import { useObserve } from '@hooks/useObserve/useObserve';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { logOut } from '@services/keycloak';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { useTranslation } from 'react-i18next';

export const useAuth = () => {
  const resetUser = useResetRecoilState(userAtom);

  const [isAuth, setIsAuth] = useState(!!hasToken());
  const [isLoadingLogOut, setisLoadingLogOut] = useState(false);
  const user = useRecoilValue(userAtom);
  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogOut = async () => {
    try {
      setisLoadingLogOut(true);
      await logOut();
      logoutEventHandler();
    } catch (err) {
      logoutEventHandler();
      setisLoadingLogOut(false);
    }
  };

  const logoutEventHandler = () => {
    localStorage.removeItem('firebase_token');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');

    setisLoadingLogOut(false);

    resetUser();
    setIsAuth(false);
    navigate('/login');
  };

  useObserve(() => {
    window.addEventListener('logout', logoutEventHandler);

    return () => {
      window.removeEventListener('logout', logoutEventHandler);
    };
  }, []);

  useEffect(() => {
    if (!hasToken() && user !== null) {
      logoutEventHandler();
    } else if (user?.type === null) {
      setErrorPopup({
        title: t('login.no_permissions_message'),
        onClose: () => {
          handleLogOut();
          resetErrorPopup();
        },
        type: 'error'
      });
      navigate('/login');
    }
  }, [user]);

  return {
    isAuth,
    setIsAuth,
    handleLogOut,
    isLoadingLogOut,
    setisLoadingLogOut
  };
};
