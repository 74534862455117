import { useNavigate } from 'react-router-dom';
//mui
import { Box, Typography, Grid, Button } from '@mui/material';
//image
import LoginLayoutImage from '@src/assets/LoginLayoutImage.png';
//icons
import { BeatsIcon } from '@components/icons/icons';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Grid container position={'relative'} height={'99.6vh'}>
      <Grid item xs={12} md={6} height={'100%'} sx={{ zIndex: 1 }}>
        <Box
          px={15}
          display={'flex'}
          height={'100%'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography color={'primary'} variant="h3" my={3} fontWeight={'bold'}>
            Ups...
          </Typography>
          <Typography color={'#7281A0'} variant="h4" my={3}>
            Nismo uspeli da pronadjemo trazenu stranicu
          </Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Nazad
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: { xs: 'block', md: 'block' }, maxHeight: '100%', p: { xs: 0, md: 1 } }}
      >
        <Box
          height={'100%'}
          width={'100%'}
          position={{ xs: 'absolute', md: 'relative' }}
          top={0}
          sx={{ opacity: { xs: '30%', md: '100%' } }}
        >
          <BeatsIcon
            sx={{
              width: '110px',
              height: '125px',
              position: 'absolute',
              left: { xs: '4%', md: '40%' },
              top: { xs: '4%', md: '40%' }
            }}
          />
          <img
            height={'100%'}
            width={'100%'}
            src={LoginLayoutImage}
            style={{ objectFit: 'cover', borderRadius: '8px' }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
