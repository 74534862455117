import { SvgIcon, SvgIconProps } from '@mui/material';

export const BeatsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 109 126" sx={{ fill: 'none' }} {...props}>
      <path
        d="M85.748 22.1451C85.748 17.9358 82.3357 14.5234 78.1265 14.5234C73.9173 14.5234 70.505 17.9358 70.505 22.1451V104.459C70.505 108.668 73.9173 112.081 78.1265 112.081C82.3357 112.081 85.748 108.668 85.748 104.459V22.1451Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M62.8836 8.42634C62.8836 4.21702 59.4713 0.804688 55.2621 0.804688C51.0529 0.804688 47.6406 4.21702 47.6406 8.42634V118.178C47.6406 122.387 51.0529 125.8 55.2621 125.8C59.4713 125.8 62.8836 122.387 62.8836 118.178V8.42634Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M38.4948 22.1451C38.4948 17.9358 35.0825 14.5234 30.8733 14.5234C26.6641 14.5234 23.2518 17.9358 23.2518 22.1451V104.459C23.2518 108.668 26.6641 112.081 30.8733 112.081C35.0825 112.081 38.4948 108.668 38.4948 104.459V22.1451Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M15.6304 36.0591C15.6304 31.8498 12.2182 28.4375 8.00893 28.4375C3.7997 28.4375 0.387451 31.8498 0.387451 36.0591V90.935C0.387451 95.1443 3.7997 98.5567 8.00893 98.5567C12.2182 98.5567 15.6304 95.1443 15.6304 90.935V36.0591Z"
        fill="white"
      />
      <path
        d="M108.612 36.0591C108.612 31.8498 105.2 28.4375 100.991 28.4375C96.7818 28.4375 93.3695 31.8498 93.3695 36.0591V90.935C93.3695 95.1443 96.7818 98.5567 100.991 98.5567C105.2 98.5567 108.612 95.1443 108.612 90.935V36.0591Z"
        fill="white"
      />
      <path
        d="M38.4949 77.2154C38.4949 73.0061 35.0827 69.5938 30.8734 69.5938C26.6642 69.5938 23.252 73.0061 23.252 77.2154V104.653C23.252 108.863 26.6642 112.275 30.8734 112.275C35.0827 112.275 38.4949 108.863 38.4949 104.653V77.2154Z"
        fill="white"
      />
      <path
        d="M62.8836 49.7779C62.8836 45.5686 59.4713 42.1562 55.2621 42.1562C51.0529 42.1562 47.6406 45.5686 47.6406 49.7779V77.2158C47.6406 81.4251 51.0529 84.8375 55.2621 84.8375C59.4713 84.8375 62.8836 81.4251 62.8836 77.2158V49.7779Z"
        fill="white"
      />
      <path
        d="M85.748 77.2154C85.748 73.0061 82.3357 69.5938 78.1265 69.5938C73.9173 69.5938 70.505 73.0061 70.505 77.2154V104.653C70.505 108.863 73.9173 112.275 78.1265 112.275C82.3357 112.275 85.748 108.863 85.748 104.653V77.2154Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const SmallLogo = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 39" sx={{ fill: 'none', width: 30, height: 38 }}>
    <path
      d="M23.6619 7.4284C23.6619 6.14872 22.716 5.11133 21.5492 5.11133C20.3824 5.11133 19.4365 6.14872 19.4365 7.4284V32.4528C19.4365 33.7325 20.3824 34.7699 21.5492 34.7699C22.716 34.7699 23.6619 33.7325 23.6619 32.4528V7.4284Z"
      fill="#304674"
    />
    <path
      d="M17.324 3.25848C17.324 1.9788 16.3781 0.941406 15.2113 0.941406C14.0445 0.941406 13.0986 1.9788 13.0986 3.25848V36.6243C13.0986 37.904 14.0445 38.9414 15.2113 38.9414C16.3781 38.9414 17.324 37.904 17.324 36.6243V3.25848Z"
      fill="#304674"
    />
    <path
      d="M10.5632 7.4284C10.5632 6.14872 9.61737 5.11133 8.45057 5.11133C7.28377 5.11133 6.33789 6.14872 6.33789 7.4284V32.4528C6.33789 33.7325 7.28377 34.7699 8.45057 34.7699C9.61737 34.7699 10.5632 33.7325 10.5632 32.4528V7.4284Z"
      fill="#304674"
    />
    <path
      d="M4.22535 11.6589C4.22535 10.3792 3.27947 9.3418 2.11268 9.3418C0.945877 9.3418 0 10.3792 0 11.6589V28.3418C0 29.6215 0.945877 30.6589 2.11268 30.6589C3.27947 30.6589 4.22535 29.6215 4.22535 28.3418V11.6589Z"
      fill="white"
    />
    <path
      d="M29.9998 11.6589C29.9998 10.3792 29.0539 9.3418 27.8871 9.3418C26.7203 9.3418 25.7744 10.3792 25.7744 11.6589V28.3418C25.7744 29.6215 26.7203 30.6589 27.8871 30.6589C29.0539 30.6589 29.9998 29.6215 29.9998 28.3418V11.6589Z"
      fill="white"
    />
    <path
      d="M10.5632 24.1706C10.5632 22.8909 9.61737 21.8535 8.45057 21.8535C7.28377 21.8535 6.33789 22.8909 6.33789 24.1706V32.5121C6.33789 33.7917 7.28377 34.8291 8.45057 34.8291C9.61737 34.8291 10.5632 33.7917 10.5632 32.5121V24.1706Z"
      fill="white"
    />
    <path
      d="M17.324 15.8307C17.324 14.5511 16.3781 13.5137 15.2113 13.5137C14.0445 13.5137 13.0986 14.5511 13.0986 15.8307V24.1722C13.0986 25.4519 14.0445 26.4893 15.2113 26.4893C16.3781 26.4893 17.324 25.4519 17.324 24.1722V15.8307Z"
      fill="white"
    />
    <path
      d="M23.6619 24.1706C23.6619 22.8909 22.716 21.8535 21.5492 21.8535C20.3824 21.8535 19.4365 22.8909 19.4365 24.1706V32.5121C19.4365 33.7917 20.3824 34.8291 21.5492 34.8291C22.716 34.8291 23.6619 33.7917 23.6619 32.5121V24.1706Z"
      fill="white"
    />
  </SvgIcon>
);

export const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 21" sx={{ fill: 'none', width: 20, height: 21 }}>
    <rect y="0.5" width="20" height="20" rx="10" fill="#304674" />
    <path
      d="M9.99984 2.16602C5.39984 2.16602 1.6665 5.89935 1.6665 10.4993C1.6665 15.0993 5.39984 18.8327 9.99984 18.8327C14.5998 18.8327 18.3332 15.0993 18.3332 10.4993C18.3332 5.89935 14.5998 2.16602 9.99984 2.16602ZM8.33317 14.666L4.1665 10.4993L5.3415 9.32435L8.33317 12.3077L14.6582 5.98268L15.8332 7.16602L8.33317 14.666Z"
      fill="#C5CBD8"
    />
  </SvgIcon>
);
