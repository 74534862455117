// Import the functions you need from the SDKs you need
import {
  VITE_API_KEY,
  VITE_APP_ID,
  VITE_AUTH_DOMAIN,
  VITE_MESSAGING_SENDER_ID,
  VITE_PROJECT_ID,
  VITE_STORAGE_BUCKET,
  VITE_MEASUREMENT_ID
} from '@helpers/env.config';
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: VITE_API_KEY,
  authDomain: VITE_AUTH_DOMAIN,
  projectId: VITE_PROJECT_ID,
  storageBucket: VITE_STORAGE_BUCKET,
  messagingSenderId: VITE_MESSAGING_SENDER_ID,
  appId: VITE_APP_ID,
  measurementId: VITE_MEASUREMENT_ID
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);

export const messaging = async () => {
  try {
    const isSupportedMessaging = await isSupported();
    if (isSupportedMessaging) {
      return getMessaging(firebaseApp);
    }
  } catch (e) {
    console.log('Messages not supported', e);
  }
  return null;
};

const mess = messaging();
