//interfaces
import {
  CreateRadioProgramType,
  CreateRadioType,
  RadioImagesType,
  RadioProgramErrorType,
  RadioProgramType,
  RadioType,
  UpdateRadioType
} from '@interfaces/radio/radio';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//services
import {
  createRadio,
  createRadioProgram,
  deleteRadio,
  deleteRadioImages,
  deleteRadioProgram,
  updateRadio,
  updateRadioImages
} from '@services/radioStation/radioStations';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreateRadioStation = (
  onSuccess: (data: RadioType) => void,
  onError: (err: ErrorType<CreateRadioType>) => void
): UseMutationResult<
  { data: RadioType }, // response
  ErrorsResponseType<CreateRadioType>,
  CreateRadioType, // request
  (data: RadioType) => void // on success
> =>
  useMutation({
    mutationFn: createRadio,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateRadioStation = (
  onSuccess: (data: RadioType) => void,
  onError: (err: ErrorType<CreateRadioType>) => void
): UseMutationResult<
  { data: RadioType }, // response
  ErrorsResponseType<CreateRadioType>,
  UpdateRadioType, // request
  (data: RadioType) => void // on success
> =>
  useMutation({
    mutationFn: updateRadio,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteRadioStation = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteRadio,
    onSuccess: response => {
      onSuccess();
    },
    onError: err => onError(err)
  });

export const useCreateRadioProgram = (
  onSuccess: (data: RadioProgramType[]) => void,
  onError: (err: RadioProgramErrorType) => void
): UseMutationResult<
  { data: RadioProgramType[] }, // response
  { response: { data: RadioProgramErrorType } },
  CreateRadioProgramType, // request
  (data: RadioProgramType[]) => void // on success
> =>
  useMutation({
    mutationFn: createRadioProgram,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response?.data)
  });

export const useDeleteRadioProgram = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteRadioProgram,
    onSuccess: response => {
      onSuccess();
    },
    onError: err => onError(err)
  });

export const useUpdateRadioImages = (
  onSuccess: (data: RadioImagesType) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  { data: RadioImagesType }, // response
  ErrorsResponseType<{}>,
  RadioImagesType, // request
  (data: RadioImagesType) => void // on success
> =>
  useMutation({
    mutationFn: updateRadioImages,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useDeleteRadioImage = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { id: string; type: string[] }, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: req => deleteRadioImages(req.id, req.type),
    onSuccess: response => {
      onSuccess && onSuccess();
    },
    onError: err => onError(err)
  });
