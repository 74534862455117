import { TFunction } from 'i18next';
import { StepType } from '@reactour/tour';
import { Typography, Box } from '@mui/material';

export const getProfileSteps = (t: TFunction, navigate: () => void): StepType[] => {
  return [
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.notifications.title')}
          </Typography>
          <Typography>{t('steps.business.notifications.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="notifications"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.company.title')}
          </Typography>
          <Typography>{t('steps.business.company.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="company"]',
      action: navigate
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.edit_profile.title')}
          </Typography>
          <Typography>{t('steps.business.edit_profile.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="edit-profile-btn"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.edit_banner_img.title')}
          </Typography>
          <Typography>{t('steps.business.edit_banner_img.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="edit-banner-img"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.edit_profile_img.title')}
          </Typography>
          <Typography>{t('steps.business.edit_profile_img.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="edit-profile-img"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.qr_btn.title')}
          </Typography>
          <Typography>{t('steps.business.qr_btn.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="qr-button"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.edit_desc_btn.title')}
          </Typography>
          <Typography>{t('steps.business.edit_desc_btn.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="edit-description-btn"]'
    },
    {
      content: (
        <Box>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('steps.business.jobs_tab.title')}
          </Typography>
          <Typography>{t('steps.business.jobs_tab.subtitle')}</Typography>
        </Box>
      ),
      position: 'bottom',
      selector: '[data-tour="jobs-tab"]'
    }
  ];
};
