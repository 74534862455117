//api
import { useSeenNotification } from '@api/mutations/notification/notification';
import { useAllNotificationsInfinity } from '@api/queries/notifications/notifications';
//hooks
import useLastElementScroll from '@hooks/useLastElementScroll/useLastElementScroll';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//type
import { ErrorsResponseType } from '@interfaces/response';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { NotificationsType } from '@interfaces/notifications/notifications';
import { NotificationOriginEnum } from '@enum/notificationsEnum';
//recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { notificationCountAtom } from '@atoms/notfications';
import { userAtom } from '@atoms/userAtom';
import { RoleEnum } from '@enum/roleEnum';

export function useNotifications() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const setNotificationCount = useSetRecoilState(notificationCountAtom);
  const user = useRecoilValue(userAtom);

  const {
    data: notifications,
    fetchNextPage: fetchNextPageNotifications,
    hasNextPage: hasNextPageNotifications,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
    refetch
  } = useAllNotificationsInfinity(
    i18n.language as SupportedLanguagesEnum,
    (err: ErrorsResponseType<{}>) => {
      console.log('error', err);
    }
  );

  const { lastElementRef } = useLastElementScroll(
    isLoadingNotifications,
    isFetchingNotifications,
    hasNextPageNotifications,
    fetchNextPageNotifications
  );

  const { mutate: seenNotfication } = useSeenNotification(
    (err: ErrorsResponseType<{}>) => {
      console.log('error', err);
    },
    (n: NotificationsType) => {
      refetch();
    }
  );

  const onNotificationClick = (notification: NotificationsType) => {
    if (!notification.seen) {
      seenNotfication(notification.id);
      setNotificationCount(prev => prev - 1);
    }
    if (user?.type === RoleEnum.ADMIN) {
      if (notification.origin === NotificationOriginEnum.VOUCHER)
        navigate('/vouchers/' + notification.externalId);
      else if (notification.origin === NotificationOriginEnum.NEWS)
        navigate('/news/' + notification.externalId);
      else if (notification.origin === NotificationOriginEnum.JOB)
        navigate('/jobs/' + notification.externalId);
    }
  };

  const onSeenNotificationClick = (id: string) => {
    seenNotfication(id);
    setNotificationCount(prev => prev - 1);
  };
  return {
    onNotificationClick,
    seenNotfication,
    lastElementRef,
    notifications: notifications?.pages.flatMap(item => item.data.data) ?? [],
    isLoadingNotifications,
    onSeenNotificationClick
  };
}
