//hooks
import { useGenres } from '@hooks/useGenres/useGenres';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//components
import { NavLink, Navigate } from 'react-router-dom';
import { Box, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
//helpers
import { primaryColor } from '@src/theme';
import dayjs from 'dayjs';
import { getTranslationValue } from '@helpers/utility';
//type
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { MRT_ColumnDef } from 'material-react-table';
import { RadioType } from '@interfaces/radio/radio';

const SingleGenrePage = () => {
  const {
    singleGenre,
    isLoadingSingle,
    radioPagination,
    setRadioPagination,
    radios,
    rowCountRadio
  } = useGenres();
  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<RadioType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.name'),
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue<string>()}>
            <NavLink to={`/radio/${row.original.id}`}>
              <Typography variant="body2">{cell.getValue<string>()}</Typography>
            </NavLink>
          </Tooltip>
        ),
        enableColumnFilter: false
      }
    ],
    [t, radios]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleGenre) return <Navigate to={'/error-page'} replace />;
  return (
    <Box className="container">
      <CardContent sx={{ display: 'flex', columnGap: 2 }}>
        <CardMedia
          component="img"
          sx={{
            width: 120,
            height: 120,
            border: '2px solid ' + primaryColor,
            bgcolor: '#bdbdbd'
          }}
          image={singleGenre?.imageUrl ? singleGenre?.imageUrl + '?' + dayjs().toISOString() : ''}
          alt=""
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Typography component="div" variant="h5">
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {t('global.genre')}
            </Typography>
            {singleGenre &&
              getTranslationValue(
                singleGenre.translations,
                'name',
                i18n.language as SupportedLanguagesEnum
              )}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h5" color="primary" fontWeight={'bold'}>
          {t('interests.genres.radios_with_genre')}
        </Typography>
        <TableLayout
          data={radios}
          columns={columns}
          pagination={radioPagination}
          setPagination={setRadioPagination}
          rowCount={rowCountRadio}
        />
      </CardContent>
    </Box>
  );
};

export default SingleGenrePage;
