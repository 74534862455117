import { useEffect, useState } from 'react';

type Props = {
  detail: { value: string };
};

export function useLocalStorage(
  key: string,
  initialValue: string
): [string, (value: string) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<string>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item || initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  const handleEvent = (e: Props): void => {
    setStoredValue(e.detail.value);
  };

  useEffect(() => {
    // window.addEventListener('update_storage', handleEvent);

    window.addEventListener('update_storage', ((event: CustomEvent) => {
      handleEvent(event);
    }) as EventListener);

    return () => {
      // window.removeEventListener('update_storage', handleEvent);
      window.removeEventListener('update_storage', ((event: CustomEvent) => {
        handleEvent(event);
      }) as EventListener);
    };
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string): void => {
    try {
      // Allow value to be a function so we have same API as useState
      // const valueToStore =
      //   value instanceof Function ? value(storedValue) : value;
      // Save state
      // setStoredValue(value);

      const event = new CustomEvent('update_storage', {
        detail: {
          value
        }
      });

      window.dispatchEvent(event);

      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, value);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}