import { useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
//components
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { JobType } from '@interfaces/job/job';
import { ErrorsResponseType } from '@interfaces/response';
//hooks
import { useCompanyJobs } from '@api/queries/jobs/jobs';
import { useTranslation } from 'react-i18next';
//helpers
import { getTypographyColor } from '@helpers/utility';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';

const SingleBusinessJobsPage = () => {
  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>) => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { companyId } = useParams();
  const { data: jobs, isLoading } = useCompanyJobs(companyId, onError);

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<JobType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        Cell: ({ row }) => <NavLink to={`/jobs/${row.original.id}`}>{row.original.name}</NavLink>
      },
      {
        accessorKey: 'status',
        header: t('global.status'),
        Cell: ({ cell }) => (
          <Typography variant="body2" sx={{ color: getTypographyColor(cell.getValue<string>()) }}>
            {t(`status.${cell.getValue<string>()}`)}
          </Typography>
        )
      }
    ],
    []
  );

  if (isLoading) return <Loading />;

  return (
    <Box className="container">
      <TableLayout columns={columns} data={jobs ? jobs : []} rowCount={jobs?.length} />
    </Box>
  );
};

export default SingleBusinessJobsPage;
