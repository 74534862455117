//interfaces
import { AllPredictionsResponseType, GeoLocationType } from '@interfaces/autocomplete/autocomplete';
//api
import { getGeoInfo } from '@services/autocomplete/autocomplete';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//axios
import { AxiosError } from 'axios';

export const useGeoInfo = (
  onError: (err: AxiosError) => void
): UseMutationResult<
  { data: GeoLocationType }, // response
  AxiosError,
  { placeId: string; language: string }, // request
  (data: GeoLocationType) => void // on success
> =>
  useMutation({
    mutationFn: req => getGeoInfo(req.placeId, req.language),
    onError: err => onError(err)
  });
