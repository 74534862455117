//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRubrics } from '@hooks/useRubrics/useRubrics';
//components
import { Box, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { NavLink, Navigate } from 'react-router-dom';
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
//helpers
import { dayjs, getTranslationValue } from '@helpers/utility';
import { primaryColor } from '@src/theme';
//type
import { NewsType } from '@interfaces/news/news';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';
import { MRT_ColumnDef } from 'material-react-table';

const SingleRubricsPage = () => {
  const { singleRubric, isLoadingSingle, news, newsPagination, setNewsPagination, rowCountNews } =
    useRubrics();
  const { t, i18n } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<NewsType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue<string>()}>
            <NavLink to={`/news/${row.original.id}`}>
              <Typography variant="body2">{cell.getValue<string>()}</Typography>
            </NavLink>
          </Tooltip>
        ),
        enableColumnFilter: false
      },
      {
        accessorKey: 'hashTags',
        header: t('news.hash_tags'),
        Cell: ({ row }) => row.original.hashTags?.join(', '),
        enableColumnFilter: false
      }
    ],
    [news, t]
  );

  if (isLoadingSingle) return <Loading />;
  if (!singleRubric) return <Navigate to={'/error-page'} replace />;
  return (
    <Box>
      <CardContent sx={{ display: 'flex', columnGap: 2 }}>
        <CardMedia
          component="img"
          sx={{
            width: 120,
            height: 120,
            border: '2px solid ' + primaryColor,
            bgcolor: '#bdbdbd'
          }}
          image={singleRubric.imageUrl ? singleRubric.imageUrl + '?' + dayjs().toISOString() : ''}
          alt=""
        />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Typography component="div" variant="h5">
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {t('global.rubrics')}
            </Typography>
            {getTranslationValue(
              singleRubric.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            )}
          </Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h5" color="primary" fontWeight={'bold'}>
          {t('interests.rubrics.news_with_rubric')}
        </Typography>
        <TableLayout
          data={news}
          columns={columns}
          pagination={newsPagination}
          setPagination={setNewsPagination}
          rowCount={rowCountNews}
        />
      </CardContent>
    </Box>
  );
};

export default SingleRubricsPage;
