import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ErrorPopupPropsType = {
  title: string;
  onClose: () => void;
};

const ErrorPopup = ({ title, onClose }: ErrorPopupPropsType) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={true}
      sx={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255,255,255, 0.2)' }}
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255,255,255, .5)' } }
      }}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {t('global.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorPopup;
