import { NavLink, Navigate } from 'react-router-dom';
import { useMemo } from 'react';
//components
import FormModal from '@components/FormModal/FormModal';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { HitListSongsType, HitListType } from '@interfaces/hitList/hitList';
import { StatusEnum } from '@enum/progressEnum';
//hooks
import { useHitList } from '@hooks/useHitList/useHitList';
import { useTranslation } from 'react-i18next';
//helpers
import { getHitListKeys } from '@helpers/profileFields/profileFields';
//theme
import theme from '@src/theme';
import { getTypographyColor, secondsToMinutes } from '@helpers/utility';

const SingleHitListPage = () => {
  const { singleList, popup, errors, onEditClick, onDeleteClick, onStatusChange, isLoadingSingle } =
    useHitList();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<{ label: string; key: keyof HitListType }>[]>(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          if (cell.getValue<string>() === 'status') {
            return (
              <Select
                value={singleList?.status}
                size="small"
                sx={{
                  width: '100%',
                  color: singleList && getTypographyColor(singleList?.status.toString())
                }}
                onChange={onStatusChange}
              >
                {singleList?.status === StatusEnum.pending && (
                  <MenuItem value={StatusEnum.active} sx={{ color: theme.palette.success.main }}>
                    {t('status.active')}
                  </MenuItem>
                )}
                {singleList?.status === StatusEnum.active && (
                  <MenuItem value={StatusEnum.finished} sx={{ color: theme.palette.error.main }}>
                    {t('status.finished')}
                  </MenuItem>
                )}
                <MenuItem
                  value={singleList?.status}
                  sx={{ color: singleList && getTypographyColor(singleList.status) }}
                >
                  {t('status.' + singleList?.status)}
                </MenuItem>
              </Select>
            );
          }
          return (
            <Typography>
              {singleList?.[cell.getValue<string>() as keyof HitListType]?.toString()}
            </Typography>
          );
        }
      }
    ],
    [singleList]
  );

  const songsColumns = useMemo<MRT_ColumnDef<HitListSongsType>[]>(
    () => [
      {
        accessorKey: 'title',
        header: t('global.title'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'duration',
        header: t('global.duration'),
        enableColumnFilter: false,
        Cell: ({ cell }) => secondsToMinutes(cell.getValue<number>())
      },
      {
        accessorKey: 'singer',
        header: t('global.author'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'votes',
        header: t('hit_list.vote_number'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'link',
        header: t('global.link'),
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <NavLink to={cell.getValue<string>()} target="_blank" rel="noopener noreferrer">
            {cell.getValue<string>()}
          </NavLink>
        )
      }
    ],
    [singleList]
  );

  if (isLoadingSingle) {
    return <Loading />;
  }
  if (!singleList) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          errors={errors}
        />
      )}

      <Box>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('hit_list.page_title')}
        </Typography>
        <Typography variant="h4" fontWeight={'bold'}>
          {singleList.name}
        </Typography>
      </Box>

      <Box mt={2}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
            {t('global.basic_info')}
          </Typography>
          {singleList.status === StatusEnum.pending && (
            <Box>
              <Button variant="contained" size="small" sx={{ mx: 2 }} onClick={() => onEditClick()}>
                {t('global.edit')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  onDeleteClick();
                }}
              >
                {t('global.delete')}
              </Button>
            </Box>
          )}
        </Box>
        <TableLayout data={getHitListKeys(t)} columns={columns} rowCount={0} />
      </Box>

      <Box mt={2}>
        <Typography color={'primary'} variant="h5" fontWeight={'bold'}>
          {t('hit_list.form.song_list')}
        </Typography>

        <TableLayout data={singleList.songs ?? []} columns={songsColumns} rowCount={0} />
      </Box>
    </Box>
  );
};

export default SingleHitListPage;
