//components
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loading from '@components/Loading/Loading';
//hooks
import { useCountries } from '@api/queries/autocomplete/autocomplete';
import { Path, PathValue, UseFormSetValue } from 'react-hook-form';
//type
import { CountryLocalizedType, PredictionType } from '@interfaces/autocomplete/autocomplete';
import { useEffect, useState } from 'react';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorsResponseType } from '@interfaces/response';

type CountriesAutocompleteInputFieldProps<T extends {}> = {
  setValue: UseFormSetValue<T>;
  invalid: boolean;
  label: string;
  type: AutpocompleteTypeEnum;
  error?: string;
  defaultValue?: CountryLocalizedType | CountryLocalizedType[];
  required?: boolean;
  multiple?: boolean;
};

const CountriesAutocompleteField = <T extends {}>({
  setValue,
  invalid,
  error,
  label,
  type,
  required,
  multiple,
  defaultValue
}: CountriesAutocompleteInputFieldProps<T>) => {
  const [countrySearch, setCountrySearch] = useState<string>('');

  const { i18n } = useTranslation();

  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>): void => {
    setErrorPopup({
      title: err.message,
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { data: suggestedCountries, isLoading: isLoadingSuggestion } = useCountries(
    type === 'country',
    onError,
    {
      search: countrySearch ?? '',
      lang: i18n.language
    }
  );

  const handleSearchChange = (event: React.SyntheticEvent, value: string) => {
    setCountrySearch(value);
    if (!value && !multiple) {
      setValue('newCountryAlpha2' as Path<T>, undefined as PathValue<T, Path<T>>);
    }
  };

  const handleInputChange = (
    e: React.SyntheticEvent,
    data: (CountryLocalizedType | string)[] | CountryLocalizedType | null | string
  ) => {
    if (typeof data !== 'string' && !Array.isArray(data) && data?.name && data?.alpha2) {
      setValue('newCountryAlpha2' as Path<T>, data.alpha2 as PathValue<T, Path<T>>);
    }
    if (Array.isArray(data)) {
      const val = data.map(el => (typeof el !== 'string' ? el.id : el));
      setValue('countries' as Path<T>, val as PathValue<T, Path<T>>);
    }
  };

  return (
    <Autocomplete
      multiple={multiple}
      defaultValue={defaultValue}
      freeSolo
      id="autocomplte_basic_data"
      options={suggestedCountries?.data ? suggestedCountries.data : []}
      loading={countrySearch === '' ? false : isLoadingSuggestion}
      loadingText={<Loading />}
      inputValue={countrySearch}
      onChange={handleInputChange}
      getOptionLabel={(option: CountryLocalizedType | string) =>
        typeof option !== 'string' ? option.name : option
      }
      onInputChange={handleSearchChange}
      isOptionEqualToValue={(option: CountryLocalizedType, value: CountryLocalizedType) =>
        option.name === value.name
      }
      filterOptions={(options, state) => options}
      noOptionsText={'no options'}
      renderInput={params => (
        <TextField
          InputProps={params.InputProps}
          inputProps={params.inputProps}
          label={label}
          error={invalid}
          helperText={error}
          required={required}
        />
      )}
    />
  );
};

export default CountriesAutocompleteField;
