import React from 'react';
//components
import { Box, Button, Grid } from '@mui/material';
import DataImageCard from '@components/DataImageCard/DataImageCard';
import FormModal from '@components/FormModal/FormModal';
//hooks
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBusinessUserJobs } from '@hooks/useBusinesUserJobs/useBusinessUserJobs';
import dayjs from 'dayjs';

const BusinessUserJobsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { popup, jobs, onCreateJobClick, onDeleteJobClick, onEditJobClick } = useBusinessUserJobs();

  return (
    <Box>
      {popup && <FormModal {...popup} />}
      <Box mb={2}>
        <Button variant="contained" onClick={onCreateJobClick}>
          {t('user.create_job')}
        </Button>
      </Box>
      <Grid container spacing={2}>
        {jobs.map(job => (
          <Grid item xs={12} sm={6} md={4} key={job.id}>
            <DataImageCard
              title={job.name}
              text={job.description}
              handleDelete={() => {
                onDeleteJobClick(job.id);
              }}
              handleEdit={() => {
                onEditJobClick(job);
              }}
              chip={t(`status.${job.status}`)}
              subtitle={`${job.type} • ${job.location}`}
              onCardClick={() => navigate(job.id)}
              image={job.heroImage + `?${dayjs().toISOString()}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BusinessUserJobsPage;
