//type
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
import {
  SliderType,
  CreateSliderType,
  UpdateSliderType,
  SliderItemType,
  CreateSliderItemType
} from '@interfaces/sliders/sliders';
//api
import {
  createSlider,
  createSliderItem,
  deleteSlider,
  deleteSliderItem,
  updateSlider
} from '@services/sliders/sliders';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';


export const useCreateSlider = (
  onSuccess: (data: SliderType) => void,
  onError: (err: ErrorType<CreateSliderType>) => void
): UseMutationResult<
  { data: SliderType }, // response
  ErrorsResponseType<CreateSliderType>,
  CreateSliderType, // request
  (data: SliderType) => void // on success
> =>
  useMutation({
    mutationFn: createSlider,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateSlider = (
  onError: (err: ErrorType<CreateSliderType>) => void
): UseMutationResult<
  { data: SliderType }, // response
  ErrorsResponseType<CreateSliderType>,
  UpdateSliderType, // request
  (data: SliderType) => void // on success
> =>
  useMutation({
    mutationFn: updateSlider,
    onError: err => onError(err.response.data)
  });

export const useDeleteSlider = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteSlider,
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });

export const useCreateSliderItem = (
  onError: (err: ErrorType<CreateSliderItemType>) => void
): UseMutationResult<
  { data: SliderItemType }, // response
  ErrorsResponseType<CreateSliderItemType>,
  CreateSliderItemType, // request
  (data: SliderType) => void // on success
> =>
  useMutation({
    mutationFn: createSliderItem,
    onError: err => onError(err.response.data)
  });

export const useDeleteSliderItem = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteSliderItem,
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });
