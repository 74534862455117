import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';
import i18next from 'i18next';
//components
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import LanguagePicker from '@components/LanguagePicker/LanguagePicker';
//image
import LoginLayoutImage from '@src/assets/LoginLayoutImage.png';
//icons
import { BeatsIcon } from '@components/icons/icons';
//helpers
import { getLanguage, isProdMode } from '@helpers/utility';
import ErrorPopup from '@components/ErrorPopup/ErrorPopup';
import { useRecoilValue } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { VITE_RECAPTCHA_KEY } from '@helpers/env.config';

const GetWrapper = ({ children }: { children: ReactNode }) => {
  if (isProdMode()) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={VITE_RECAPTCHA_KEY}>
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  console.log('Recaptcha is disabled');
  return <>{children}</>;
};

const LoginLayout: React.FC = (): JSX.Element => {
  const [editLanguage, setEditLanguage] = useState<boolean>(false);

  const errorPopup = useRecoilValue(infoPopupAtom);

  return (
    <GetWrapper>
      <Grid
        container
        position={'relative'}
        height={{ xs: 'fit-content', md: '100vh' }}
        minHeight={'100vh'}
      >
        {errorPopup && <ErrorPopup title={errorPopup.title} onClose={errorPopup.onClose} />}

        <LanguagePicker open={editLanguage} onClose={() => setEditLanguage(false)} />
        <Grid item xs={12} md={6} height={'100%'} sx={{ zIndex: 1 }} minHeight={'100vh'}>
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: { xs: 5, md: '50%' }
            }}
          >
            <ListItem
              className={'language-card active'}
              sx={{ mb: 1, cursor: 'pointer', p: { xs: '0rem 1rem' } }}
              onClick={() => setEditLanguage(true)}
            >
              <ListItemAvatar>
                <Avatar src={getLanguage(i18next.language)?.icon}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={getLanguage(i18next.language)?.label} />
            </ListItem>
          </Box>

          <Outlet />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: '100%',
            p: { xs: 0, md: 1 },
            width: '100%',
            overflowY: 'clip'
          }}
          position={{ xs: 'absolute', md: 'relative' }}
          top={0}
        >
          <Box height={'100%'} width={'100%'} sx={{ opacity: { xs: '30%', md: '100%' } }}>
            <BeatsIcon
              sx={{
                width: '110px',
                height: '125px',
                position: 'absolute',
                left: { xs: '4%', md: '40%' },
                top: { xs: '4%', md: '40%' }
              }}
            />
            <Box
              component={'img'}
              height={'100%'}
              width={'100%'}
              src={LoginLayoutImage}
              sx={{ objectFit: 'cover', borderRadius: '8px', opacity: { xs: 0.2, md: 1 } }}
            />
          </Box>
        </Grid>
      </Grid>
    </GetWrapper>
  );
};

export default LoginLayout;
