//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//type
import {
  CreateFAQRequestType,
  FAQType,
  SingleFAQResponseType,
  UpdateFAQRequestType
} from '@interfaces/faq/faq';
//api
import { createFAQ, deleteFAQ, updateFAQ } from '@services/faq/faq';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';

export const useCreateFAQ = (
  onSuccess: (data: FAQType) => void,
  onError: (err: ErrorType<CreateFAQRequestType>) => void
): UseMutationResult<
  SingleFAQResponseType, // response
  ErrorsResponseType<CreateFAQRequestType>,
  CreateFAQRequestType, // request
  (data: FAQType) => void // on success
> =>
  useMutation({
    mutationFn: createFAQ,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateFAQ = (
  onSuccess: (data: FAQType) => void,
  onError: (err: ErrorType<CreateFAQRequestType>) => void
): UseMutationResult<
  SingleFAQResponseType, // response
  ErrorsResponseType<CreateFAQRequestType>,
  UpdateFAQRequestType, // request
  (data: FAQType) => void // on success
> =>
  useMutation({
    mutationFn: updateFAQ,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteFAQ = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteFAQ,
    onSuccess: data => onSuccess(),
    onError: err => onError(err)
  });
