import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
//components
import { Box, Button, Typography } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import TableLayout from '@components/TableLayout/TableLayout';
//icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
//type
import { MRT_PaginationState, MRT_ColumnFiltersState, MRT_ColumnDef } from 'material-react-table';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form';
import { MappedFieldsType } from '@interfaces/modalForm';
import { useTranslation } from 'react-i18next';

type PageLayoutPropsType<T extends Object, K extends Object> = {
  columns: MRT_ColumnDef<T>[];
  data: T[];
  fields: MappedFieldsType[];
  handleCreate: (data: K) => void;
  handleDelete: undefined | ((data: T) => void);
  title: string;
  subtitle: string;
  addBtnText: string;
  translationKey: string;
  control: Control<K>;
  handleSubmit: UseFormHandleSubmit<K>;
  setValue: UseFormSetValue<K>;
  resetForm: UseFormReset<K>;
  setError?: UseFormSetError<K>;
  handleEdit?: (data: T) => void;
  isSubmitSuccessfull?: boolean;
  errors?: FieldErrors<K>;
  rowCount?: number;
  pagination?: MRT_PaginationState;
  setPagination?: Dispatch<SetStateAction<MRT_PaginationState>>;
  columnFilters?: MRT_ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<MRT_ColumnFiltersState>>;
  isLoading: boolean;
  isSubmitting?: boolean;
  clearErrors?: UseFormClearErrors<K>;
};

const PageLayout = <T extends Object, K extends Object>({
  columns,
  data,
  fields,
  handleCreate,
  handleDelete,
  title,
  subtitle,
  addBtnText,
  control,
  handleSubmit,
  setValue,
  resetForm,
  translationKey,
  setError,
  handleEdit,
  isSubmitSuccessfull,
  errors,
  pagination,
  setPagination,
  columnFilters,
  setColumnFilters,
  rowCount,
  isLoading,
  isSubmitting,
  clearErrors
}: PageLayoutPropsType<T, K>) => {
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [openDeleteForm, setOpenDeleteForm] = useState<T | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (isSubmitSuccessfull) {
      setOpenCreateForm(false);
    }
  }, [isSubmitSuccessfull]);

  const onSubmit = (data: K) => {
    handleCreate(data);
  };

  const onCancel = () => {
    resetForm();
    setOpenCreateForm(false);
  };

  const onEdit = (row: T) => {
    if (handleEdit) {
      handleEdit(row);
      setOpenCreateForm(true);
    }
  };

  return (
    <Box>
      <FormModal
        title={t(`${translationKey}.add_form_title`)}
        open={openCreateForm}
        control={control}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit(onSubmit)}
        setValue={setValue}
        buttonText={t('global.accept')}
        variant="form"
        setError={setError}
        errors={errors}
        isLoading={isSubmitting}
        clearErrors={clearErrors}
      />
      <FormModal
        title={t(`global.delete_title`, { object: t(`delete.${translationKey}`) })}
        subtitle={t(`global.delete_subtitle`, { object: t(`delete.${translationKey}`) })}
        open={!!openDeleteForm}
        fields={[]}
        onCancel={() => setOpenDeleteForm(null)}
        onSubmit={() => {
          if (openDeleteForm && handleDelete) handleDelete(openDeleteForm);
          setOpenDeleteForm(null);
        }}
        buttonText={t('global.accept')}
        variant="delete"
        isLoading={isSubmitting}
      />

      <Box mt={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {title}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {subtitle}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={2} mb={2}>
        <Button variant="outlined" onClick={() => setOpenCreateForm(true)}>
          {addBtnText} <AddRoundedIcon />
        </Button>
      </Box>
      <TableLayout
        columns={columns}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={rowCount ? rowCount : data.length}
        setColumnFilters={setColumnFilters}
        columnFilters={columnFilters}
        handleDelete={handleDelete ? row => setOpenDeleteForm(row) : undefined}
        handleEdit={handleEdit ? onEdit : undefined}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default PageLayout;
