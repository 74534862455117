import { CreateNewsType, NewsType } from '@interfaces/news/news';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';
//services
import {
 
  deleteNewsImages,
  editNews
} from '@services/news/news';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useEditNews = (
  onSuccess: (data: NewsType) => void,
  onError: (err: ErrorType<CreateNewsType>) => void
): UseMutationResult<
  { data: NewsType }, // response
  ErrorsResponseType<CreateNewsType>,
  { id: string; data: FormData }, // request
  (data: NewsType) => void // on success
> =>
  useMutation({
    mutationFn: editNews,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteNewsImage = (
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess?: () => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  { id: string; type: string[] }, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: req => deleteNewsImages(req.id, req.type),
    onSuccess: response => {
      onSuccess && onSuccess();
    },
    onError: err => onError(err)
  });
