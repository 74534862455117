//components
import { Box, Typography } from '@mui/material';
import PageLayout from '@pages/Page/PageLayout';
//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGenres } from '@hooks/useGenres/useGenres';
import { NavLink } from 'react-router-dom';
import Loading from '@components/Loading/Loading';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { InterestsType } from '@interfaces/interests/interests';
//helpers
import { interestsFields } from '@helpers/formFields/formFields';
import { dayjs, getTranslationValue } from '@helpers/utility';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const GenresPage = () => {
  const { t, i18n } = useTranslation();

  const {
    genres,
    onSubmitGenre,
    deleteGenre,
    isLoadingGenres,
    isSuccessGenres,
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    errors,
    isSubmitting
  } = useGenres();

  const columns = useMemo<MRT_ColumnDef<InterestsType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('global.title'),
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <NavLink to={row.original.id}>
            {getTranslationValue(
              row.original.translations,
              'name',
              i18n.language as SupportedLanguagesEnum
            )}
          </NavLink>
        )
      },
      {
        accessorKey: 'imageURL',
        header: '',
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <img
            src={row.original.imageUrl ? row.original.imageUrl + '?' + dayjs().toISOString() : ''}
            height={50}
          />
        )
      }
    ],
    [t, genres]
  );

  return (
    <Box display={'flex'} flexDirection={'column'} className="container">
      <Typography variant="h5" fontWeight={'bold'}>
        {t('interests.genres.title')}
      </Typography>

      <PageLayout
        title=""
        subtitle={t('interests.genres.subtitle')}
        addBtnText={t('interests.genres.add_btn')}
        translationKey="interests.genres"
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={() => {
          reset({
            translations: {
              sr: { name: '' },
              en: { name: '' },
              de: { name: '' },
              fr: { name: '' }
            },
            file: '',
            id: undefined
          });
        }}
        columns={columns}
        fields={interestsFields(t)}
        data={genres}
        handleCreate={onSubmitGenre}
        handleDelete={data => {
          deleteGenre(data.id);
        }}
        handleEdit={data => {
          reset({ ...data, file: data.imageUrl });
        }}
        setError={setError}
        isSubmitSuccessfull={isSuccessGenres}
        errors={errors}
        isLoading={isLoadingGenres}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};
export default GenresPage;
