// store
import { atom } from 'recoil';

type PopupAtomType = {
  title: string;
  onClose: () => void;
  type: 'error' | 'success';
};

const infoPopupAtom = atom<PopupAtomType | null>({
  key: 'infoPopupAtomState',
  default: null
});

export { infoPopupAtom };
