import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { getColorByStatus } from '@helpers/utility';
import { grey } from '@mui/material/colors';
import { ImageOutlined } from '@mui/icons-material';

type DataImageCardPropsType = {
  title: string;
  text: string;
  chip: string;
  handleEdit: Function;
  handleDelete: Function;
  subtitle?: string;
  onCardClick?: () => void;
  image?: string | null;
};

const DataImageCard = ({
  title,
  text,
  chip,
  handleEdit,
  handleDelete,
  subtitle,
  onCardClick,
  image
}: DataImageCardPropsType) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardActionArea onClick={() => onCardClick && onCardClick()}>
        {image && (
          <CardMedia
            component={'img'}
            image={image}
            alt="image"
            height={200}
            sx={{ bgcolor: grey[100] }}
          />
        )}

        {!image && (
          <Box
            sx={{
              bgcolor: grey[100],
              color: grey[600],

              width: '100%',
              height: 200
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <ImageOutlined fontSize="large" />
          </Box>
        )}

        <CardContent>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography variant="h5" fontWeight={'bold'}>
                {title}
              </Typography>
              <Typography variant="subtitle2" color={'text.secondary'}>
                {subtitle}
              </Typography>
            </Box>
            <Chip label={chip} color={getColorByStatus(chip)} variant="outlined" />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {text.slice(0, 135) + '...'}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button variant="contained" size="small" onClick={() => handleEdit()}>
          {t('global.edit')}
        </Button>
        <Button variant="contained" color="error" size="small" onClick={() => handleDelete()}>
          {t('global.delete')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default DataImageCard;
