import { CouponStatusEnum, StatusEnum } from '@enum/progressEnum';
import {
  AllCouponsResponseType,
  AllVouchersResponseType,
  SingleVoucherResponseType,
  UpdateVoucherType,
  VoucherType
} from '@interfaces/vouchers/vouchers';
import axiosInstance from '@services/config';

export const getAllVoucher = (pagination?: {
  page: number;
  take: number;
  status?: StatusEnum;
  search?: string;
  interestId?: string;
  lang?: string;
}): Promise<{ data: AllVouchersResponseType }> =>
  axiosInstance.get('v2/vouchers/pageable', {
    params: { ...pagination, order: 'DESC' }
  });

export const createVoucher = (data: {
  data: FormData;
  companyId: string;
}): Promise<SingleVoucherResponseType> => {
  return axiosInstance.post('v1/vouchers/' + data.companyId, data.data);
};

export const updateVoucher = (data: UpdateVoucherType): Promise<SingleVoucherResponseType> => {
  return axiosInstance.patch('v1/vouchers/' + data.id, data.data);
};

export const deleteVoucher = (id: string): Promise<{}> => {
  return axiosInstance.delete('v1/vouchers/' + id);
};

export const getSingleVoucher = (id: string, lang?: string): Promise<SingleVoucherResponseType> => {
  return axiosInstance.get('v1/vouchers/not-localized/' + id, { params: { lang } });
};

export const approveVoucher = (id: string): Promise<SingleVoucherResponseType> => {
  return axiosInstance.post('v1/vouchers/approve/' + id);
};

export const declineVoucher = (id: string): Promise<SingleVoucherResponseType> => {
  return axiosInstance.post('v1/vouchers/decline/' + id);
};

export const getAllActiveVouchers = (): Promise<{ data: VoucherType[] }> => {
  return axiosInstance.get('v1/vouchers/active');
};

export const deleteVoucherImages = (id: string, type: string[]): Promise<{}> =>
  axiosInstance.delete(`v1/vouchers/${id}/images?type=${type}`);

export const getAllCouponsForVoucher = (
  id: string,
  pagination?: {
    page: number;
    take: number;
    status?: CouponStatusEnum;
  }
): Promise<{ data: AllCouponsResponseType }> =>
  axiosInstance.get('v1/coupons/voucher/' + id, {
    params: { ...pagination, order: 'DESC' }
  });

export const getAllVoucherForBusinessUser = (pagination?: {
  page: number;
  take: number;
  status?: StatusEnum;
  search?: string;
  interestId?: string;
  lang?: string;
}): Promise<{ data: AllVouchersResponseType }> =>
  axiosInstance.get('v2/vouchers/business/pageable', {
    params: { ...pagination, order: 'DESC' }
  });
