//router
import { RouterProvider } from 'react-router-dom';
import { routerConfig } from './router/routerConfig';
//localization
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//style
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import '@styles/global.scss';
//recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userAtom } from '@atoms/userAtom';
//type
import { User } from '@interfaces/users/users';
import { ErrorType } from '@interfaces/response';
//api
import { useUserMutation } from '@api/mutations/user/user';
//helpers
import { hasToken } from '@helpers/utility';
import useFcmToken from '@helpers/firebase/useFcmToken';
import { useObserve } from '@hooks/useObserve/useObserve';
import { useState } from 'react';
import Loading from '@components/Loading/Loading';

function App() {
  const [user, setUser] = useRecoilState(userAtom);
  const [loading, setLoading] = useState(true);

  const {} = useFcmToken();

  const onSuccessUserResponse = (data: User) => {
    setUser(data);
    setLoading(false);
  };

  const onErrorUserResponse = (err: ErrorType<{}>) => {
    setUser(null);
    setLoading(false);
  };

  const { mutate: refetchUser } = useUserMutation(onSuccessUserResponse, onErrorUserResponse);

  useObserve(() => {
    if (!user && hasToken()) {
      setLoading(true);

      refetchUser('');
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={routerConfig} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
