import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SoonPage = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      {' '}
      <Typography variant="h4" fontWeight={'bold'} color={'primary'}>
        {t('global.soon')}
      </Typography>
    </div>
  );
};

export default SoonPage;
