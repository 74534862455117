import React, { useState, useEffect } from 'react';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { fetchToken } from '@services/keycloak';
//components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Loading from '@components/Loading/Loading';
//types
import { LoginRequestErrorType, LoginRequestType } from '@interfaces/login';
import FormModal, { FormModalPropsType } from '@components/FormModal/FormModal';
import { User } from '@interfaces/users/users';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//translation
import { useTranslation } from 'react-i18next';
//hook
import { useLocalStorage } from '@hooks/localStorage/useLocalStorage';
import { useResendVerify, useUserMutation } from '@api/mutations/user/user';
//helpers
import { CLIENT_ID, ISSUER, REDIRECT_URL } from '@helpers/env.config';
//recoil
import { userAtom } from '@atoms/userAtom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { queryClient } from '@src/main';

const LoginPage: React.FC = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState<FormModalPropsType<{}> | null>(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [, setToken] = useLocalStorage('token', '');

  const { t } = useTranslation();

  const [user, setUser] = useRecoilState(userAtom);

  const setErrorPopup = useSetRecoilState(infoPopupAtom);
  const resetErrorPopup = useResetRecoilState(infoPopupAtom);

  const searchParams = new URLSearchParams(window.location.search);
  const verify = searchParams.get('verify');
  const email = searchParams.get('email');

  const { control, handleSubmit, setError } = useForm<LoginRequestType>({
    defaultValues: {
      email: localStorage.getItem('login_email') || '',
      password: '',
      remember: localStorage.getItem('login_email') ? true : false
    }
  });

  const onSuccess = (res: any): void => {
    setPopup(null);
    setLoading(false);
  };

  const onError = (err: ErrorsResponseType<{}>): void => {
    setErrorPopup({
      title: t('global.error_message'),
      onClose: () => resetErrorPopup(),
      type: 'error'
    });
  };

  const { mutate: resendVerify } = useResendVerify(onSuccess, onError);

  const sendVerify = (emailVerify: string): void => {
    resendVerify({
      email: emailVerify,
      clientId: CLIENT_ID,
      redirectUri: `${REDIRECT_URL}/login`
    });
  };

  const onSuccessUserResponse = (data: User) => {
    setUser(data);

    setLoading(false);

    navigate('/profile');
  };

  const onErrorUserResponse = (err: ErrorType<{}>) => {
    navigate('/error-page');
  };

  const { mutate: refetchUser } = useUserMutation(
    onSuccessUserResponse,
    onErrorUserResponse
  );

  const onSubmit = async (data: LoginRequestType) => {
    try {
      setLoading(true);
      const token = await fetchToken({ username: data.email, password: data.password });

      if (token) {

        setToken(token);

        window.dispatchEvent(new Event('login'));

        if (data.remember) {
          localStorage.setItem('login_email', data.email);
        } else {
          localStorage.removeItem('login_email');
        }

       refetchUser('');
      }
    } catch (err) {
      setLoading(false);

      const error = err as LoginRequestErrorType;

      if (
        error?.response?.data &&
        error.response.data.error_description == 'Account is not fully set up'
      ) {
        setPopup({
          title: t('verify_email.title'),
          subtitle: t('verify_email.description'),
          open: true,
          fields: [],
          onCancel: onCancel,
          onSubmit: () => sendVerify(data.email),
          variant: 'info',
          buttonText: t('verify_email.button_title')
        });
      } else {
        setError('email', {
          type: 'custom',
          message: error.response.data.error_description
        });
        setError('password', {
          type: 'custom',
          message: error.response.data.error_description
        });
      }
    }
  };

  const onCancel = () => {
    setPopup(null);
  };

  useEffect(() => {
    if (verify && email) {
      setPopup({
        title: t('verify_email.title'),
        subtitle: t('verify_email.description_register'),
        open: true,
        fields: [],
        onCancel: onCancel,
        onSubmit: () => sendVerify(email),
        variant: 'info',
        buttonText: t('verify_email.button_title')
      });
    }
  }, []);

  if (loading) return <Loading />;
  if (user) return <Navigate to={'/profile'} replace />;
  return (
    <>
      <Box
        px={{ xs: 1, md: 5 }}
        display={'flex'}
        alignItems={'center'}
        height={'100vh'}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color={'primary'} variant="h3" p={1} fontSize={'clamp(2rem, 3vw, 2.5rem)'}>
              {t('login.title')} <br />
              <b>W - Media Group</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  label={t('global.email') + ' / ' + t('global.username')}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                  name="username"
                  placeholder=""
                  InputLabelProps={{ shrink: true }}
                  autoComplete="on"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid}>
                  <InputLabel error={invalid} shrink>
                    {t('global.password')}
                  </InputLabel>
                  <OutlinedInput
                    label={t('global.password')}
                    notched
                    type={showPassword ? 'text' : 'password'}
                    placeholder=""
                    {...field}
                    name="password"
                    error={invalid}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? (
                            <VisibilityOff color="primary" />
                          ) : (
                            <Visibility color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText color="error">{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Controller
                name="remember"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} color="primary" name="remember" checked={field.value} />
                )}
              />
              <FormLabel color="primary" focused sx={{ fontWeight: 'bold' }}>
                {t('global.remember_me')}
              </FormLabel>
            </Box>
            <NavLink
              to={`${ISSUER}/login-actions/reset-credentials?redirectUrl=${REDIRECT_URL}/login`}
              className={'link'}
            >
              {t('login.login_page.forgot_password')}
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" size="large" type="submit" sx={{ py: '.5rem' }}>
              {t('login.login_page.log_in')}
            </Button>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
            <NavLink to={'/register'} className={'link'}>
              {t('login.login_page.new_account')}
            </NavLink>
          </Grid>
        </Grid>
      </Box>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          fields={popup.fields}
          onCancel={() => {
            popup.onCancel();
            setPopup(null);
          }}
          onSubmit={popup.onSubmit}
          buttonText={popup.buttonText}
          variant={popup.variant}
        />
      )}
    </>
  );
};

export default LoginPage;
