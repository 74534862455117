import { RegisterRequestType, ResendVerifyType } from '@interfaces/login';
import {
  ChangePasswordRequestType,
  UpdateUserRequestType,
  User,
  AllUsersType,
  UserInfo,
  UserKeycloak,
  CreateUserRequestType,
  ExportUsersParamsType
} from '@interfaces/users/users';
import axiosInstance from '@services/config';
import routes from '@services/keycloak/routes';
import { InterestsLocalizedType } from '@interfaces/interests/interests';

export const me = (): Promise<{ data: UserInfo }> => {
  return axiosInstance.get('v1/users/me');
};

export const meKeycloak = (): Promise<{ data: UserKeycloak }> => {
  return axiosInstance.get(routes.me);
};

export const register = async (data: RegisterRequestType): Promise<{ data: User }> => {
  const reCaptchaToken = data.reCaptchaToken;
  axiosInstance.defaults.headers['recaptcha'] = reCaptchaToken || null;
  delete data.reCaptchaToken;
  return axiosInstance.post(`v1/users/business`, data);
};

export const resendVerify = async (data: ResendVerifyType): Promise<{ data: User }> => {
  return axiosInstance.post('v1/users/resend-verify', data);
};

export const updateUser = async (data: UpdateUserRequestType): Promise<{ data: User }> => {
  return axiosInstance.patch('v1/users/' + data.id, data.data);
};

export const changePassword = async (data: ChangePasswordRequestType): Promise<{}> => {
  return axiosInstance.post('v1/users/change-pass', { password: data.newPassword });
};

export const getAllUsers = (pagination?: {
  page: number;
  take: number;
  search?: string;
  lang?: string;
  countries?: string[];
  roles?: string[];
  genders?: string[];
  birthStartDate?: string;
  birthEndDate?: string;
  createdAtStartDate?: string;
  createdAtEndDate?: string;
}): Promise<{ data: AllUsersType }> =>
  axiosInstance.get('v1/users', {
    params: {
      ...pagination,
      order: 'DESC'
    }
  });

export const getSingleUser = (id: string): Promise<{ data: User }> =>
  axiosInstance.get('v1/users/' + id);

export const deleteUser = (id: string): Promise<{}> => axiosInstance.delete('v1/users/' + id);

export const createRegularUser = async (data: CreateUserRequestType): Promise<{ data: User }> => {
  return axiosInstance.post('v1/users', data);
};

export const uploadUserImage = (
  id: string,
  image: FormData,
  type: 'image' | 'coverImage'
): Promise<{ data: { image: string } }> =>
  axiosInstance.patch(`v1/users/${id}/images`, image, {
    params: { type }
  });

export const deleteUsersImage = (id: string, type: 'image' | 'coverImage'): Promise<{}> =>
  axiosInstance.delete(`v1/users/${id}/images`, {
    params: { type }
  });

export const getGenresForUser = (
  id: string,
  lang: string
): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get(`v2/genres/localized/user/${id}`, { params: { lang } });

export const getRubricsForUser = (
  id: string,
  lang: string
): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get(`v2/rubrics/localized/user/${id}`, { params: { lang } });

export const getIndustriesForUser = (
  id: string,
  lang: string
): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get(`v2/industries/localized/user/${id}`, { params: { lang } });

export const getInterestsForUser = (
  id: string,
  lang: string
): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get(`v2/interests/localized/user/${id}`, { params: { lang } });

export const getCelebrationsForUser = (
  id: string,
  lang: string
): Promise<{ data: InterestsLocalizedType[] }> =>
  axiosInstance.get(`v1/celebrations/localized/user/${id}`, { params: { lang } });

export const exportUsersData = (params: ExportUsersParamsType): Promise<{ data: BlobPart }> => {
  return axiosInstance.get(`v1/users/download-users`, {
    responseType: 'blob',
    params: {
      firstName: true,
      lastName: true,
      email: true,
      ...params
    }
  });
};
