import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//types
import { CreateVoucherType, VoucherType } from '@interfaces/vouchers/vouchers';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { MRT_PaginationState, MRT_ColumnFiltersState } from 'material-react-table';
import { StatusEnum } from '@enum/progressEnum';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
//api
import {
  useApproveVoucher,
  useCreateVoucher,
  useDeclineVoucher,
  useDeleteVoucher,
  useDeleteVoucherImage,
  useUpdateVoucher
} from '@api/mutations/vouchers/vouchers';
import { useAllCurrencies, useAllVouchers, useSingleVoucher } from '@api/queries/vouchers/vouchers';
import { useAllCompaniesInfinity } from '@api/queries/company/company';
import { useAllLocalizedInterests } from '@api/queries/interests/interests';
//hooks
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//helpers
import { formHelper } from '@helpers/formDataHelper';
import { dayjs, getDefaultPagnation } from '@helpers/utility';
//recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { userAtom } from '@atoms/userAtom';
//enum
import { RoleEnum } from '@enum/roleEnum';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

export function useVoucher() {
  const [popup, setPopup] = useState<FormModalPropsType<{}> | null>(null);
  const [search, setSearch] = useState('');

  const { voucherId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const user = useRecoilValue(userAtom);

  const onCreateSuccess = (data: VoucherType) => {
    refetch();
    navigate(`/vouchers/${data.id}/edit/body`);
    reset({});
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onUpdateSuccess = (data: VoucherType) => {
    const val = getValues();
    const deleteTypes = [];
    if (val.image === null && singleVoucher?.image) deleteTypes.push('image');
    if (val.heroImage === null && singleVoucher?.heroImage) deleteTypes.push('heroImage');

    if (deleteTypes.length === 0) {
      handleResetUpdate();
    } else {
      deleteImages({
        id: data.id,
        type: deleteTypes
      });
    }
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onCreateError = (err: ErrorType<CreateVoucherType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof CreateVoucherType, {
          type: 'custom',
          message: err.errors[key as keyof CreateVoucherType]?.join(', ')
        });
      }
    else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onDeleteSuccess = () => {
    if (voucherId) navigate('/vouchers');
    refetch();
    setPopup(null);
  };
  const onApproveSuccess = () => {
    refetchSingle();
    setPopup(null);
  };

  const onSuccessSingle = (data: VoucherType) => {
    reset({
      validUntil: data.validUntil ? dayjs(data.validUntil) : undefined,
      location: data.location ?? undefined,
      interests: data.interests.map(i => i.id),
      couponLimit: data.couponLimit ?? undefined,
      targetGroup: data.targetGroup,
      image: data.image,
      heroImage: data.heroImage,
      value: {
        value: data.value.value
      },
      translations: Object.values(SupportedLanguagesEnum).reduce((acc: any, lng) => {
        acc[lng] = {
          body: data?.translations?.[lng]?.body,
          tags: data.translations?.[lng]?.tags?.length ? data.translations?.[lng]?.tags : [''],
          hashTags: data.translations?.[lng]?.hashTags?.length
            ? data.translations?.[lng]?.hashTags
            : [''],
          title: data.translations?.[lng]?.title,
          shortDescription: data.translations?.[lng]?.shortDescription ?? ''
        };

        return acc;
      }, {})
    });
  };

  const handleResetUpdate = () => {
    if (location.pathname.includes('/edit/body')) {
      navigate(`/vouchers/${voucherId}`);
    } else {
      navigate(`/vouchers/${voucherId}/edit/body`);
    }
    refetchSingle();
    reset({});
  };

  const {
    data: singleVoucher,
    isLoading: isLoadingSingle,
    refetch: refetchSingle
  } = useSingleVoucher(voucherId, onError, i18n.language, onSuccessSingle);

  const { mutate: createVoucher, isLoading: isLoadingCreate } = useCreateVoucher(
    onCreateSuccess,
    onCreateError
  );
  const { mutate: updateVoucher, isLoading: isLoadingUpdate } = useUpdateVoucher(
    onUpdateSuccess,
    onCreateError
  );
  const { mutate: deleteVoucher, isLoading: isLoadingDelete } = useDeleteVoucher(
    onDeleteSuccess,
    onError
  );
  const { mutate: approveVoucher, isLoading: isLoadingApprove } = useApproveVoucher(
    onApproveSuccess,
    onError
  );
  const { mutate: declineVoucher, isLoading: isLoadingDecline } = useDeclineVoucher(
    onApproveSuccess,
    onError
  );
  const { mutate: deleteImages } = useDeleteVoucherImage(onError, handleResetUpdate);

  const {
    data: vouchers,
    refetch,
    isLoading: isLoadingAll
  } = useAllVouchers(onError, user?.type as RoleEnum, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    status: columnFilters.find(el => el.id === 'status')?.value as StatusEnum,
    search: columnFilters.find(el => el.id === 'title')?.value as string,
    interestId: columnFilters.find(el => el.id === 'interests')?.value as string,
    lang: i18n.language
  });

  const { data: currencies, isFetching: isLoadingCurrencies } = useAllCurrencies(onError);

  const {
    data: companiesInfinity,
    fetchNextPage: fetchNextPageCompanies,
    hasNextPage: hasNextPageCompanies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies
  } = useAllCompaniesInfinity(i18n.language, onError, true, search);

  const { data: interests } = useAllLocalizedInterests(
    onError,
    i18n.language as SupportedLanguagesEnum
  );

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
    clearErrors,
    getValues,
    formState: { errors, isValid }
  } = useForm<CreateVoucherType>({
    defaultValues: {
      targetGroup: 'all',
      translations: {
        en: {
          tags: [''],
          hashTags: ['']
        },
        sr: {
          tags: [''],
          hashTags: ['']
        },
        de: {
          tags: [''],
          hashTags: ['']
        },
        fr: {
          tags: [''],
          hashTags: ['']
        }
      }
    }
  });

  const handleCreate = (data: CreateVoucherType) => {
    if (!data.translations) {
      setError('root', {
        type: 'custom',
        message: t('voucher.translation_error')
      });
      return;
    }

    for (const lng of Object.values(SupportedLanguagesEnum)) {
      if (!data?.translations?.[lng]?.title) {
        setError('root', {
          type: 'custom',
          message: t('voucher.translation_error')
        });
        return;
      }
    }

    let title = '';
    data.translations = Object.values(SupportedLanguagesEnum).reduce((acc: any, lng) => {
      title += data?.translations?.[lng]?.title + ' ';
      const hashTags = data?.translations?.[lng]?.hashTags.filter((tag: string) => tag !== '');
      const tags = data?.translations?.[lng]?.tags.filter((tag: string) => tag !== '');
      acc[lng] = {
        body: JSON.stringify(data?.translations?.[lng]?.body) ?? '{}',
        title: data?.translations?.[lng]?.title,
        hashTags: hashTags.length ? hashTags : undefined,
        tags: tags.length ? tags : undefined,
        shortDescription: data?.translations?.[lng]?.shortDescription ?? undefined
      };

      return acc;
    }, {});
    data.title = title;

    data.validUntil = data.validUntil?.toString();

    data.categories =
      typeof data.categories !== 'string' ? data.categories?.join(', ') : data.categories;

    data.value.currency = currencies?.find(c => c.id === data.value.currency);

    if (typeof data.hashTags !== 'string')
      data.hashTags = data.hashTags?.filter(ht => ht !== '').join(',');

    if (typeof data.tags !== 'string') data.tags = data.tags?.filter(ht => ht !== '').join(',');

    if (data.interests && typeof data.interests !== 'string')
      data.interests = data.interests.join(',');

    const companyId = data.companyId;
    delete data.companyId;

    data.shortDescription = data?.translations?.[SupportedLanguagesEnum.SERBIAN]?.shortDescription;
    data.tags = data?.translations?.[SupportedLanguagesEnum.SERBIAN]?.tags;
    data.hashTags = data?.translations?.[SupportedLanguagesEnum.SERBIAN]?.hashTags;
    // data.data = data?.translations?.[SupportedLanguagesEnum.SERBIAN]?.body;

    if (voucherId) {
      if (typeof data.image === 'string') delete data.image;
      if (typeof data.heroImage === 'string') delete data.heroImage;

      const newVoucher = Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v));

      updateVoucher({
        data: formHelper.getBody(newVoucher),
        id: voucherId
      });
    } else {
      const newVoucher = Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v));

      if (companyId)
        createVoucher({
          data: formHelper.getBody(newVoucher),
          companyId: companyId
        });
    }
  };

  const handleDelete = (data: VoucherType) => {
    deleteVoucher(data.id);
  };

  const onDeleteVoucherClick = (id: string) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.voucher`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.voucher`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (id) {
          deleteVoucher(id);
        }
      }
    });
  };

  const handleApprove = () => {
    setPopup({
      title: t(`voucher.approve_message_title`),
      subtitle: t(`voucher.approve_message_content`),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (voucherId) {
          approveVoucher(voucherId);
        }
      }
    });
  };

  const handleDecline = () => {
    setPopup({
      title: t(`voucher.decline_message_title`),
      subtitle: t(`voucher.decline_message_content`),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (voucherId) {
          declineVoucher(voucherId);
        }
      }
    });
  };

  const handleEditClick = () => {
    navigate('edit');
  };

  useEffect(() => {
    if (location.pathname === '/vouchers')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  useEffect(() => {
    if (singleVoucher && currencies) {
      setValue(
        'value.currency',
        currencies.find(el => el.id === singleVoucher.value.currency?.id)?.id
      );
    }
  }, [currencies, singleVoucher]);

  return {
    vouchers: vouchers ? vouchers.data : [],
    watch,
    errors,
    setValue,
    clearErrors,
    getValues,
    handleCreate,
    handleDelete,
    control,
    handleSubmit,
    reset,
    deleteVoucher,
    voucherId: voucherId ? voucherId : null,
    setError,
    singleVoucher,
    isLoadingSingle,
    onDeleteVoucherClick,
    popup,
    currencies: currencies ? currencies : [],
    handleApprove,
    handleDecline,
    rowCount: vouchers ? vouchers.meta.itemCount : 0,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    isLoadingAll,
    isValid,
    isLoadingCompanies,
    isFetchingCompanies,
    hasNextPageCompanies,
    fetchNextPageCompanies,
    interests: interests ?? [],
    companies: companiesInfinity?.pages
      .flatMap(item => item.data.data)
      .map(v => {
        return { id: v.id, name: v.name ? v.name : '' };
      }),
    setSearch,
    isLoadingDelete,
    isLoadingStatus: isLoadingApprove || isLoadingDecline,
    isSubmitting: isLoadingCreate || isLoadingUpdate,
    handleEditClick,
    isLoadingCurrencies,
    isBusiness: user?.type === RoleEnum.BUSINESS
  };
}
