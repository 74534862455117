//hooks
import { useDropzone } from 'react-dropzone';
// components
import {
  Typography,
  IconButton,
  Button,
  Grid,
  LinearProgress,
  Badge,
  Box,
  Card
} from '@mui/material';
// icons
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '@src/theme';
import dayjs from 'dayjs';

type UploadImageProps = {
  title: string;
  image: File | null | string | undefined;
  handleDelete: () => void;
  onDropCallback: (image: File) => void;
  uploadPercent?: number;
  isLoading?: boolean;
  isDocument?: boolean;
  error?: boolean;
};

const UploadImage = ({
  title,
  image,
  onDropCallback,
  handleDelete,
  uploadPercent,
  isLoading,
  isDocument,
  error
}: UploadImageProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: isDocument
      ? {
          'text/csv': [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
          'application/excel': [],
          'application/vnd.ms-excel': [],
          'application/x-excel': [],
          'application/x-msexcel': []
        }
      : { 'image/jpeg': [], 'image/png': [], 'image/webp': [] },
    onDrop: acceptImages =>
      acceptImages.map(image => {
        onDropCallback(image);
      })
  });

  return (
    <Box>
      <Typography variant="subtitle2" color="gray">
        {title}
      </Typography>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '152px',
          border: `1px dashed ${error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.2)'}`,
          borderRadius: '1rem',
          '&:hover': {
            borderColor: 'blue',
            background: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <input {...getInputProps()} />
        <IconButton size="medium" color={error ? 'error' : 'primary'}>
          <UploadFileOutlinedIcon />
        </IconButton>
        <Typography variant="subtitle2">
          <Button
            variant="text"
            component="a"
            sx={{ textTransform: 'none' }}
            color={error ? 'error' : 'primary'}
          >
            Click to upload
            <input {...getInputProps()} />
          </Button>
          or drag and drop
        </Typography>
        <Typography variant="subtitle2" color="gray">
          {isDocument ? 'CSV, Excel (max. 3MB)' : '.png, .jpeg, .webp (max. 3MB)'}
        </Typography>
      </Box>
      <Grid container spacing={2} py={2} wrap="wrap">
        {image && (
          <Grid item xs={6} sm={3}>
            <Badge
              color="error"
              sx={{
                cursor: 'pointer',
                '.MuiBadge-badge': { padding: '12px 4px', borderRadius: '50%' }
              }}
              onClick={() => handleDelete()}
              badgeContent={<ClearIcon fontSize="small" />}
            >
              {typeof image === 'string' ? (
                <img src={image + `?${dayjs().toISOString()}`} width={100} />
              ) : isDocument ? (
                <Box p={2}>{image.name}</Box>
              ) : (
                <img src={URL.createObjectURL(image)} width={100} />
              )}
              {isLoading && (
                <LinearProgress
                  color="success"
                  variant="buffer"
                  value={uploadPercent}
                  valueBuffer={uploadPercent}
                />
              )}
            </Badge>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UploadImage;
