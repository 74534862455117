// store
import { atom } from 'recoil';
// interfaces
import { User } from '@src/domain/interfaces/users/users';
import { RegisterRequestType } from '@interfaces/login';

const userAtom = atom<User | null>({
  key: 'userState',
  default: null
});

const credentialsUserRegisterAtom = atom<RegisterRequestType>({
  key: 'credentialsUserRegisterAtom',
  default: {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    passwordConfirm: '',
    companyName: '',
    pib: '',
    country: '',
    activity: ''
  }
});

export { userAtom, credentialsUserRegisterAtom };
