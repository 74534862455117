import { createTheme } from '@mui/material/styles';

export const primaryColor = '#304674';
export const secondaryColor = '#DAE2F2';
export const errorColor = '#f44336';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto'
    },
    subtitle1: {
      fontSize: '1.15rem'
    },
    body1: {
      fontSize: '1rem'
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        autoComplete: 'off'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '60rem',

          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
          },
          '& input[type=number]': {
            MozAppearance: 'textfield'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 3px 0px #0000001A',
          borderRadius: '1.5rem',
          outline: '1px solid #C4C4C4'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '60rem'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: primaryColor,
          padding: '0.75rem 1rem',
          minHeight: 'auto',
          '&.Mui-selected': {
            borderRadius: '1.5rem',
            boxShadow: '0px 4px 3px 0px #0000001A',
            backgroundColor: secondaryColor,

            border: '1px solid #304674',
            padding: '0.75rem 1rem',
            minHeight: '1rem'
          }
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
        scrollButtons: 'auto'
      },
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '1.5rem',
          boxShadow: '0px 4px 3px 0px #0000001A',
          padding: '0.25rem 1rem'
        }
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            outline: '1px solid #304674',
            backgroundColor: secondaryColor,
            color: primaryColor,
            '&:hover': {
              color: secondaryColor,
              backgroundColor: primaryColor
            }
          }
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            outline: '1px solid #C62828',
            backgroundColor: '#FFE4E4',
            color: '#C62828',
            '&:hover': {
              color: '#FFE4E4',
              backgroundColor: '#C62828'
            }
          }
        },
        {
          props: { variant: 'text' },
          style: {
            boxShadow: 'none'
          }
        }
      ]
    },

    MuiChip: {
      defaultProps: {
        variant: 'outlined',
        size: 'small'
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            border: '1px solid #0E7013',
            backgroundColor: '#EAFFEB',
            color: '#0E7013'
          }
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            border: '1px solid #C62828',
            backgroundColor: '#FFE4E4',
            color: '#C62828'
          }
        },

        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            border: '1px solid #ED6C02',
            backgroundColor: '#FFE0C7',
            color: '#ED6C02'
          }
        },

        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: `1px solid ${primaryColor}`,
            backgroundColor: secondaryColor,
            color: primaryColor
          }
        }
      ]
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 3px 0px #0000001A'
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          borderInline: `1px solid ${secondaryColor}`,
          borderBottom: `1px solid ${secondaryColor}`
        }
      }
    },

    MuiIconButton: {
      defaultProps: { size: 'small' }
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: primaryColor,
            fontSize: '2rem',
            border: 'none',
            text: {
              fill: secondaryColor
            }
          },
          '&.Mui-completed': {
            color: primaryColor,
            border: 'none'
          },
          color: secondaryColor,
          fontSize: '2rem',
          border: `1px solid #304674`,
          borderRadius: '50%'
        },
        text: {
          fill: primaryColor
        }
      }
    },

    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: secondaryColor
        }
      }
    },

    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': { color: primaryColor, fontWeight: 'bold', opacity: 1, fontSize: '1rem' }
        }
      }
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '1rem',
          outline: '1px solid #C4C4C4'
        }
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBlock: '1px solid #c4c4c4',
          boxShadow: 'none'
        }
      }
    }
  }
});

export default theme;
