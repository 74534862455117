import { NavLink, Outlet, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const selectedTab = (path: string) => {
  const page = path.split('/').pop();

  if (page === 'genres' || page === 'rubrics' || page === 'industries' || page === 'interests') {
    return page;
  }
  return 'rubrics';
};

const CategoriesLayoutPage = () => {
  const [activeTab, setActiveTab] = useState<string | boolean>(false);

  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    setActiveTab(selectedTab(location.pathname));
  }, [location.pathname]);

  return (
    <Box width={'100%'}>
      <Box m={2}>
        <Tabs value={activeTab}>
          <Tab
            label={t('interests.rubrics.title')}
            component={NavLink}
            to="rubrics"
            value={'rubrics'}
          />
          <Tab
            label={t('interests.genres.title')}
            component={NavLink}
            to="genres"
            value={'genres'}
          />
          <Tab
            label={t('interests.industries.title')}
            component={NavLink}
            to="industries"
            value={'industries'}
          />
          <Tab
            label={t('interests.interests.title')}
            component={NavLink}
            to="interests"
            value={'interests'}
          />
        </Tabs>
      </Box>

      <Outlet />
    </Box>
  );
};

export default CategoriesLayoutPage;
