import { NavLink } from 'react-router-dom';
//hooks
import { useEffect, useMemo, useState } from 'react';
import { useUsers } from '@hooks/useUsers/useUsers';
//components
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import TableLayout from '@components/TableLayout/TableLayout';
import Loading from '@components/Loading/Loading';
import FormModal from '@components/FormModal/FormModal';
//helpers
import dayjs from 'dayjs';
//type
import { MRT_ColumnDef } from 'material-react-table';
import { RoleEnum } from '@enum/roleEnum';
import { User } from '@interfaces/users/users';
//icons
import DeleteIcon from '@mui/icons-material/Delete';
import { FileDownload } from '@mui/icons-material';
import { CountryLocalizedType } from '@interfaces/autocomplete/autocomplete';

const UsersPage = () => {
  const {
    users,
    t,
    pagination,
    setPagination,
    isLoading,
    onDeleteClick,
    popup,
    setPopup,
    columnFilters,
    setColumnFilters,
    isLoadingDelete,
    handleDownloadData,
    suggestedCountries,
    isLoadingSuggestion,
    handleSearchChange,
    handleInputChange,
    countrySearch
  } = useUsers();

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: t('global.name_and_surname'),
        Cell: ({ row }) => (
          <NavLink to={`single/${row.original.id}`}>
            {row.original.firstName
              ? (row.original.firstName ?? '') + ' ' + (row.original.lastName ?? '')
              : row.original.email}
          </NavLink>
        )
      },
      {
        accessorKey: 'type',
        header: t('global.role'),
        Cell: ({ row }) =>
          row.original.type ? t(`global.${row.original.type}`) : t('global.regular'),
        filterVariant: 'multi-select',
        filterSelectOptions: [
          { value: RoleEnum.ADMIN, text: t('global.admin') },
          { value: RoleEnum.BUSINESS, text: t('global.business') },
          { value: null, text: t('global.regular') }
        ]
      },
      {
        accessorKey: 'emailVerifiedAt',
        header: t('user.email_verified'),
        Cell: ({ cell }) =>
          cell.getValue<string>() ? dayjs(cell.getValue<string>()).format('DD.MM.YYYY.') : '',
        enableColumnFilter: false
      },
      {
        accessorKey: 'createdAt',
        header: t('user.created_at'),
        Cell: ({ cell }) => dayjs(cell.getValue<string>()).format('DD.MM.YYYY.'),
        filterVariant: 'date',
        filterFn: 'between'
      },
      {
        accessorKey: 'dateOfBirth',
        header: t('global.date_of_birth'),
        Cell: ({ cell }) =>
          cell.getValue<string>() ? dayjs(cell.getValue<string>()).format('DD.MM.YYYY.') : '',
        filterVariant: 'date',
        filterFn: 'between'
      },
      {
        accessorKey: 'gender',
        header: t('global.gender'),
        Cell: ({ cell }) => (cell.getValue<string>() ? t(`global.${cell.getValue<string>()}`) : ''),
        filterVariant: 'multi-select',
        filterSelectOptions: [
          { value: 'male', text: t('global.male') },
          { value: 'female', text: t('global.female') }
        ]
      },
      {
        accessorKey: 'countryNew',
        header: t('global.country'),
        Cell: ({ row }) => row.original.countryNew?.name,
        filterVariant: 'autocomplete',
        manualFilter: true,
        muiFilterAutocompleteProps: (props: any) => {
          return {
            renderInput: params => (
              <TextField InputProps={params.InputProps} inputProps={params.inputProps} value={[]} />
            ),
            options: suggestedCountries?.data ? suggestedCountries.data : [],
            freeSolo: true,
            loading: countrySearch.length === 0 ? false : isLoadingSuggestion,
            loadingText: <Loading />,
            onChange: handleInputChange,
            getOptionLabel: (option: CountryLocalizedType | string) =>
              typeof option !== 'string' ? option.name : option,

            onInputChange: handleSearchChange,
            isOptionEqualToValue: (option: CountryLocalizedType, value: CountryLocalizedType) =>
              option.name === value.name,
            filterOptions: (options, state) => options,
            noOptionsText: 'no options'
            // multiple: true
          };
        }
        // enableColumnFilter: false
      },
      {
        accessorKey: 'id',
        header: t('global.actions'),
        enableColumnFilter: false,
        Cell: ({ row }) =>
          row.original.type !== 'admin' && (
            <IconButton onClick={() => onDeleteClick(row.original)} color="error">
              <DeleteIcon />
            </IconButton>
          ),
        enableHiding: false,
        enableSorting: false
      }
    ],
    [users, t, suggestedCountries]
  );

  return (
    <Box className="container">
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={() => {
            popup.onCancel();
            setPopup(null);
          }}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
          isLoading={isLoadingDelete}
        />
      )}
      <Box mt={1}>
        <Typography fontWeight={'bold'} variant="h4">
          {t('user.page_title')}
        </Typography>
        <Typography variant="subtitle1" color={'text.secondary'}>
          {t('user.page_subtitle')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Box display={'flex'} width={'100%'} justifyContent={'flex-end'}>
          <Button onClick={() => handleDownloadData()} variant="outlined" sx={{ mr: 2 }}>
            {t('global.export')}
            <FileDownload />
          </Button>
        </Box>
        <TableLayout
          columns={columns}
          data={users ? users.data : []}
          pagination={pagination}
          setPagination={setPagination}
          rowCount={users ? users.meta.itemCount : 0}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          isLoading={isLoading}
          enableHiding={true}
        />
      </Box>
    </Box>
  );
};

export default UsersPage;
