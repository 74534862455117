import global from './de/global.json';
import sidebar from './de/sidebar.json';
import category from './de/category.json';
import deleteTranslation from './de/delete.json';
import faq from './de/faq.json';
import slider from './de/slider.json';
import hit_list from './de/hit_list.json';
import interests from './de/interests.json';
import job from './de/job.json';
import news from './de/news.json';
import radio from './de/radio.json';
import status from './de/status.json';
import user from './de/user.json';
import voucher from './de/voucher.json';
import login from './de/login.json';
import verify_email from './de/verify_email.json';
import podcast from './de/podcast.json';
import steps from './de/steps.json';

export const translationGerman = {
  global,
  sidebar,
  category,
  delete: deleteTranslation,
  faq,
  slider,
  hit_list,
  interests,
  job,
  news,
  radio,
  status,
  user,
  voucher,
  login,
  verify_email,
  podcast,
  steps
};
