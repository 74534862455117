//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//type
import { InterestsType, UpdateInterestsType } from '@interfaces/interests/interests';
//api
import {
  createGenres,
  createIndustries,
  createInterests,
  createRubrics,
  deleteGenreImage,
  deleteGenres,
  deleteIndustries,
  deleteIndustryImage,
  deleteInterestImage,
  deleteInterests,
  deleteRubricImage,
  deleteRubrics,
  updateGenres,
  updateIndustries,
  updateInterests,
  updateRubrics
} from '@services/interests/interests';
import { ErrorsResponseType, ErrorType } from '@interfaces/response';

export const useCreateRubrics = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  FormData, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: createRubrics,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateRubrics = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  UpdateInterestsType, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: updateRubrics,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteRubrics = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteRubrics,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useCreateGenres = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  FormData, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: createGenres,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateGenres = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  UpdateInterestsType, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: updateGenres,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteGenres = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteGenres,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useCreateIndustry = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  FormData, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: createIndustries,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateIndustry = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  UpdateInterestsType, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: updateIndustries,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteIndustry = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteIndustries,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useDeleteIndustryImage = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteIndustryImage,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useDeleteRubricImage = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteRubricImage,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useDeleteGenreImage = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteGenreImage,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useCreateInterest = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  FormData, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: createInterests,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateInterest = (
  onSuccess: (data: InterestsType) => void,
  onError: (err: ErrorType<InterestsType>) => void
): UseMutationResult<
  { data: InterestsType }, // response
  ErrorsResponseType<InterestsType>,
  UpdateInterestsType, // request
  (data: InterestsType) => void // on success
> =>
  useMutation({
    mutationFn: updateInterests,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteInterest = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteInterests,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });

export const useDeleteInterestImage = (
  onSuccess: () => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseMutationResult<
  {}, // response
  ErrorsResponseType<{}>,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteInterestImage,
    onSuccess: () => onSuccess(),
    onError: err => onError(err)
  });
