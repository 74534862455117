import axios from 'axios';
import { isTokenExpired } from '@helpers/utility';
import { refreshToken } from '@services/keycloak';
import { BASE_URL } from '@helpers/env.config';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  async function (config) {
    if (localStorage.getItem('token')) {
      if (isTokenExpired(localStorage.getItem('token') || '')) {
        await refreshToken();
      }
    }

    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response.status === 401) {
      window.dispatchEvent(new Event('logout'));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
