import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import App from './App';
import './translation/i18n';
import { detectAdBlocker, isDevMode } from '@helpers/utility';
import { SENTRY_DSN, VITE_ENV } from '@helpers/env.config';

detectAdBlocker()
  .then(({ redirected }) => {
    if (redirected) {
      console.log('ad blocker detected');
    } else {
      Sentry.init({
        dsn: SENTRY_DSN,
        enabled: VITE_ENV === 'production' && !isDevMode(),
        integrations: [
          new BrowserTracing({ tracingOrigins: ['*'] }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
          })
        ],
        tracesSampleRate: 1.0,
        release: 'release_0.0.2'
      });
    }
  })
  .catch(e => {
    console.log('err', e);
  });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);
