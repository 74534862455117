import global from './en/global.json';
import sidebar from './en/sidebar.json';
import category from './en/category.json';
import deleteTranslation from './en/delete.json';
import faq from './en/faq.json';
import slider from './en/slider.json';
import hit_list from './en/hit_list.json';
import interests from './en/interests.json';
import job from './en/job.json';
import news from './en/news.json';
import radio from './en/radio.json';
import status from './en/status.json';
import user from './en/user.json';
import voucher from './en/voucher.json';
import login from './en/login.json';
import verify_email from './en/verify_email.json';
import podcast from './en/podcast.json';
import steps from './en/steps.json';

export const translationEnglish = {
  global,
  sidebar,
  category,
  delete: deleteTranslation,
  faq,
  slider,
  hit_list,
  interests,
  job,
  news,
  radio,
  status,
  user,
  voucher,
  login,
  verify_email,
  podcast,
  steps
};
