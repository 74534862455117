import { NavLink, Navigate } from 'react-router-dom';
//hooks
import { useNews } from '@hooks/useNews/useNews';
import { useTranslation } from 'react-i18next';
//components
import FormModal from '@components/FormModal/FormModal';
import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import PortableText from 'react-portable-text';
import Loading from '@components/Loading/Loading';
//helpers
import dayjs from 'dayjs';
import { primaryColor } from '@src/theme';
import { SANITY_PROJECT_ID, SANITY_URL, SANITY_PROJECT_DATASET } from '@helpers/env.config';

const SingleNewsPage = () => {
  const { singleNews, popup, onEditClick, isLoadingSingle, isSubmitting } = useNews();

  const { t } = useTranslation();

  if (isLoadingSingle) return <Loading />;
  if (!singleNews) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box>
      {popup && <FormModal {...popup} isLoading={isSubmitting} />}

      <Box
        sx={{
          width: '100%',
          position: 'relative',
          bgcolor: primaryColor,
          minWidth: '15rem',
          mb: 1,
          minHeight: 'fit-content',
          isolation: 'isolate',
          backgroundSize: 'cover',
          backgroundImage: `linear-gradient(0deg, rgba(48, 70, 116,.9),rgba(48, 70, 116,.5)),url(${
            singleNews?.heroImage ? singleNews.heroImage + `?${dayjs().toISOString()}` : ''
          })`,
          backgroundPosition: 'center'
        }}
      >
        <CardContent sx={{ color: 'white', width: '95%', display: 'flex', gap: 1 }}>
          {singleNews.featuredImage && (
            <CardMedia
              component="img"
              sx={{
                width: 90,
                height: 90,
                border: '2px solid ' + primaryColor,
                borderRadius: '8px'
              }}
              loading="lazy"
              image={singleNews.featuredImage + `?${dayjs().toISOString()}`}
              alt=""
            />
          )}
          <Box width={'100%'}>
            <Typography gutterBottom variant="h5" component="div" fontWeight={'bold'}>
              {singleNews.title ? singleNews.title : t('slider.form.title')}
            </Typography>
            <Typography variant="body2" sx={{ maxWidth: '90ch', textWrap: 'pretty' }}>
              {singleNews.shortDescription
                ? singleNews.shortDescription
                : t('slider.form.description_placeholder')}
            </Typography>
            <Typography variant="body2">{singleNews.tags?.map(tag => `#${tag} `)}</Typography>

            <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
              {singleNews.hashTags?.join(' ')}
            </Typography>
            <Box display={'flex'} p={1} justifyContent={'flex-end'}>
              <Button
                variant="contained"
                size="small"
                onClick={onEditClick}
                sx={{ outline: '1px solid #C5CBD8' }}
              >
                {t(`global.edit`)}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Box>
      <Box className="container">
        <Card sx={{ bgcolor: '#f9f9f9', p: 2 }}>
          <Box width={{ xs: '100%', sm: '80%' }}>
            <Box display={'flex'} gap={1}>
              <NavLink to={SANITY_URL} target="_blank" rel="noopener noreferrer">
                <Button size="small" variant="contained">
                  {t('news.edit_text')}
                </Button>
              </NavLink>
            </Box>
            <PortableText
              content={singleNews.data.content}
              projectId={SANITY_PROJECT_ID}
              dataset={SANITY_PROJECT_DATASET}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default SingleNewsPage;
