//react
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//interfaces
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { CreatePodcastType, PodcastType, PodcastTypeEnum } from '@interfaces/podcast/podcast';
import { FormModalPropsType } from '@components/FormModal/FormModal';
//hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
//services
import { useAllPodcasts, useSinglePodcast } from '@api/queries/podcast/podcast';
import {
  useCreatePodcast,
  useDeletePodcast,
  useUpdatePodcast
} from '@api/mutations/podcast/podcast';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
//helpers
import { getDefaultPagnation } from '@helpers/utility';
import { podcastFields } from '@helpers/formFields/formFields';

export function usePodcast() {
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });

  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [popup, setPopup] = useState<FormModalPropsType<CreatePodcastType> | null>(null);

  const { podcastId, channelId } = useParams();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors }
  } = useForm<CreatePodcastType>();

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.response.data?.message ? err.response.data?.message : err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onErrorCreate = (err: ErrorType<CreatePodcastType>) => {
    for (const key in err.errors) {
      setError(key as keyof CreatePodcastType, {
        type: 'custom',
        message: err.errors[key as keyof CreatePodcastType]?.join(', ')
      });
    }
    if (!err.errors) {
      setError('root', {
        type: 'custom',
        message: err.message
      });
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onSuccessCreate = (data: PodcastType) => {
    refetch();
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const { data: singlePodcast, isLoading: isLoadingSingle } = useSinglePodcast(podcastId, onError);

  const {
    data: podcasts,
    isLoading: isLoadingAll,
    refetch
  } = useAllPodcasts(onError, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    channelId: channelId,
    type: columnFilters.find(el => el.id === 'type')?.value as PodcastTypeEnum
  });

  const {
    mutate: deletePodcast,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete
  } = useDeletePodcast(onError);

  const {
    mutate: createPodcast,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreatePodcast(onErrorCreate, onSuccessCreate);
  const {
    mutate: updatePodcast,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdatePodcast(onErrorCreate);

  const onCancel = () => {
    setPopup(null);
  };

  useEffect(() => {
    if (location.pathname === '/podcasts')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  useEffect(() => {
    if (isSuccessDelete) {
      setPopup(null);
      if (podcastId) navigate(-1);
      else refetch();
    }
  }, [isSuccessDelete]);

  const onSubmit = (data: CreatePodcastType) => {
    if (channelId)
      createPodcast({
        externalId: data.externalId,
        type: data.type,
        channelId: channelId
      });
  };

  const onDeletePodcastClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.podcast`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.podcast`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (podcastId) {
          deletePodcast(podcastId);
        }
      }
    });
  };

  const onEditPodcastClick = () => {
    setPopup({
      title: t('podcast.form.edit_title'),
      open: true,
      control: control,
      fields: podcastFields(t),
      onCancel: onCancel,
      onSubmit: handleSubmit(onSubmit as () => void),
      setValue: setValue,
      buttonText: t('global.edit'),
      variant: 'form',
      setError: setError
    });
  };

  return {
    onCancel,
    deletePodcast,
    podcasts: podcasts ? podcasts.data : [],
    rowCount: podcasts ? podcasts.meta.itemCount : 0,
    pagination,
    setPagination,
    singlePodcast,
    isLoadingAll,
    isLoadingSingle,
    podcastId,
    popup,
    setPopup,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    errors,
    isSuccess: isCreateSuccess || isUpdateSuccess,
    reset,
    onSubmit,
    onDeletePodcastClick,
    onEditPodcastClick,
    columnFilters,
    setColumnFilters,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
