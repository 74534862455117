import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getVoucherSteps } from '@helpers/utility';

const CreateVoucherStepper = ({ activeStep }: { activeStep: number }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%' }} mt={3}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {getVoucherSteps(t).map((label, index) => (
          <Step key={label}>
            <StepLabel icon={index + 1}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box className="container">
        <Outlet />
      </Box>
    </Box>
  );
};

export default CreateVoucherStepper;
