import { InterestsLocalizedType } from '@interfaces/interests/interests';
import { ErrorsResponseType } from '@interfaces/response';
import { AllUsersType, ExportUsersParamsType, User } from '@interfaces/users/users';
import {
  exportUsersData,
  getAllUsers,
  getCelebrationsForUser,
  getGenresForUser,
  getIndustriesForUser,
  getInterestsForUser,
  getRubricsForUser,
  getSingleUser,
  me
} from '@services/users/users';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useUser = (
  isAuth: string | null,
  onSuccessUserResponse: (data: User) => void,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<User, ErrorsResponseType<{}>> =>
  useQuery(['user', isAuth], () => me(), {
    select: res => res.data.user,
    onSuccess: data => onSuccessUserResponse(data),
    onError: err => onError(err),
    refetchOnWindowFocus: false,
    enabled: !!isAuth,
    retry: false,
    cacheTime: 0,
    keepPreviousData: false,
    staleTime: 0
  });

export const useAllUsers = (
  pagination: {
    page: number;
    take: number;
    search?: string;
    lang?: string;
    countries?: string[];
    roles?: string[];
    genders?: string[];
    birthStartDate?: string;
    birthEndDate?: string;
    createdAtStartDate?: string;
    createdAtEndDate?: string;
  },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<AllUsersType, ErrorsResponseType<{}>> =>
  useQuery(['users', pagination], () => getAllUsers(pagination), {
    select: res => res.data,
    onError: err => onError(err)
  });

export const useSingleUser = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<User | undefined, ErrorsResponseType<{}>> =>
  useQuery(['user', id], () => (id ? getSingleUser(id) : null), {
    select: res => res?.data,
    onError: err => onError(err),
    enabled: !!id
  });

export const useUsersGenres = (
  req: { id: string | undefined; lang: string },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsLocalizedType[], ErrorsResponseType<{}>> =>
  useQuery(
    ['user-genres', req],
    () => (req.id ? getGenresForUser(req.id, req.lang) : { data: [] }),
    {
      select: res => res?.data,
      onError: err => onError(err),
      enabled: !!req.id
    }
  );

export const useUsersRubrics = (
  req: { id: string | undefined; lang: string },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsLocalizedType[], ErrorsResponseType<{}>> =>
  useQuery(
    ['user-rubrics', req],
    () => (req.id ? getRubricsForUser(req.id, req.lang) : { data: [] }),
    {
      select: res => res?.data,
      onError: err => onError(err),
      enabled: !!req.id
    }
  );

export const useUsersIndustries = (
  req: { id: string | undefined; lang: string },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsLocalizedType[], ErrorsResponseType<{}>> =>
  useQuery(
    ['user-industries', req],
    () => (req.id ? getIndustriesForUser(req.id, req.lang) : { data: [] }),
    {
      select: res => res?.data,
      onError: err => onError(err),
      enabled: !!req.id
    }
  );

export const useUsersInterests = (
  req: { id: string | undefined; lang: string },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsLocalizedType[], ErrorsResponseType<{}>> =>
  useQuery(
    ['user-interests', req],
    () => (req.id ? getInterestsForUser(req.id, req.lang) : { data: [] }),
    {
      select: res => res?.data,
      onError: err => onError(err),
      enabled: !!req.id
    }
  );

export const useUsersCelebrations = (
  req: { id: string | undefined; lang: string },
  onError: (err: ErrorsResponseType<{}>) => void
): UseQueryResult<InterestsLocalizedType[], ErrorsResponseType<{}>> =>
  useQuery(
    ['user-celebrations', req],
    () => (req.id ? getCelebrationsForUser(req.id, req.lang) : { data: [] }),
    {
      select: res => res?.data,
      onError: err => onError(err),
      enabled: !!req.id
    }
  );

export const useExportUserData = (
  params: ExportUsersParamsType,
  onError: (err: ErrorsResponseType<{}>) => void,
  onSuccess: (data: { data: BlobPart }) => void
): UseQueryResult<{}, ErrorsResponseType<{}>> =>
  useQuery(['export-user', params], () => exportUsersData(params), {
    enabled: false,
    onError: err => onError(err),
    onSuccess: onSuccess
  });
