//react
import { NavLink } from 'react-router-dom';
import { useMemo } from 'react';
//components
import { Box } from '@mui/system';
import Loading from '@components/Loading/Loading';
import PageLayout from '@pages/Page/PageLayout';
//interfaces
import { type MRT_ColumnDef } from 'material-react-table';
import { SliderType } from '@interfaces/sliders/sliders';
//hook
import { useSliders } from '@hooks/useSliders/useSliders';
import { useTranslation } from 'react-i18next';
//enum
import { InputTypeEnum } from '@enum/formModal';
import { SliderTypeEnum } from '@enum/sliderEnum';

const SlidersPage = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    setValue,
    reset,
    setError,
    sliders,
    success,
    deleteSlider,
    isLoading,
    isSubmitting
  } = useSliders();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<SliderType>[]>(
    () => [
      {
        accessorKey: 'type',
        header: t('global.type'),
        enableColumnFilter: false,
        Cell: ({ cell, row }) => <NavLink to={row.original.id}>{cell.getValue<string>()}</NavLink>
      }
    ],
    [t, sliders]
  );

  const handleDelete = (data: SliderType) => {
    deleteSlider(data.id);
  };

  return (
    <Box className="container">
      <PageLayout
        columns={columns}
        data={sliders}
        fields={[
          {
            key: 'type',
            type: InputTypeEnum.select,
            label: t('global.type'),
            select_options: Object.values(SliderTypeEnum)
          }
        ]}
        handleCreate={onSubmit}
        handleDelete={handleDelete}
        title={t('global.featured_posts')}
        subtitle={''}
        addBtnText={t('slider.add_featured_post')}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        resetForm={reset}
        translationKey="slider"
        setError={setError}
        isSubmitSuccessfull={success}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

export default SlidersPage;
