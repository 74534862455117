//type
import { AllCompaniesResponseType, CompanyType } from '@interfaces/company/company';
import { AxiosError } from 'axios';
//api
import { getAllCompanies, getCompaniesByUser, getSingleCompany } from '@services/company/company';
//react-query
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';
import { ErrorsResponseType } from '@interfaces/response';

export const useAllCompanies = (
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: {
    page: number;
    take: number;
    search?: string;
    industryId?: string;
    lang?: string;
    visible?: boolean;
  },
  disabled?: boolean
): UseQueryResult<AllCompaniesResponseType, ErrorsResponseType<{}>> =>
  useQuery(['companies', params], () => getAllCompanies(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: !disabled
  });

export const useMyCompanies = (
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean
): UseQueryResult<CompanyType[], ErrorsResponseType<{}>> =>
  useQuery(['my-companies'], () => getCompaniesByUser(), {
    onError: err => onError(err),
    select: res => res.data,
    refetchOnWindowFocus: false,
    enabled: enabled
  });

export const useSingleCompany = (
  id: string | undefined,
  onError: (err: ErrorsResponseType<{}>) => void,
  lang?: string
): UseQueryResult<CompanyType | undefined, ErrorsResponseType<{}>> =>
  useQuery(
    ['company', id, lang],
    () => {
      if (id) {
        return getSingleCompany(id, lang);
      }
      return null;
    },
    {
      onError: err => onError(err),
      select: res => (res ? res.data : undefined),
      enabled: !!id,
      cacheTime: 0,
      staleTime: 0,
      retry: false
    }
  );

export const useAllCompaniesInfinity = (
  lang: string,
  onError: (err: ErrorsResponseType<{}>) => void,
  enabled: boolean,
  search?: string
): UseInfiniteQueryResult<{ data: AllCompaniesResponseType }, ErrorsResponseType<{}>> =>
  useInfiniteQuery(
    ['infinity-companies', search],
    a =>
      getAllCompanies({
        page: a.pageParam,
        take: 10,
        lang,
        search
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.hasNextPage ? lastPage.data.meta.page + 1 : undefined,
      onError: err => onError(err),
      enabled: enabled
    }
  );
