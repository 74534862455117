//components
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loading from '@components/Loading/Loading';
//hooks
import { useAutocomplete } from '@hooks/useAutocompleteLocation/useAutocompleteLocation';
import { UseFormSetValue } from 'react-hook-form';
//type
import { PredictionType } from '@interfaces/autocomplete/autocomplete';
import { useEffect } from 'react';
import { AutpocompleteTypeEnum } from '@enum/autocompleteEnum';

type AutocompleteInputFieldProps<T extends {}> = {
  setValue: UseFormSetValue<T>;
  invalid: boolean;
  label: string;
  type: AutpocompleteTypeEnum;
  error?: string;
  defaultValue?: string;
  required?: boolean;
};

function AutocompleteInputField<T extends {}>({
  setValue,
  invalid,
  error,
  label,
  type,
  required,
  defaultValue = ''
}: AutocompleteInputFieldProps<T>) {
  const {
    handleSearchChange,
    countrySearch,
    handleInputChange,
    suggested,
    isLoadingSuggestion,
    setCountrySearch
  } = useAutocomplete(setValue, type);

  useEffect(() => {
    if (defaultValue && !countrySearch) setCountrySearch(defaultValue);
  }, [defaultValue]);

  return (
    <Autocomplete
      freeSolo
      id="autocomplte_basic_data"
      options={suggested?.predictions ? suggested.predictions : []}
      loading={countrySearch === '' ? false : isLoadingSuggestion}
      loadingText={<Loading />}
      inputValue={countrySearch}
      onChange={handleInputChange}
      getOptionLabel={(option: PredictionType | string) =>
        typeof option !== 'string' ? option.description : option
      }
      onInputChange={handleSearchChange}
      isOptionEqualToValue={(option: PredictionType, value: PredictionType) =>
        option.description === value.description
      }
      filterOptions={(options, state) => options}
      noOptionsText={'no options'}
      renderInput={params => (
        <TextField
          InputProps={params.InputProps}
          inputProps={params.inputProps}
          label={label}
          error={invalid}
          helperText={error}
          required={required}
        />
      )}
    />
  );
}

export default AutocompleteInputField;
