import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import PushNotification from './PushNotification.modal';

import { onMessage } from 'firebase/messaging';
import useFcmToken from '@helpers/firebase/useFcmToken';
import { messaging } from '@helpers/firebase/firebase';
import {
  isShowNotificationAtom,
  notificationAtom,
  notificationCountAtom
} from '@atoms/notfications';

let notificationTimeout: NodeJS.Timeout;

export default function PushNotificationsProvider() {
  const setIsShowNotification = useSetRecoilState(isShowNotificationAtom);
  const setNotification = useSetRecoilState(notificationAtom);
  const setNotificationCount = useSetRecoilState(notificationCountAtom);

  const hideNotification = () => {
    notificationTimeout = setTimeout(() => {
      setIsShowNotification(false);
      setTimeout(() => {
        setNotification(null);
      }, 1000);
    }, 5000);
  };

  const handleMouseOver = (): void => {
    clearTimeout(notificationTimeout);
  };

  useEffect(() => {
    const setupMessages = async () => {
      const mess = await messaging();

      if (typeof window !== 'undefined' && mess) {
        onMessage(mess, payload => {
          console.log('Foreground push notification received:', payload);
          // Handle the received push notification while the app is in the foreground
          // You can display a notification or update the UI based on the payload

          const notification =
            payload &&
            payload.data &&
            payload.notification &&
            payload.notification.title &&
            payload.notification.body
              ? {
                  origin: payload.data.origin,
                  notificationId: payload.data.notificationId,
                  type: payload.data.type,
                  title: payload.notification.title,
                  body: payload.notification.body
                }
              : null;

          setNotification(notification);
          setIsShowNotification(true);

          hideNotification();

          setNotificationCount(prev => prev + 1);
        });
      } else {
        console.log('messages not supported');
      }

      setupMessages();

      return () => {
        // unsubscribe(); // Unsubscribe from the onMessage event
        // clearTimeout(notificationTimeout);
      };
    };
  }, []);

  // JSX
  return <PushNotification handleMouseOver={handleMouseOver} handleMouseLeave={hideNotification} />;
}
