import global from './sr/global.json';
import sidebar from './sr/sidebar.json';
import category from './sr/category.json';
import deleteTranslation from './sr/delete.json';
import faq from './sr/faq.json';
import slider from './sr/slider.json';
import hit_list from './sr/hit_list.json';
import interests from './sr/interests.json';
import job from './sr/job.json';
import news from './sr/news.json';
import radio from './sr/radio.json';
import status from './sr/status.json';
import user from './sr/user.json';
import voucher from './sr/voucher.json';
import login from './sr/login.json';
import verify_email from './sr/verify_email.json';
import podcast from './sr/podcast.json';
import steps from './sr/steps.json';

export const translationSerbian = {
  global,
  sidebar,
  category,
  delete: deleteTranslation,
  faq,
  slider,
  hit_list,
  interests,
  job,
  news,
  radio,
  status,
  user,
  voucher,
  login,
  verify_email,
  podcast,
  steps
};
