export enum ProgressEnum {
  active = 'active',
  draft = 'draft',
  inactive = 'inactive'
}

export enum StatusEnum {
  active = 'active',
  pending = 'pending',
  finished = 'finished',
  draft = 'draft',
  declined = 'declined'
}

export enum MuiColorEnum {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  PRIMARY = 'primary'
}

export enum CouponStatusEnum {
  EXPIRED = 'expired',
  ACTIVE = 'active',
  REDEEMED = 'redeemed'
}
