//react
import { useState } from 'react';
import { useParams } from 'react-router-dom';
//interfaces
import { CreateSliderType, SliderType } from '@interfaces/sliders/sliders';
//useForm
import { useForm } from 'react-hook-form';
//api
import { useAllSliders } from '@api/queries/sliders/sliders';
import { useCreateSlider, useDeleteSlider } from '@api/mutations/sliders/sliders';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';
import { useTranslation } from 'react-i18next';

export function useSliders() {
  const [values, setValues] = useState<CreateSliderType | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState<
    | {
        title: string;
        heroImage: string | null;
        shortDescription: string;
        type: string;
      }
    | undefined
  >();
  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
    watch
  } = useForm<CreateSliderType>({
    values
  });

  const handleReset = () => {
    reset({});
    setValues(undefined);
    setSelected(undefined);
  };

  const onSuccessCreateSlider = (data: SliderType) => {
    refetch();
    handleReset();
    setSuccess(true);
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onErrorCreate = (err: ErrorType<CreateSliderType>) => {
    setSuccess(false);
    for (const key in err.errors) {
      setError(key as keyof CreateSliderType, {
        type: 'custom',
        message: err.errors[key as keyof CreateSliderType]?.join(', ')
      });
    }
    if (!err.errors)
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
  };

  const onSuccessDelete = () => {
    refetch();
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const { data: sliders, refetch, isFetching: isLoading } = useAllSliders(onError);
  const { mutate: createSlider, isLoading: isLoadingCreate } = useCreateSlider(
    onSuccessCreateSlider,
    onErrorCreate
  );
  const { mutate: deleteSlider, isLoading: isLoadingDelete } = useDeleteSlider(
    onSuccessDelete,
    onError
  );

  const onSubmit = (data: CreateSliderType) => {
    createSlider(data);
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    setValue,
    reset: handleReset,
    setError,
    sliders: sliders ? sliders : [],
    errors,
    success,
    deleteSlider,
    watch,
    selected,
    setSelected,
    refetch,
    isLoading,
    isSubmitting: isLoadingDelete || isLoadingCreate
  };
}
