//hooks
import { useMemo } from 'react';
//components
import TableLayout from '@components/TableLayout/TableLayout';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
//type
import { User } from '@interfaces/users/users';
import { MRT_ColumnDef } from 'material-react-table';
//helpers
import dayjs from 'dayjs';
import { getBusinessUserProfileKeys, getUserKeys } from '@helpers/profileFields/profileFields';
//icons
import { DeleteOutline } from '@mui/icons-material';
import { useProfile } from '@hooks/useProfile/useProfile';
import { useEditPassword } from '@hooks/useEditPassword/useEditPassword';
import { CompanyType } from '@interfaces/company/company';
import { NavLink } from 'react-router-dom';
import { UserInterestsEnum } from '@enum/userEnum';
import { getTranslationValue } from '@helpers/utility';
import i18next from 'i18next';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const UserProfileInfoPage = ({ single }: { single?: boolean }) => {
  const { user, t, formData, control, setFormData, errors, onDeleteClick, isLoadingDelete } =
    useProfile();
  const { onEditPassword, passwordFormData } = useEditPassword();

  const columns = useMemo<
    MRT_ColumnDef<{
      label: string;
      key: keyof User | UserInterestsEnum;
    }>[]
  >(
    () => [
      {
        accessorKey: 'label',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => <Typography color={'primary'}>{cell.getValue<string>()}</Typography>
      },
      {
        accessorKey: 'key',
        header: '',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const key = cell.getValue<string>() as keyof User;
          if (key === 'createdAt')
            return <Typography>{dayjs(user?.[key]).format('DD.MM.YYYY.')}</Typography>;
          if (key === 'dateOfBirth')
            return (
              <Typography>
                {user?.dateOfBirth ? dayjs(user?.[key]).format('DD.MM.YYYY.') : ''}
              </Typography>
            );
          if (key === 'id')
            return (
              <Button
                variant="text"
                sx={{ textDecoration: 'underline', textTransform: 'none', pl: 0 }}
                onClick={() => onEditPassword()}
              >
                {t('user.change_password')}
              </Button>
            );

          if (key === 'gender') return <Typography>{t(`global.${user?.gender}`, '')}</Typography>;

          if (key === 'countryNew')
            return (
              <Typography>
                {user?.countryNew?.translations
                  ? getTranslationValue(
                      user.countryNew.translations,
                      'name',
                      i18next.language as SupportedLanguagesEnum
                    )
                  : ''}
              </Typography>
            );

          if (key === 'firstName')
            return <Typography>{user?.firstName + ' ' + user?.lastName}</Typography>;

          return <Typography>{user?.[key]?.toString()}</Typography>;
        }
      }
    ],
    [user]
  );

  const companyColumns = useMemo<
    MRT_ColumnDef<{ userId: string; companyId: string; role: 'founder'; company: CompanyType }>[]
  >(
    () => [
      {
        accessorKey: 'company.name',
        header: t('global.title'),
        enableColumnFilter: false,
        Cell: ({ cell, row }) => (
          <NavLink to={'/companies/single/' + row.original.companyId}>
            {cell.getValue<string>()}
          </NavLink>
        )
      },
      {
        accessorKey: 'company.email',
        header: t('global.email'),
        enableColumnFilter: false
      },

      {
        accessorKey: 'company.phone',
        header: t('global.phone'),
        enableColumnFilter: false
      },
      {
        accessorKey: 'company.country',
        header: t('global.country'),
        enableColumnFilter: false
      }
    ],
    [user?.userCompanies]
  );

  return (
    <Box>
      {passwordFormData && <FormModal {...passwordFormData} />}
      {formData && (
        <FormModal
          {...formData}
          open={!!formData}
          onCancel={() => {
            formData.onCancel();
            setFormData(null);
          }}
          errors={errors}
        />
      )}
      <TableLayout
        data={
          user?.type === 'business' ? getBusinessUserProfileKeys(t, single) : getUserKeys(t, single)
        }
        columns={columns}
        rowCount={0}
      />
      {single && (
        <TableLayout
          columns={companyColumns}
          data={user?.userCompanies ? user?.userCompanies : []}
        />
      )}
      {!single && user?.type !== 'admin' && (
        <Box display={'flex'} justifyContent={'flex-end'}>
          {isLoadingDelete ? (
            <CircularProgress />
          ) : (
            <Button variant="outlined" color="error" size="small" onClick={onDeleteClick}>
              <DeleteOutline /> {t('user.delete_account')}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
export default UserProfileInfoPage;
