import React, { ReactNode, useEffect } from 'react';
//components
import { Avatar, Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//icons
import { CheckIcon } from '@components/icons/icons';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { primaryColor } from '@src/theme';

type ProfileInfoCardPropsType = {
  title: string;
  subtitle: string;
  bannerImage?: string | null;
  profileImage: string | null | undefined;
  logoImage?: string;
  isVerified?: string;
  handleEditProfileImage?: Function;
  handleEditBannerImage?: Function;
  handleEdit?: Function;
  handleDeleteProfile?: Function;
  handleResetPassword?: Function;
  avatarVariant?: 'rounded' | 'circular' | 'square';
  buttons?: ReactNode;
};

const ProfileInfoCard: React.FC<ProfileInfoCardPropsType> = ({
  title,
  bannerImage,
  profileImage,
  subtitle,
  isVerified,
  handleEditProfileImage,
  handleEditBannerImage,
  handleEdit,
  handleResetPassword,
  handleDeleteProfile,
  avatarVariant = 'circular',
  logoImage,
  buttons
}: ProfileInfoCardPropsType): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column', md: 'row' }}
      p={2}
      pb={1}
      gap={1}
      sx={
        bannerImage
          ? {
              border: '1px solid #C5CBD8',
              borderRadius: '4px',
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(0deg, rgb(255,255,255,1),rgba(255,255,255,.7)),url(${bannerImage})`,
              backgroundPosition: 'center'
            }
          : {}
      }
      position={'relative'}
    >
      <Box
        sx={{ display: 'flex' }}
        position={'relative'}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        {profileImage !== undefined && (
          <Avatar
            src={profileImage?.toString()}
            sx={{
              width: 140,
              height: 140,
              background: primaryColor,
              border: '2px solid #304674'
            }}
            variant={avatarVariant}
          />
        )}

        {handleEditProfileImage && (
          <IconButton
            sx={{
              position: 'absolute',
              left: 100,
              border: '1px solid #304674',
              backgroundColor: '#FFFFFF',
              color: '#304674'
            }}
            onClick={() => handleEditProfileImage()}
            data-tour="edit-profile-img"
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            {isVerified && (
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <CheckIcon />
                <Typography color={'primary'} variant="subtitle2">
                  {isVerified}
                </Typography>
              </Box>
            )}
            <Typography
              component="div"
              variant="h4"
              fontWeight={'bold'}
              // sx={{ filter: 'drop-shadow(1px 1px 0px rgb(0,0,0,.8))' }}
              color={'black'}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              // sx={{ fontWeight: '800', filter: 'drop-shadow(1px 1px 0px rgb(0,0,0,.8))' }}
              color={'black'}
            >
              {subtitle}
            </Typography>
          </CardContent>
        </Box>
      </Box>

      {handleEditBannerImage && (
        <IconButton
          size="small"
          sx={{
            width: 'fit-content',
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
            border: '1px solid #304674',
            backgroundColor: '#FFFFFF',
            color: '#304674'
          }}
          onClick={() => handleEditBannerImage()}
          data-tour="edit-banner-img"
        >
          <EditOutlinedIcon fontSize="small" />
        </IconButton>
      )}

      {logoImage && (
        <Avatar
          src={logoImage?.toString()}
          sx={{ width: 80, height: 80 }}
          variant={avatarVariant}
        />
      )}

      <Box
        display={'flex'}
        flexDirection={{ xs: 'row', md: 'row' }}
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        gap={1}
        width={'100%'}
      >
        <Box display={'flex'} flexDirection={'row'}>
          {buttons}
        </Box>

        <Box display={'flex'} flexDirection={'row'} alignSelf={'flex-end'}>
          {handleEdit && (
            <Button
              variant="contained"
              size="small"
              sx={{ gap: 1 }}
              onClick={() => handleEdit()}
              data-tour="edit-profile-btn"
            >
              {t('user.edit_profile')}
              <EditOutlinedIcon fontSize="small" />
            </Button>
          )}
        </Box>
        {(handleResetPassword || handleDeleteProfile) && (
          <Box display={'flex'} flexDirection={'row'} alignSelf={'flex-end'} gap={1} mt={'auto'}>
            {handleResetPassword && (
              <Button variant="outlined" size="small" onClick={() => handleResetPassword()}>
                {t('global.reset_password')}
              </Button>
            )}
            {handleDeleteProfile && (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleDeleteProfile()}
              >
                {t('global.delete_user')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileInfoCard;
