import {
  AllCountriesResponseType,
  AllPredictionsResponseType,
  PredictionType
} from '@interfaces/autocomplete/autocomplete';
import { ErrorsResponseType } from '@interfaces/response';
import {
  getAllCountries,
  getGeoInfo,
  getSuggestedAddresses,
  getSuggestedCities,
  getSuggestedCountries
} from '@services/autocomplete/autocomplete';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useSuggestedCountries = (
  enabled: boolean,
  onError: (err: AxiosError) => void,
  params?: { input: string; language: string }
): UseQueryResult<AllPredictionsResponseType, AxiosError> =>
  useQuery(['country', params], () => getSuggestedCountries(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: enabled && params && params.input.length > 1 ? true : false
  });

export const useSuggestedCities = (
  enabled: boolean,
  onError: (err: AxiosError) => void,
  params?: { input: string; countryCode?: string; language: string }
): UseQueryResult<AllPredictionsResponseType, AxiosError> =>
  useQuery(['city', params], () => getSuggestedCities(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: enabled && params && params.input.length > 1 ? true : false
  });

export const useSuggestedAddresses = (
  enabled: boolean,
  onError: (err: AxiosError) => void,
  params?: { input: string; lat?: number; lng?: number; language: string }
): UseQueryResult<AllPredictionsResponseType, AxiosError> =>
  useQuery(['address', params], () => getSuggestedAddresses(params), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: enabled && params && params.input.length > 1 ? true : false
  });

export const useCountries = (
  enabled: boolean,
  onError: (err: ErrorsResponseType<{}>) => void,
  params?: { search: string; lang: string }
): UseQueryResult<AllCountriesResponseType, ErrorsResponseType<{}>> =>
  useQuery(['countries', params], () => getAllCountries(params?.search, params?.lang), {
    onError: err => onError(err),
    select: res => res.data,
    enabled: enabled && params && params.search.length > 1 ? true : false
  });
