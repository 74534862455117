import { JobStatusEnum } from '@enum/companyEnum';
import { CouponStatusEnum, MuiColorEnum, StatusEnum } from '@enum/progressEnum';
import { CreateVoucherType, VoucherType } from '@interfaces/vouchers/vouchers';
import theme from '@src/theme';
import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { MRT_PaginationState } from 'material-react-table';

import { OutputBlockData } from '@editorjs/editorjs';
import { FieldError, Path, PathValue, UseFormSetError } from 'react-hook-form';
import { MappedFieldsType } from '@interfaces/modalForm';
import { SupportedLanguagesEnum, languages } from '@src/translation/languages.config';
import { TranslationType } from '@interfaces/translation';
import { VITE_ENV } from './env.config';
export { dayjs };

export const isDevMode = (): boolean => {
  return import.meta.env.DEV;
};

export const isProdMode = (): boolean => {
  return VITE_ENV === 'production';
};

export const hasToken = (): string | null => {
  return localStorage.getItem('token');
};

export const isTokenExpired = (token: string) => {
  const decoded = jwtDecode(token);
  if (decoded && decoded.exp) {
    return decoded.exp * 1000 < Date.now();
  }
  return true;
};

export const selectedTab = (path: string) => {
  const page = path.split('/').pop();
  if (page === 'jobs' || page === 'projects' || page === 'owner') {
    return page;
  }
  return '';
};

export const getDefaultPagnation = (searchParams: URLSearchParams): MRT_PaginationState => {
  const pagination = { pageIndex: 0, pageSize: 10 };
  const params = Object.fromEntries([...searchParams]);
  if (params.pageIndex) {
    pagination.pageIndex = parseInt(params.pageIndex);
  }
  if (params.pageSize) {
    pagination.pageSize = parseInt(params.pageSize);
  }
  return pagination;
};

export const getColorByStatus = (status: string): MuiColorEnum => {
  if (status === JobStatusEnum.PENDING) return MuiColorEnum.WARNING;
  if (status === JobStatusEnum.ALLOWED) return MuiColorEnum.SUCCESS;
  if (status === JobStatusEnum.BLOCKED) return MuiColorEnum.ERROR;
  if (status === JobStatusEnum.EXPIRED) return MuiColorEnum.PRIMARY;
  return MuiColorEnum.PRIMARY;
};

export const getTypographyColor = (status: string) => {
  if (status === StatusEnum.active) return theme.palette.success.main;
  if (status === StatusEnum.pending) return theme.palette.warning.main;
  if (status === StatusEnum.finished) return theme.palette.error.main;
  if (status === JobStatusEnum.PENDING) return theme.palette.warning.main;
  if (status === JobStatusEnum.ALLOWED) return theme.palette.success.main;
  if (status === JobStatusEnum.BLOCKED) return theme.palette.error.main;
  if (status === JobStatusEnum.EXPIRED) return theme.palette.primary.main;
  if (status === CouponStatusEnum.REDEEMED) return theme.palette.warning.main;
};

export const getVoucherTypographyColor = (status: string) => {
  if (status === StatusEnum.active) return theme.palette.success.main;
  if (status === StatusEnum.pending) return theme.palette.warning.main;
  if (status === StatusEnum.finished) return theme.palette.success.dark;
  if (status === StatusEnum.draft) return theme.palette.warning.light;
  if (status === StatusEnum.declined) return theme.palette.error.main;
};

export const secondsToMinutes = (seconds: number) => {
  const minutes = parseInt(((seconds % 3600) / 60).toString());
  let sec: number | string = seconds % 60;
  if (sec < 10) sec = '0' + sec;
  return minutes + ':' + sec;
};

export const getDiscountType = (t: TFunction): { value: string; label: string }[] => [
  { value: 'fixed', label: t('voucher.discount.fixed') },
  { value: 'percentage', label: t('voucher.discount.percentage') }
];

export const getLanguage = (lang: string) => {
  const lng = languages.find(lng => lng.id === lang);
  if (lng) return lng;
  else return languages.find(lng => lng.id === SupportedLanguagesEnum.SERBIAN);
};

export const formatEditorColor = (block: OutputBlockData<string, any>) => {
  const colorsArray = /(\d+)/g;
  const rgb = block.data.text.match(colorsArray);

  let blockText = block.data.text;

  if (rgb) {
    let hexr = parseInt(rgb[0]).toString(16);
    let hexg = parseInt(rgb[1]).toString(16);
    let hexb = parseInt(rgb[2]).toString(16);

    hexr = hexr.length === 1 ? '0' + hexr : hexr;
    hexg = hexg.length === 1 ? '0' + hexg : hexg;
    hexb = hexb.length === 1 ? '0' + hexb : hexb;

    const rgbColorRegex = /rgb\(\d+, \d+, \d+\)/g;
    const rgbColor = '#' + hexr + hexg + hexb;

    blockText = block.data.text.replace(rgbColorRegex, rgbColor);
  }
  return blockText;
};

export const checkDate = <T extends {}>(
  option: MappedFieldsType,
  fieldValue: Dayjs,
  t: TFunction
) => {
  if (option.minDate && dayjs(fieldValue).isBefore(option.minDate, 'day')) {
    return t('global.expired_at_past_message');
  } else if (option.maxDate && option.maxDate.isBefore(dayjs(fieldValue))) {
    return t('global.future_date_error_message');
  }
  return '';
};

export const filterEmptyTranslation = <T>(translations: TranslationType<T>) => {
  Object.values(SupportedLanguagesEnum).map(lng => {
    const tr = translations[lng as SupportedLanguagesEnum];
    if (translations?.[lng] && tr) {
      const pom = Object.fromEntries(Object.entries(tr).filter(([_, v]) => !!v));
      if (Object.keys(pom).length === 0) delete translations[lng];
    }
  });
};

export const getTranslationValue = <T>(
  translations: TranslationType<T>,
  key: keyof T,
  selectedLng: keyof TranslationType<T>
): string => {
  const value = translations[selectedLng]?.[key] as string;
  if (value) return value;
  return (
    (Object.values(SupportedLanguagesEnum).map(lng => {
      if (translations[lng]?.[key]) {
        return translations[lng]?.[key];
      }
    })[0] as string) ?? ''
  );
};

export const checkForEmptyTranslation = <T>(translations: TranslationType<T>) => {
  for (const lng of Object.values(SupportedLanguagesEnum)) {
    const translation = translations[lng];

    if (translation) {
      for (const en in Object.fromEntries(Object.entries(translation))) {
        if (!en) return true;
      }
      const pom = Object.fromEntries(Object.entries(translation).filter(([_, v]) => !!v));
      if (Object.keys(pom).length === 0) return true;
    } else {
      return true;
    }
  }
  return false;
};

export const getVoucherSteps = (t: TFunction) => {
  return [t('voucher.create_title'), t('voucher.text_title')];
};

export function youtubeDurationParse(duration: string) {
  const iso8601DurationRegex =
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

  const matches = duration?.match(iso8601DurationRegex)!;

  const durationTime = {
    sign: matches?.[1] === undefined ? '+' : '-',
    years: matches?.[2] === undefined ? 0 : matches[2],
    months: matches?.[3] === undefined ? 0 : matches[3],
    weeks: matches?.[4] === undefined ? 0 : matches[4],
    days: matches?.[5] === undefined ? 0 : matches[5],
    hours: matches?.[6] === undefined ? 0 : matches[6],
    minutes: matches?.[7] === undefined ? 0 : matches[7],
    seconds: matches?.[8] === undefined ? 0 : matches[8]
  };

  return `${Number(durationTime?.hours) > 0 ? `${durationTime?.hours}h` : ''} ${
    Number(durationTime?.minutes) > 0 ? `${durationTime?.minutes}m` : ''
  } ${
    (durationTime?.seconds && !Number(durationTime?.hours)) || Number(durationTime?.minutes) === 0
      ? `${durationTime?.seconds}s`
      : ''
  }`;
}

export const detectAdBlocker = () => {
  const url = 'https://www3.doubleclick.net';
  return fetch(url, {
    method: 'HEAD',
    mode: 'no-cors',
    cache: 'no-store'
  });
};
