export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const VITE_RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_KEY;

export const VITE_API_KEY = import.meta.env.VITE_API_KEY;
export const VITE_AUTH_DOMAIN = import.meta.env.VITE_AUTH_DOMAIN;
export const VITE_PROJECT_ID = import.meta.env.VITE_PROJECT_ID;
export const VITE_STORAGE_BUCKET = import.meta.env.VITE_STORAGE_BUCKET;
export const VITE_MESSAGING_SENDER_ID = import.meta.env.VITE_MESSAGING_SENDER_ID;
export const VITE_APP_ID = import.meta.env.VITE_APP_ID;
export const VITE_MEASUREMENT_ID = import.meta.env.VITE_MEASUREMENT_ID;
export const VITE_VAPID_KEY = import.meta.env.VITE_VAPID_KEY;

export const ISSUER = import.meta.env.VITE_ISSUER;
export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;
export const CLIENT_SECRET = import.meta.env.VITE_CLIENT_SECRET;
export const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL;

export const SANITY_URL = import.meta.env.VITE_SANITY_URL;
export const SANITY_PROJECT_ID = import.meta.env.VITE_SANITY_PROJECT_ID;
export const SANITY_PROJECT_DATASET = import.meta.env.VITE_SANITY_PROJECT_DATASET;

export const VITE_TERMS_OF_SERVICE_URL = import.meta.env.VITE_TERMS_OF_SERVICE_URL;

export const VITE_DISABLE_TOUR = import.meta.env.VITE_DISABLE_TOUR === 'true';
export const VITE_ENV = import.meta.env.VITE_ENV;
