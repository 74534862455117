import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// hooks

import { FormModalPropsType } from '@components/FormModal/FormModal';

import { useTranslation } from 'react-i18next';
import { useSingleCompany } from '@api/queries/company/company';
import { selectedTab } from '@helpers/utility';
import { CompanyType, UpdateCompanyType } from '@interfaces/company/company';
import { useForm } from 'react-hook-form';
import { companyDecriprionFields, companyProfileFields } from '@helpers/formFields/formFields';
import { InputTypeEnum } from '@enum/formModal';
import { useAllIndustries } from '@api/queries/interests/interests';
import {
  useDeleteCompanyImage,
  useUpdateCompany,
  useUpdateCompanyVisibility,
  useUploadCompanyImage
} from '@api/mutations/company/company';
import dayjs from 'dayjs';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

const getFormValues = (company: CompanyType) => {
  Object.keys(company).map(key => {
    if (company[key as keyof CompanyType] === null) {
      delete company[key as keyof CompanyType];
    }
    if (key === 'tags') {
      if (company.tags && company.tags.length === 0) {
        company.tags = [''];
      }
    }
    if (key === 'hashTags') {
      if (company.hashTags && company.hashTags.length === 0) {
        company.hashTags = [''];
      }
    }
  });

  return company;
};

export function useCompanyProfile() {
  const [activeTab, setActiveTab] = useState<string | boolean>(false);
  const [formData, setFormData] = useState<FormModalPropsType<UpdateCompanyType> | null>(null);

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { companyId } = useParams();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };
  const onUpdateError = (err: ErrorType<UpdateCompanyType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
    if (err.errors)
      for (const key in err.errors) {
        setError(key as keyof UpdateCompanyType, {
          type: 'custom',
          message: err.errors[key as keyof UpdateCompanyType]?.join(', ')
        });
      }
    else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onUploadImageError = (err: ErrorsResponseType<{}>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
  };

  const {
    data: company,
    isLoading,
    refetch: refetchCompany,
    remove
  } = useSingleCompany(companyId, onError, i18n.language);

  const { data: industries } = useAllIndustries(onError);
  const {
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm<UpdateCompanyType>({
    values: company && {
      ...getFormValues(company),
      industries: company.industries.map(i => i.id),
      founded: company.founded ? dayjs(company.founded) : ''
    }
  });

  const {
    mutate: updateCompany,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdateCompany(onUpdateError);

  const {
    mutate: updateCompanyVisibility,
    isSuccess: isUpdateVisibilitySuccess,
    isLoading: isLoadingUpdateVisibility
  } = useUpdateCompanyVisibility(onError);

  const { mutate: uploadImage, isSuccess: isImageSuccess } =
    useUploadCompanyImage(onUploadImageError);

  const { mutate: deleteImage, isSuccess: isImageDeleteSuccess } = useDeleteCompanyImage(onError);

  const onSubmit = (data: UpdateCompanyType) => {
    if (data.image !== company?.image && typeof data.image !== 'string') {
      onSubmitImage(data.image);
      return;
    }
    if (data.coverImage !== company?.coverImage && typeof data.coverImage !== 'string') {
      onSubmitCoverImage(data.coverImage);
      return;
    }
    if (companyId)
      updateCompany({
        id: companyId,
        data: {
          email: data.email,
          pib: company?.pib === data.pib ? undefined : Number(data.pib),
          industries: data.industries,
          website: data.website,
          city: data.city,
          address: data.address,
          office: data.office,
          founded: data.founded !== '' ? data.founded?.toString() : undefined,
          employeesNumber: data.employeesNumber !== '' ? data.employeesNumber : undefined,
          socialNetworks: {
            instagram:
              data.socialNetworks?.instagram !== '' ? data.socialNetworks?.instagram : undefined,
            youtube: data.socialNetworks?.youtube !== '' ? data.socialNetworks?.youtube : undefined,
            linkedin:
              data.socialNetworks?.linkedin !== '' ? data.socialNetworks?.linkedin : undefined
          },
          description: data.description,
          shortDescription: data.shortDescription,
          tags: data.tags?.filter(t => t !== ''),
          hashTags: data.hashTags?.filter(ht => ht !== ''),
          newCountryAlpha2: data.newCountryAlpha2
        }
      });
  };

  const onSubmitImage = (newImage: File | null) => {
    if (newImage !== null) {
      const data = new FormData();
      data.append('file', newImage);

      companyId && uploadImage({ companyId: companyId, type: 'image', data: data });
    } else {
      companyId && deleteImage({ companyId: companyId, type: 'image' });
    }
  };

  const onSubmitCoverImage = (newImage: File | null) => {
    if (newImage !== null) {
      const data = new FormData();
      data.append('file', newImage);
      companyId && uploadImage({ companyId: companyId, type: 'coverImage', data: data });
      companyId && uploadImage({ companyId: companyId, type: 'heroImage', data: data });
    } else {
      companyId && deleteImage({ companyId: companyId, type: 'coverImage' });
      companyId && deleteImage({ companyId: companyId, type: 'heroImage' });
    }
  };

  const onEdit = () => {
    setFormData({
      title: t('user.edit_profile'),
      open: true,
      control: control,
      fields: companyProfileFields(t, industries ? industries : []),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      setValue: setValue,
      setError: setError,
      clearErrors: clearErrors,
      onCancel: () => {
        setFormData(null);
        company &&
          reset({
            ...getFormValues(company),
            industries: company.industries.map(i => i.id),
            founded: company.founded ? dayjs(company.founded) : ''
          });
      }
    });
  };

  const onEditDescription = () => {
    setFormData({
      title: t('user.company.edit_description'),
      open: true,
      control: control,
      fields: companyDecriprionFields(t),
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        setFormData(null);
        company &&
          reset({
            ...getFormValues(company),
            industries: company.industries.map(i => i.id),
            founded: company.founded ? dayjs(company.founded) : ''
          });
      },
      setValue: setValue,
      clearErrors: clearErrors,
      getValues: getValues
    });
  };

  const onEditImageClick = () => {
    setFormData({
      title: t('user.company.image'),
      open: true,
      control: control,
      fields: [
        {
          key: 'image',
          type: InputTypeEnum.image,
          label: t('user.company.image')
        }
      ],
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        setFormData(null);
        company &&
          reset({
            ...getFormValues(company),
            industries: company.industries.map(i => i.id),
            founded: company.founded ? dayjs(company.founded) : ''
          });
      },
      setValue: setValue,
      setError: setError,
      errors: errors,
      getValues: getValues
    });
  };

  const onEditHeroImageClick = () => {
    setFormData({
      title: t('user.company.banner'),
      open: true,
      control: control,
      fields: [
        {
          key: 'coverImage',
          type: InputTypeEnum.image,
          label: t('user.company.banner')
        }
      ],
      onSubmit: handleSubmit(onSubmit),
      buttonText: t('global.edit'),
      variant: 'form',
      onCancel: () => {
        setFormData(null);
        company &&
          reset({
            ...getFormValues(company),
            industries: company.industries.map(i => i.id),
            founded: company.founded ? dayjs(company.founded) : ''
          });
      },
      setValue: setValue,
      setError: setError
    });
  };
  const onDeleteClick = () => {};

  useEffect(() => {
    setActiveTab(selectedTab(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (isUpdateSuccess || isImageSuccess || isImageDeleteSuccess || isUpdateVisibilitySuccess) {
      remove();
      setFormData(null);
      refetchCompany();
      setInfoPopup({
        title: t('global.success_update'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
    }
  }, [isUpdateSuccess, isImageSuccess, isImageDeleteSuccess, isUpdateVisibilitySuccess]);

  return {
    isLoading,
    company: company
      ? {
          ...company,
          image: company.image && company.image + '?' + dayjs().format('ddMMyyyyhhmmsstt'),
          coverImage:
            company.coverImage && company.coverImage + '?' + dayjs().format('ddMMyyyyhhmmsstt')
        }
      : company,
    formData,
    onEdit,
    onEditHeroImageClick,
    onEditImageClick,
    onEditDescription,
    activeTab,
    t,
    onDeleteClick,
    setFormData,
    isLoadingUpdate,
    updateCompanyVisibility,
    isLoadingUpdateVisibility
  };
}
