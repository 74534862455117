export enum JobTypeEnum {
  FULL_TIME = 'full-time',
  PART_TIME = 'part-time',
  INTERNSHIP = 'internship'
}

export enum JobStatusEnum {
  PENDING = 'pending',
  ALLOWED = 'allowed',
  BLOCKED = 'blocked',
  EXPIRED = 'expired'
}

export enum EmployeesNumberEnum {
  '1-10' = '1-10',
  '11-50' = '11-50',
  '51-200' = '51-200',
  '201-500' = '201-500',
  '501-1000' = '501-1000',
  '1001-5000' = '1001-5000',
  '5000+' = '5000+'
}
