//react
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//interfaces
import { MRT_PaginationState, MRT_ColumnFiltersState } from 'material-react-table';
import { CreateRadioType } from '@interfaces/radio/radio';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { AxiosError } from 'axios';
//hooks
import { useTranslation } from 'react-i18next';
//services
import { useAllCompanies, useSingleCompany } from '@api/queries/company/company';
import { useAllIndustries } from '@api/queries/interests/interests';
import { useDeleteCompany } from '@api/mutations/company/company';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { getDefaultPagnation } from '@helpers/utility';
import { ErrorsResponseType } from '@interfaces/response';

export function useCompany() {
  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [popup, setPopup] = useState<FormModalPropsType<CreateRadioType> | null>(null);

  const { companyId } = useParams();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t, i18n } = useTranslation();

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.response.data?.message ? err.response.data?.message : err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const {
    data: companies,
    isLoading: isLoadingAll,
    refetch
  } = useAllCompanies(onError, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    search: columnFilters.find(el => el.id === 'name')?.value as string,
    industryId: columnFilters.find(el => el.id === 'industries')?.value as string,
    visible: columnFilters.find(el => el.id === 'visible')
      ? columnFilters.find(el => el.id === 'visible')?.value === 'true'
      : undefined,
    lang: i18n.language
  });
  const { data: industries } = useAllIndustries(onError);
  const {
    mutate: deleteCompany,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete
  } = useDeleteCompany(onError);

  const onCancel = () => {
    setPopup(null);
  };

  const onDeleteCompanyClick = (id: string) => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.company`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.company`) }),
      open: true,
      fields: [],
      onCancel: () => {
        setPopup(null);
      },
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (id) {
          deleteCompany(id);
        }
      }
    });
  };

  useEffect(() => {
    if (location.pathname === '/companies')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  useEffect(() => {
    if (isSuccessDelete) {
      setPopup(null);
      refetch();
    }
  }, [isSuccessDelete]);

  return {
    onCancel,
    deleteCompany,
    companies: companies ? companies.data : [],
    rowCount: companies?.meta.itemCount,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    isLoadingAll,
    industries: industries ? industries : [],
    companyId,
    popup,
    setPopup,
    onDeleteCompanyClick,
    isLoadingDelete
  };
}
