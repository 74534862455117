import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// hooks
import { useUsers } from '@hooks/useUsers/useUsers';
//componets
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import { Box, Typography } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import Loading from '@components/Loading/Loading';

const SingleUserLayout: React.FC = (): JSX.Element => {
  const { user, t, isLoadingSingle, onDeleteClick, popup,isLoadingDelete } = useUsers();

  if (isLoadingSingle) {
    return <Loading />;
  }
  if (!user) {
    return <Navigate to={'/error-page'} replace />;
  }
  return (
    <Box width={'100%'}>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={!!popup}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          buttonText={popup.buttonText}
          variant={popup.variant}
          isLoading={isLoadingDelete}
        />
      )}

      <ProfileInfoCard
        title={`${user.firstName ?? '/'} ${user.lastName ?? '/'}`}
        subtitle={`${user.email}`}
        profileImage={user.image}
        handleDeleteProfile={
          user.type !== 'admin'
            ? () => {
                onDeleteClick(user);
              }
            : undefined
        }
        bannerImage={user.coverImage}
      />

      <Box width={{ xs: '100%', md: '70%' }}>
        <Typography variant="subtitle2">{user?.companyDescription}</Typography>
      </Box>
      <Box className={'container'}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SingleUserLayout;
