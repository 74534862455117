// store
import { NotificationContentType } from '@interfaces/notifications/notifications';
import { atom } from 'recoil';

const isShowNotificationAtom = atom<boolean>({
  key: 'isShowNotification',
  default: false
});

const notificationAtom = atom<NotificationContentType | null>({
  key: 'notification',
  default: null
});

const notificationCountAtom = atom<number>({
  key: 'notificationCount',
  default: 0
});

export { isShowNotificationAtom, notificationAtom, notificationCountAtom };
