//types
import { CreateHitListType, HitListType } from '@interfaces/hitList/hitList';
import { FormModalPropsType } from '@components/FormModal/FormModal';
import { SelectChangeEvent } from '@mui/material';
import { MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table';
import { StatusEnum } from '@enum/progressEnum';
//api
import {
  useChangeStatusHitList,
  useCreateHitList,
  useDeleteHitList,
  useUpdateHitList
} from '@api/mutations/hitList/hitList';
import { useAllHitLists, useSingleHitList } from '@api/queries/hitList/hitList';
//hooks
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//helpers
import { formHelper } from '@helpers/formDataHelper';
import { hitListFields } from '@helpers/formFields/formFields';
import { getDefaultPagnation } from '@helpers/utility';
import dayjs from 'dayjs';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

export function useHitList() {
  const [success, setSuccess] = useState(false);
  const [popup, setPopup] = useState<FormModalPropsType<CreateHitListType> | null>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [searchParams, setSearchParams] = useSearchParams({ pageIndex: '0', pageSize: '10' });
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    getDefaultPagnation(searchParams)
  );

  const { listId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const onCreateSuccess = (data: HitListType) => {
    resetForm();
  };

  const onCreateError = (err: ErrorType<CreateHitListType>) => {
    for (const key in err.errors) {
      setError(key as keyof CreateHitListType, {
        type: 'custom',
        message: err.errors[key as keyof CreateHitListType]?.join(', ')
      });
    }
    if (!err.errors) {
      setError('root', {
        type: 'custom',
        message: err.message
      });
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onDeleteSuccess = () => {
    refetchAll();
    if (listId) navigate(-1);
  };

  const onChangeStatusSuccess = () => {
    refetchSingle();
  };

  const { mutate: createHitList, isLoading: isLoadingCreate } = useCreateHitList(
    onCreateSuccess,
    onCreateError
  );
  const { mutate: updateHitList, isLoading: isLoadingUpdate } = useUpdateHitList(
    onCreateSuccess,
    onCreateError
  );
  const { mutate: deleteHitList, isLoading: isLoadingDelete } = useDeleteHitList(
    onDeleteSuccess,
    onError
  );
  const { mutate: changeStatus } = useChangeStatusHitList(onChangeStatusSuccess, onError);
  const {
    data: allLists,
    refetch: refetchAll,
    isLoading: isLoadingAll
  } = useAllHitLists(onError, {
    page: pagination.pageIndex + 1,
    take: pagination.pageSize,
    search: columnFilters.find(el => el.id === 'name')?.value as string,
    status: columnFilters.find(el => el.id === 'status')?.value as StatusEnum
  });
  const {
    data: singleList,
    refetch: refetchSingle,
    isLoading: isLoadingSingle
  } = useSingleHitList(listId, onError);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
    clearErrors,
    getValues,
    formState: { errors }
  } = useForm<CreateHitListType>({
    values: singleList ? { ...singleList, expiredAt: dayjs(singleList.expiredAt) } : undefined
  });

  const handleCreate = (data: CreateHitListType) => {
    if (data.name.trim().length === 0) {
      setError('name', {
        type: 'custom',
        message: ''
      });
      return;
    }
    data.expiredAt = data.expiredAt.toString();
    createHitList(formHelper.getBody(data));
  };

  const handleDelete = (data: HitListType) => {
    if (data.status !== StatusEnum.pending) {
      setInfoPopup({
        title: '',
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    } else deleteHitList(data.id);
  };

  const onUpdate = (data: CreateHitListType) => {
    if (listId)
      updateHitList({
        id: listId,
        data: formHelper.getBody({
          name: data.name,
          expiredAt: data.expiredAt.toString(),
          file: data.file
        })
      });
  };

  const onCancel = () => {
    setPopup(null);
  };

  const resetForm = () => {
    setInfoPopup({
      title: t('global.success_create'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
    reset();
    if (listId) {
      refetchSingle();
      onCancel();
    } else {
      refetchAll();
      setSuccess(true);
      setTimeout(() => setSuccess(false), 500);
    }
  };

  const onEditClick = () => {
    setPopup({
      title: t('hit_list.form.edit_title'),
      open: true,
      control: control,
      fields: hitListFields(t, false),
      onCancel: onCancel,
      onSubmit: handleSubmit(onUpdate),
      setValue: setValue,
      buttonText: t('global.edit'),
      variant: 'form',
      setError: setError,
      clearErrors: clearErrors
    });
  };

  const onDeleteClick = () => {
    setPopup({
      title: t(`global.delete_title`, { object: t(`delete.hit_list`) }),
      subtitle: t(`global.delete_subtitle`, { object: t(`delete.hit_list`) }),
      open: true,
      fields: [],
      onCancel: onCancel,
      buttonText: t('global.accept'),
      variant: 'delete',
      onSubmit: () => {
        if (listId) {
          deleteHitList(listId);
        }
      }
    });
  };

  const onStatusChange = (ev: SelectChangeEvent) => {
    const newStatus = ev.target.value as StatusEnum;
    if (listId && newStatus) changeStatus({ id: listId, newStatus: newStatus });
  };

  useEffect(() => {
    if (location.pathname === '/hit-lists')
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString()
      });
  }, [pagination]);

  return {
    allLists: allLists ? allLists.data : [],
    watch,
    errors,
    setValue,
    clearErrors,
    getValues,
    handleCreate,
    handleDelete,
    control,
    handleSubmit,
    reset,
    deleteHitList,
    listId: listId ? listId : null,
    setError,
    success,
    onUpdate,
    onCancel,
    singleList,
    popup,
    onEditClick,
    onDeleteClick,
    onStatusChange,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    rowCount: allLists?.meta.itemCount,
    isLoadingAll,
    isLoadingSingle,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
