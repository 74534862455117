import { NavLink, Navigate, Outlet } from 'react-router-dom';
// hooks
import { useEffect, useState } from 'react';
import { useVoucher } from '@hooks/useVoucher/useVoucher';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
//components
import ProfileInfoCard from '@components/ProfileInfoCard/ProfileInfoCard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button } from '@mui/material';
import FormModal from '@components/FormModal/FormModal';
import Loading from '@components/Loading/Loading';
//helpers
import dayjs from 'dayjs';
import { getTranslationValue } from '@helpers/utility';
//atom
import { userAtom } from '@atoms/userAtom';
//type
import { RoleEnum } from '@enum/roleEnum';
import { StatusEnum } from '@enum/progressEnum';
import { SupportedLanguagesEnum } from '@src/translation/languages.config';

const VoucherLayout = () => {
  const {
    voucherId,
    singleVoucher,
    isLoadingSingle,
    popup,
    onDeleteVoucherClick,
    handleEditClick
  } = useVoucher();

  const user = useRecoilValue(userAtom);

  const { t, i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState<string | boolean>(false);

  useEffect(() => {
    setActiveTab(location.pathname.split('/').pop() === 'coupons' ? 'coupons' : '');
  }, [location.pathname]);

  if (isLoadingSingle) return <Loading />;
  if (!singleVoucher) return <Navigate to={'/error-page'} />;
  return (
    <Box width={'100%'}>
      {popup && (
        <FormModal
          title={popup.title}
          subtitle={popup.subtitle}
          open={true}
          control={popup.control}
          fields={popup.fields}
          onCancel={popup.onCancel}
          onSubmit={popup.onSubmit}
          setValue={popup.setValue}
          buttonText={popup.buttonText}
          variant={popup.variant}
          setError={popup.setError}
        />
      )}

      <ProfileInfoCard
        title={
          `${getTranslationValue(
            singleVoucher.translations,
            'title',
            i18n.language as SupportedLanguagesEnum
          )}` || singleVoucher.title
        }
        subtitle={t('global.voucher')}
        profileImage={
          singleVoucher.image ? singleVoucher.image + `?${dayjs().toISOString()}` : undefined
        }
        bannerImage={
          singleVoucher.heroImage
            ? singleVoucher.heroImage + `?${dayjs().toISOString()}`
            : undefined
        }
        buttons={
          <>
            {user?.type === RoleEnum.ADMIN && singleVoucher.status !== StatusEnum.finished && (
              <Button variant="contained" size="small" sx={{ mx: 2 }} onClick={handleEditClick}>
                {t(`global.edit`)}
              </Button>
            )}
            {user?.type === RoleEnum.ADMIN && singleVoucher.status !== StatusEnum.active && (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  voucherId && onDeleteVoucherClick(voucherId);
                }}
              >
                {t(`global.delete`)}
              </Button>
            )}
          </>
        }
      />

      <Box p={2}>
        <Tabs value={activeTab}>
          <Tab label={t('global.basic_info')} component={NavLink} to="" value={''} />
          {(singleVoucher.status === StatusEnum.active ||
            singleVoucher.status === StatusEnum.finished) && (
            <Tab label={t('voucher.history')} component={NavLink} to="coupons" value={'coupons'} />
          )}
        </Tabs>
      </Box>

      <Box className="container">
        <Outlet />
      </Box>
    </Box>
  );
};

export default VoucherLayout;
