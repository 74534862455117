//api
import {
  useCreateIndustry,
  useDeleteIndustry,
  useDeleteIndustryImage,
  useUpdateIndustry
} from '@api/mutations/interests/interests';
import { useAllIndustries, useSingleIndustry } from '@api/queries/interests/interests';
//helpers
import { formHelper } from '@helpers/formDataHelper';
//type
import { CreateInterestsType, InterestsType } from '@interfaces/interests/interests';
import { useForm } from 'react-hook-form';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { infoPopupAtom } from '@atoms/infoPopupAtom';
import { checkForEmptyTranslation } from '@helpers/utility';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAllCompanies } from '@api/queries/company/company';
import { useState } from 'react';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { useAllJobs } from '@api/queries/jobs/jobs';
import { ErrorType, ErrorsResponseType } from '@interfaces/response';

export function useIndustries() {
  const setInfoPopup = useSetRecoilState(infoPopupAtom);
  const resetInfoPopup = useResetRecoilState(infoPopupAtom);

  const { t } = useTranslation();
  const { industryId } = useParams();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
    getValues
  } = useForm<CreateInterestsType>();

  const onSuccessDelete = () => {
    refetchIndustries();
    reset({
      translations: {
        sr: { name: '' },
        en: { name: '' },
        de: { name: '' },
        fr: { name: '' }
      },
      file: '',
      id: undefined
    });
    setInfoPopup({
      title: t('global.success_update'),
      onClose: () => resetInfoPopup(),
      type: 'success'
    });
  };

  const onError = (err: ErrorsResponseType<{}>) => {
    setInfoPopup({
      title: err.message,
      onClose: () => resetInfoPopup(),
      type: 'error'
    });
  };

  const onSuccessCreate = (data: InterestsType) => {
    const val = getValues();
    if (val.file === null) {
      deleteIndustryImage(data.id);
    } else {
      setInfoPopup({
        title: val.id ? t('global.success_update') : t('global.success_create'),
        onClose: () => resetInfoPopup(),
        type: 'success'
      });
      refetchIndustries();
      reset({
        translations: {
          sr: { name: '' },
          en: { name: '' },
          de: { name: '' },
          fr: { name: '' }
        },
        file: '',
        id: undefined
      });
    }
  };

  const onErrorCreate = (err: ErrorType<InterestsType>) => {
    setError('root', {
      type: 'custom',
      message: err.message
    });
    if (err.errors?.translations) {
      setError('root', {
        type: 'custom',
        message: err.errors.translations.join(', ')
      });
    } else {
      setInfoPopup({
        title: err.message,
        onClose: () => resetInfoPopup(),
        type: 'error'
      });
    }
  };

  const {
    data: industries,
    refetch: refetchIndustries,
    isLoading: isLoadingIndustries
  } = useAllIndustries(onError);

  const {
    mutate: createIndustry,
    isSuccess: isCreateSuccess,
    isLoading: isLoadingCreate
  } = useCreateIndustry(onSuccessCreate, onErrorCreate);
  const {
    mutate: updateIndustry,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate
  } = useUpdateIndustry(onSuccessCreate, onErrorCreate);
  const { mutate: deleteIndustry, isLoading: isLoadingDelete } = useDeleteIndustry(
    onSuccessDelete,
    onError
  );

  const { data: singleIndustry, isLoading: isLoadingSingle } = useSingleIndustry(
    industryId,
    onError
  );

  const [companyPagination, setCompanyPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data: companies } = useAllCompanies(
    onError,
    {
      page: companyPagination.pageIndex + 1,
      take: companyPagination.pageSize,
      industryId: industryId
    },
    !industryId
  );

  const [jobPagination, setJobPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data: jobs } = useAllJobs(
    {
      page: jobPagination.pageIndex + 1,
      take: jobPagination.pageSize,
      industryId: industryId
    },
    onError,
    !!industryId
  );
  const { mutate: deleteIndustryImage } = useDeleteIndustryImage(onSuccessDelete, onError);

  const onSubmitIndustry = (data: CreateInterestsType) => {
    if (checkForEmptyTranslation(data.translations)) {
      setError('root', {
        type: 'custom',
        message: t('global.translation_error_message')
      });
      return;
    }
    if (data.id) {
      if (data.file === null || typeof data.file === 'string') {
        updateIndustry({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations })
        });
      } else {
        updateIndustry({
          id: data.id,
          data: formHelper.getBody({ translations: data.translations, file: data.file })
        });
      }
    } else {
      delete data.id;
      createIndustry(formHelper.getBody({ translations: data.translations, file: data.file }));
    }
  };

  return {
    industries: industries ? industries : [],
    refetchIndustries,
    isLoadingIndustries,
    onSubmitIndustry,
    deleteIndustry,
    isSuccessIndustry: isCreateSuccess || isUpdateSuccess,
    control,
    setValue,
    handleSubmit,
    setError,
    reset,
    errors,
    singleIndustry,
    isLoadingSingle,
    companies: companies ? companies.data : [],
    rowCountCompanies: companies ? companies.meta.itemCount : 0,
    companyPagination,
    setCompanyPagination,
    jobPagination,
    setJobPagination,
    jobs: jobs ? jobs.data : [],
    rowCountJobs: jobs ? jobs.meta.itemCount : 0,
    isSubmitting: isLoadingCreate || isLoadingUpdate || isLoadingDelete
  };
}
